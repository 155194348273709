<template>
    <div class="px-5 py-4 pb-5" style="background-color: #262625; min-height: 100%;">
        <div class="pb-4"><span style="font-size:24px; color:#C95D43;"> 
            <!-- <a @click="$emit('back')" class="px-1" style="font-size: 20px; line-height:20px; cursor: pointer; border:1px solid #C95D43; color:#C95D43; border-radius:4px; margin-right:10px;">
                <b-icon icon="arrow-left-short"></b-icon>
            </a> -->
            {{ title }} 
        </span></div>


        
        <div v-if="title=='Custódia'">
            <CockpitRow title="Entradas"
            :type="'value'"
            :dataKey="'inflows'"
            :dataKeyTarget="'inflows_target'"
            :dataKeyCurrent="'inflows_current'"
            :goal="data.inflows_target"
            :value="data.inflows_current.total_amount"
            :target="data.inflows_target"
            :data="data"
            />
            <CockpitRow title="Saídas"
            :type="'value'"
            :dataKey="'outflows'"
            :dataKeyTarget="'outflows_target'"
            :dataKeyCurrent="'outflows_current'"
            :goal="data.outflows_target"
            :value="data.outflows_current.total_amount"
            :target="data.outflows_target"
            :data="data"
            />
            <CockpitRow title="Captação Líquida"
            :type="'value'"
            :dataKey="'net_flows'"
            :dataKeyTarget="'net_flows_target'"
            :dataKeyCurrent="'net_flows_current'"
            :goal="data.net_flows_target"
            :value="data.net_flows_current.total_amount"
            :target="data.net_flows_target"
            :data="data"
            />
            <CockpitRow title="Ticket Médio"
            :type="'value'"
            :dataKey="'average_ticket'"
            :dataKeyTarget="'average_ticket_target'"
            :dataKeyCurrent="'average_ticket_current'"
            :goal="data.average_ticket_target"
            :value="data.average_ticket_current"
            :target="data.average_ticket_target"
            :data="data"
            hideBarGraphic
            />
            <CockpitRow onlyTitle="Intensidade"/>
            <CockpitRow onlyTitle="Churn"/>
        </div>



        
        <div v-if="title=='Ativação'">
            <CockpitRow title="Target 300k" subtitle="Quantidade"
            :type="'integer'"
            :dataKey="'activation'"
            :dataKeyTarget="'activation_target_300'"
            :dataKeyCurrent="'activation_current_300'"
            :goal="data.activation_target_300"
            :value="data.activation_current_300.total_amount"
            :target="data.activation_target_300"
            :data="data"
            />
            <CockpitRow onlyTitle="Tombamento W300" subtitle="Quantidade"/>
            <CockpitRow onlyTitle="Recomendações" subtitle="Quantidade de leads gerados no mês"/>
            <CockpitRow onlyTitle="Funil de vendas" subtitle="Quantidade"/>
        </div>



        <div v-if="title=='NPS'">
            <b-row cols-md="3" cols="1" align-h="between">
                <b-col class="d-flex align-items-center justify-content-center py-3">
                    <CockpitComponent style="min-width:300px; max-width:300px; max-height:350px; min-height:350px;"
                    :title="'Mês'" :type="'integer'"
                    :goal="Number(0).toString()"
                    :value="0"
                    :target="0"
                    :value_formatter="'{value}%'"
                    />
                </b-col>
                <b-col class="d-flex align-items-center justify-content-center py-3">
                    <CockpitComponent style="min-width:300px; max-width:300px; max-height:350px; min-height:350px;"
                    :title="'Semestre'" :type="'integer'"
                    :goal="Number(0).toString()"
                    :value="0"
                    :target="0"
                    :value_formatter="'{value}%'"
                    />
                </b-col>
                <b-col class="d-flex align-items-center justify-content-center py-3">
                    <CockpitPieComponent style="min-width:300px; max-width:300px; max-height:350px; min-height:350px;"
                    :title="'Quantidade de respostas'"
                    :list="[
                        { value: 75, name: 'Respondidos'},
                        { value: 25, name: 'Não respondidos'},
                    ]"
                    />
                </b-col>
                <b-col class="d-flex align-items-center justify-content-center py-3">
                    <CockpitPieComponent style="min-width:300px; max-width:300px; max-height:350px; min-height:350px;"
                    :title="'Detratores/Neutros/Promotores'"
                    :titleFontSize="17"
                    :list="[
                        { value: 40, name: 'Promotores'},
                        { value: 25, name: 'Neutros'},
                        { value: 35, name: 'Detratores'},
                    ]"
                    />
                </b-col>
            </b-row>
        </div>




    </div>
</template>

<script>
import CockpitComponent from '@/components/CockpitComponent.vue'
// // import GraphicBarComponent from '@/components/GraphicBarComponent.vue'
import CockpitRow from './CockpitRow.vue'
import CockpitPieComponent from '@/components/CockpitPieComponent.vue'
// import GraphicLineComponent from '@/components/GraphicLineComponent.vue'

export default {
    name: "CockpitDetails",
    components:{
        CockpitRow,
        CockpitComponent,
        CockpitPieComponent,
        // GraphicBarComponent,
        // GraphicLineComponent,
    },
    props:{
        title: String,
        data: Object,
    },
}
</script>

<style>

</style>