<template>
    <div id="attachment">
        <DefaultPageComponent
        :loading="loading"
        :title="'Alterar anexo'"
        :haveBackButton="true"
        @onBack="$router.push('/attachments')"
        >

        <!-- CONTENT -->
        <div class="my-4" style="height:100%;">
            <b-row class="mx-2">
                <div class="d-flex w-100">
                    <div class="form-group">
                        <b-col>
                            <label for="">Título</label>
                            <input type="text" class="form-control form-control-sm" id="inputUserName" style="box-shadow: none; height: 56px;" v-model="attachment.title">
                        </b-col>
                    </div>
                </div>
            </b-row>
            <b-row class="mx-4" style="min-height: calc(100% - 56px);">
                <b-col style="border: 1px dashed #535252; display: flex; justify-content: center;" :style="attachment.base64 !== '' ? 'align-items: start;' : 'align-items: center;'">
                    <span>
                        <CustomButton
                        :loading="loading || loadingSubmit"
                        v-if="attachment.file_path === ''"
                        @click="openFileImport"
                        > 
                            Procurar...
                        </CustomButton>
                        <input 
                            type="file"
                            ref="fileInput"
                            style="display: none;"
                            @change="handleFileImport"
                        />
                    </span>
                    <span v-if="attachment.file_path !== ''">
                        <div class="d-flex flex-column align-items-center">
                            <img :src="attachment.file_path" alt="" style="height: 50vh; width: 40vh;" class="py-4">
                            <label for="">{{ attachment.file_name }}</label>
                        </div>
                    </span>
                    <span v-if="attachment.file_path !== ''">
                        <div class="d-flex justify-content-end align-items-center">
                            <b-button 
                                size="sm"
                                style="color: #000; border: none; box-shadow: none; background-color: transparent; font-size:10px; border: 1px solid #535252;"
                                class="py-2 ml-3 mt-4"
                                @click="removeFileInMemory"
                            >
                                <img src="@/assets/delete_orange.png">
                            </b-button>
                        </div>
                    </span>
                </b-col>
            </b-row>
        </div>

        <template #footer>
            <b-row>
                <b-col class="d-flex justify-content-end">
                    <CancelButton
                    @click="$router.push('/attachments')"
                    >
                        Cancelar
                    </CancelButton>
                    <CustomButton
                    :loading="loading || loadingSubmit"
                    :disabled="attachment.title === '' || attachment.file_path === ''"
                    @click="saveAttachment"
                    > 
                        Salvar
                    </CustomButton>
                </b-col>
            </b-row>
        </template>

        </DefaultPageComponent>

    </div>  
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import CustomButton from '@/components/CustomButton.vue';
import CancelButton from '@/components/CancelButton.vue';
export default {
    name: 'FormTagView',
    components:{
        DefaultPageComponent,
        CustomButton,
        CancelButton,
    },
    data () {
        return {
            loading: false,
            loadingSubmit: false,
            changedFile: false,
            attachment: {
                title: '',
                file_name: '',
                file_path: '',
                base64: '',
            },
        }
    },
    mounted () {
        this.getAttachment();
    },
    methods: {
        getAttachment() {
            this.loading=true;
            this.$axios.get(`attachments/${this.$router.currentRoute.params.id}`).then((response) => {
                this.attachment = response.data.data;
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar o anexo.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                });
            })
            .finally(()=>{
                this.loading=false;
            })
        },
        openFileImport () {
            if (this.loading || this.attachment.base64 !== '')
                return;

            this.$refs.fileInput.click();
        },
        handleFileImport(event) {
            const file = event.target.files[0];
            if (!file.type.includes("image")) return;

            this.loadFile(file);
        },
        loadFile(file) {
            this.changedFile = true;
            const reader = new FileReader();

            reader.onload = (e) => {
                this.attachment.base64 = e.target.result;
                this.attachment.file_path = e.target.result;
                this.attachment.file_name = file.name;
            };

            reader.readAsDataURL(file);
        },
        removeFileInMemory () {
            this.attachment.file_name = '';
            this.attachment.file_path = '';
            this.attachment.base64 = '';

            this.$refs.fileInput.reset();
        },
        async saveAttachment () {
            if (this.attachment.title === '' || this.attachment.file_name === '' || this.attachment.base64 === '')
                return;

            this.loading = true;

            let attachment = this.attachment;

            attachment.changedFile = this.changedFile;

            this.$axios.put(`attachments/${this.$router.currentRoute.params.id}`, attachment).then(() => {
                this.$swal({
                    title: 'Registro atualizado',
                    text: 'Registro atualizado com sucesso.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#EB694A',
                    allowOutsideClick: false,
                    focusConfirm: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                this.$router.push('/attachments');
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível atualizar o anexo.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            }).finally(() => {
                this.loading = false;
            });
        }
    }
}
</script>

<style scoped>
.form-control {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
</style>

<style>
    #attachment {
        height: calc(100vh - 125px);
    }

    .custom-control-input:checked ~ .custom-control-label::before {
        color: #fff !important;
        border-color: #EB694A !important;
        background-color: #EB694A !important;
        box-shadow: none !important;
    }
</style>
