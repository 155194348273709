<template>
    <div id="integration">
        <DefaultPageComponent
        :loading="loading"
        :title="'Dados integração'"
        :haveBackButton="true"
        @onBack="$router.push({name: 'dashboard'})"
        >

        <!-- CONTENT -->
        <div class="py-4">
            <b-row class="mx-0 py-2">
                <div class="form-group">
                    <b-col>
                        <label for="">API da instância</label>
                        <input type="text" class="form-control form-control-sm" id="inputUserName" style="box-shadow: none; height: 56px;" v-model="integration.instance_api">
                    </b-col>
                </div>
            </b-row>
            <b-row class="mx-0 py-2">
                <div class="d-flex w-100">
                    <div class="form-group">
                        <b-col>
                            <label for="">ID da instância</label>
                            <input type="text" class="form-control form-control-sm" id="inputUserName" style="box-shadow: none; height: 56px;" v-model="integration.instance_id">
                        </b-col>
                    </div>
                    <div class="form-group">
                        <b-col>
                            <label for="">Token da instância</label>
                            <input type="text" class="form-control form-control-sm" id="inputUserName" style="box-shadow: none; height: 56px;" v-model="integration.instance_token">
                        </b-col>
                    </div>
                </div>
            </b-row>
        </div>

        <template #footer>
            <b-row>
                <b-col class="d-flex justify-content-end">
                    <CancelButton
                    @click="$router.push({ name: 'dashboard' })"
                    >
                        Cancelar
                    </CancelButton>
                    <CustomButton
                    :loading="loading || loadingSubmit"
                    :disabled="(
                        !integration.instance_api ||
                        !integration.instance_id ||
                        !integration.instance_token
                    )"
                    @click="saveIntegration"
                    > 
                        Salvar
                    </CustomButton>
                </b-col>
            </b-row>
        </template>

        </DefaultPageComponent>

    </div>  
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import CancelButton from '@/components/CancelButton.vue';
import CustomButton from '@/components/CustomButton.vue';
export default {
    name: 'IntegrationView',
    components:{
        DefaultPageComponent,
        CancelButton,
        CustomButton,
    },
    data () {
        return {
            loading: false,
            loadingSubmit: false,
            integration: {
                id: '',
                instance_api: '',
                instance_id: '',
                instance_token: ''
            },
        }
    },
    mounted () {
        this.getIntegration();
    },
    methods: {
        async getIntegration() {
            this.loading = true;
            this.$axios.get('integrations').then((response) => {
                this.integration = response.data.data;
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar integração.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                });
            }).finally(() => this.loading = false);
        },
        async saveIntegration () {
            if (this.loading) 
                return;

            if (this.integration.instance_api.trim() === '' || this.integration.instance_id.trim() === '' || this.integration.instance_token.trim() === '') {
                this.$swal({
                    title: 'Atenção',
                    text: 'Por favor, preencha todos os campos antes de salvar.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#EB694A',
                    allowOutsideClick: false,
                    focusConfirm: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            }

            this.loading = true;

            let integration = this.integration;

            this.$axios.post('integrations', integration).then(() => {
                this.$swal({
                    title: 'Sucesso',
                    text: 'Registro cadastrado/atualizado com sucesso.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28A745',
                    allowOutsideClick: false,
                    focusConfirm: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível cadastrar/atualizar o registro.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            }).finally(() => {
                this.loading = false;
            });
        }
    }
}
</script>

<style>
    #integration {
        height: calc(100vh - 125px);
    }
</style>
