import Cookie from '@/service/cookie';
import axios from 'axios';
import store from '@/store';

export default {
    async redirectIfNotAuthenticated(to, from, next) {
        const token = Cookie.getToken();

        if (!token) {
            next({ name: 'login' });
        }

        await axios.get('me').then((response) => {
            if (!store?.state?.user?.id) {
                store.commit('user/STORE_USER', response.data.data);
            }
            Cookie.setToken(response.data.data.token_type + ' ' + response.data.data.access_token)
        }).catch(() => {
            Cookie.deleteToken();
            next({ name: 'login' });
        });

        next();
    },

    redirectIfAuthenticated(to, from, next) {
        const token = Cookie.getToken();
        let n;

        if (token) {
            n = { name: 'dashboard' };
        }

        next(n);
    },
};
