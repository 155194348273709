import axios from 'axios';
import Vue from 'vue';
import Cookie from '@/service/cookie';
import router from '@/router';

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.headers['Content-Type'] = 'application/json';
axios.defaults.headers['Accept'] = 'application/json';

axios.interceptors.request.use(function(config) {
    const token = Cookie.getToken();

    if (token) {
        config.headers['Authorization'] = token;
    }

    return config;
});

axios.interceptors.response.use(function(response) {
    return response;
}, function(error) {
    if (error.response && error.response.status === 401) {
        Cookie.deleteToken();
        router.push({ name: 'login' });
    }

    return Promise.reject(error);
});

Vue.prototype.$axios = axios;

