<template>
    <div id="tag">
        <DefaultPageComponent
        :loading="loading"
        :title="'Editar tag'"
        :haveBackButton="true"
        @onBack="$router.push('/tags')"
        >

        <!-- CONTENT -->
        <div class="py-3">
            <b-row class="py-2 mx-2">
                <div class="d-flex w-100">
                    <div class="form-group" style="width: 570px;">
                        <b-col>
                            <label for="">Tag</label>
                            <input type="text" class="form-control form-control-sm" id="inputUserName" style="box-shadow: none; height: 56px;" v-model="tag.tag" disabled>
                        </b-col>
                    </div>
                    <div class="form-group flex-grow-1">
                        <b-col>
                            <label for="">Descrição</label>
                            <input type="text" class="form-control form-control-sm" id="inputUserName" style="box-shadow: none; height: 56px;" v-model="tag.description">
                        </b-col>
                    </div>
                </div>
            </b-row>
            <b-row class="py-2 mx-2">
                <div class="d-flex w-100">
                    <b-col>
                        <b-form-radio name="radio-size" v-model="tag.type" :value="false">Tag utililizada em tela</b-form-radio>
                    </b-col>
                </div>
            </b-row>
            <b-row class="py-2 mx-2">
                <div class="d-flex w-100">
                    <b-col>
                        <b-form-radio name="radio-size" v-model="tag.type" :value="true">Tag utilizada na importação de tabela</b-form-radio>
                    </b-col>
                </div>
            </b-row>
        </div>

        <template #footer>
            <b-row>
                <b-col class="d-flex justify-content-end">
                    <CancelButton
                    @click="$router.push('/tags')"
                    >
                        Cancelar
                    </CancelButton>
                    <CustomButton
                    :loading="loading || loadingSubmit"
                    :disabled="tag.title === '' || tag.description === '' || tag.type === ''"
                    @click="saveTag"
                    > 
                        Salvar
                    </CustomButton>
                </b-col>
            </b-row>
        </template>

        </DefaultPageComponent>

    </div>  
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import CustomButton from '@/components/CustomButton.vue';
import CancelButton from '@/components/CancelButton.vue';
export default {
    name: 'TagEditView',
    components:{
        DefaultPageComponent,
        CustomButton,
        CancelButton,
    },
    data () {
        return {
            loadingSubmit: false,
            loading: false,
            tag: {
                tag: '',
                description: '',
                type: '',
            },
        }
    },
    mounted () {
        this.getTag();
    },
    methods: {
        getTag() {
            this.loading=true;
            this.$axios.get(`tags/${this.$router.currentRoute.params.id}`).then((response) => {
                this.tag = response.data.data;
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar a tag.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                });
            })
            .finally(()=>{
                this.loading=false;  
            })
        },
        async saveTag () {
            if (this.tag.title === '' || this.tag.description === '' || this.tag.type === ''){
                this.$swal({
                    title: 'Atenção',
                    text: 'Existe(m) campo(s) obrigatório(s) ainda não preenchido(s)!',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                });
                return;
            }

            this.loading = true;

            let tag = this.tag;

            this.$axios.put(`tags/${this.$router.currentRoute.params.id}`, tag).then(() => {
                this.$swal({
                    title: 'Registro atualizado',
                    text: 'Registro atualizado com sucesso.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#EB694A',
                    allowOutsideClick: false,
                    focusConfirm: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                this.$router.push('/tags');
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível atualizar a tag.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            }).finally(() => {
                this.loading = false;
            });
        }
    }
}
</script>

<style>
    #customer {
        height: calc(100vh - 125px);
    }

    .custom-control-input:checked ~ .custom-control-label::before {
        color: #fff !important;
        border-color: #EB694A !important;
        background-color: #EB694A !important;
        box-shadow: none !important;
    }
</style>
