<template>
    <div class="d-flex align-items-center justify-content-center" style="height: 100%; width: 100%;">
    <div style="height: 100%; width: 100%;">
        <b-form @submit.stop.prevent="onSubmit" style="height: 100%; width: 100%;">
        <DefaultPageComponent
        :loading="loading"
        :title="'Editar unidade'"
        :haveBackButton="true"
        @onBack="$router.push({name: 'companies'})"
        >

        <!-- CONTENT -->
        <div class="py-3">
            <div class="mx-0 py-2 row d-flex justify-content-left">
                <div class="col-md-12">
                    <b-form-group id="inputGroupName" label="Nome" label-for="inputGroupName">
                        <b-form-input
                            id="inputName"
                            name="inputName"
                            v-model="company.name"
                            style="min-height:56px;"
                            v-validate="{ required: true }"
                            :state="validateState('inputName')"
                            aria-describedby="inputNameFeedback"
                            data-vv-as="Nome"
                            :disabled="loading"
                        ></b-form-input>

                        <b-form-invalid-feedback id="inputNameFeedback">{{ veeErrors.first('inputName') }}</b-form-invalid-feedback>
                    </b-form-group>
                </div>
            </div>
            <div class="mx-0 py-2 row d-flex justify-content-left">
                <div class="col-md-3">
                    <b-form-group id="inputGroupZipCode" label="CEP" label-for="inputGroupZipCode">
                        <b-form-input
                            id="inputZipCode"
                            name="inputZipCode"
                            v-model="company.zip_code"
                            style="min-height:56px;"
                            v-validate="{ zipCode: true }"
                            :state="validateState('inputZipCode')"
                            aria-describedby="inputZipCodeFeedback"
                            data-vv-as="CEP"
                            v-mask="'##.###-###'"
                            @blur="searchZipCode"
                            :disabled="loading"
                        ></b-form-input>

                        <b-form-invalid-feedback id="inputZipCodeFeedback">{{ veeErrors.first('inputZipCode') }}</b-form-invalid-feedback>
                    </b-form-group>
                </div>
                <div class="col-md-6">
                    <b-form-group id="inputGroupAddress" label="Endereço" label-for="inputGroupAddress">
                        <b-form-input
                            id="inputAddress"
                            name="inputAddress"
                            v-model="company.address"
                            style="min-height:56px;"
                            v-validate="{}"
                            :state="validateState('inputAddress')"
                            aria-describedby="inputAddressFeedback"
                            data-vv-as="Endereço"
                            :disabled="loading"
                        ></b-form-input>

                        <b-form-invalid-feedback id="inputAddressFeedback">{{ veeErrors.first('inputAddress') }}</b-form-invalid-feedback>
                    </b-form-group>
                </div>
                <div class="col-md-3">
                    <b-form-group id="inputGroupNumber" label="Número" label-for="inputGroupNumber">
                        <b-form-input
                            id="inputNumber"
                            name="inputNumber"
                            v-model="company.number"
                            style="min-height:56px;"
                            v-validate="{}"
                            :state="validateState('inputNumber')"
                            aria-describedby="inputNumberFeedback"
                            data-vv-as="Número"
                            :disabled="loading"
                        ></b-form-input>

                        <b-form-invalid-feedback id="inputNumberFeedback">{{ veeErrors.first('inputNumber') }}</b-form-invalid-feedback>
                    </b-form-group>
                </div>
            </div>
            <div class="mx-0 py-2 row d-flex justify-content-left">
                <div class="col-md-5">
                    <b-form-group id="inputGroupNeighborhood" label="Bairro" label-for="inputGroupNeighborhood">
                        <b-form-input
                            id="inputNeighborhood"
                            name="inputNeighborhood"
                            v-model="company.neighborhood"
                            style="min-height:56px;"
                            v-validate="{}"
                            :state="validateState('inputNeighborhood')"
                            aria-describedby="inputNeighborhoodFeedback"
                            data-vv-as="Bairro"
                            :disabled="loading"
                        ></b-form-input>

                        <b-form-invalid-feedback id="inputNeighborhoodFeedback">{{ veeErrors.first('inputNeighborhood') }}</b-form-invalid-feedback>
                    </b-form-group>
                </div>
                <div class="col-md-5">
                    <b-form-group id="inputGroupCity" label="Cidade" label-for="inputGroupCity">
                        <b-form-input
                            id="inputCity"
                            name="inputCity"
                            v-model="company.city"
                            style="min-height:56px;"
                            v-validate="{}"
                            :state="validateState('inputCity')"
                            aria-describedby="inputCityFeedback"
                            data-vv-as="Cidade"
                            :disabled="loading"
                        ></b-form-input>

                        <b-form-invalid-feedback id="inputCityFeedback">{{ veeErrors.first('inputCity') }}</b-form-invalid-feedback>
                    </b-form-group>
                </div>
                <div class="col-md-2">
                    <b-form-group id="inputState" label="UF" label-for="inputGroupState">
                        <b-form-select
                            id="inputState"
                            name="inputState"
                            v-model="company.state_id"
                            style="min-height:56px;"
                            class="form-control"
                            v-validate="{}"
                            :state="validateState('inputState')"
                            aria-describedby="inputStateFeedback"
                            data-vv-as="UF"
                            :disabled="loading"
                        >
                            <option value="" key=""></option>
                            <option v-for="state in states" :value="state.id" :key="state.id">{{ state.acronym }}</option>
                        </b-form-select>

                        <b-form-invalid-feedback id="inputStateFeedback">{{ veeErrors.first('inputState') }}</b-form-invalid-feedback>
                    </b-form-group>
                </div>
            </div>
            <!-- <div class="mx-0 py-2 row d-flex justify-content-left mt-4">
                <div class="col-12 text-right">
                    <button class="btn" style="background-color: #DDD; color: #FFF;" :style="formIsValid ? 'background-color: #28A745;' : ''" type="submit" :disabled="loadingSubmit">
                        <template v-if="loadingSubmit">
                            <b-spinner small class="mr-2" />
                        </template>
                        <template v-else>
                            Salvar
                        </template>
                    </button>
                </div>
            </div> -->
        </div>

        <template #footer>
            <div class="mx-0 row d-flex align-items-center justify-content-end">
                <CancelButton
                @click="$router.push({ name: 'companies' })"
                >
                    Cancelar
                </CancelButton>
                <CustomButton
                :type="'submit'"
                :loading="loading || loadingSubmit"
                > 
                    Salvar
                </CustomButton>
            </div>
        </template>

        </DefaultPageComponent>
        </b-form>
    </div>
    </div>
</template>

<script>
// import DefaultHeader from '@/components/DefaultHeader.vue';
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import CancelButton from '@/components/CancelButton.vue';
import CustomButton from '@/components/CustomButton.vue';
export default {
    name: 'CompanyEditView',
    components:{
        DefaultPageComponent,
        CancelButton,
        CustomButton,
        // DefaultHeader,
    },
    async mounted() {
        await this.getStates();
        await this.getCompany();
        setTimeout(() => {
            this.loading = false;
        }, 1000);
    },
    created() {
        this.$validator.localize('en', {
            custom: {
                inputName: {
                    required: 'Campo obrigatório.',
                },
                inputZipCode: {
                    zipCode: 'CEP inválido.'
                }
            },
        });
    },
    data() {
        return {
            loading: true,
            formIsValid: false,
            company: {
                name: '',
                zip_code: '',
                address: '',
                number: '',
                neighborhood: '',
                city: '',
                state_id: '',
            },
            loadingSubmit: false,
            states: [],
        }
    },
    methods: {
        async getStates() {
            this.$axios.get('states').then((response) => {
                this.states = response.data.data;
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar os estados.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            });
        },
        async getCompany() {
            this.$axios.get(`companies/${this.$router.currentRoute.params.id}`).then((response) => {
                this.company = response.data.data;
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar a unidade.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                });
                return;
            });
        },
        validateState(ref) {
            if (
                this.veeFields[ref] &&
                (this.veeFields[ref].dirty || this.veeFields[ref].validated)
            ) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        async validateForm() {
            let response = await this.$validator.validateAll();
            
            if (response)
                this.formIsValid = true;
            else
                this.formIsValid = false;
        },
        async searchZipCode() {
            this.loadingSubmit = true;
            await this.$axios.post(`https://brasilapi.com.br/api/cep/v2/${this.company.zip_code}`).then((response) => {
                if (response.status == 200) {
                    this.company.address = response.data.street;
                    this.company.neighborhood = response.data.neighborhood;
                    this.company.city = response.data.city;
                    this.company.state_id = response.data.state;
                } else {
                    this.company.address = "";
                    this.company.neighborhood = "";
                    this.company.city = "";
                    this.company.state_id = "";
                }
            });
            this.loadingSubmit = false;
        },
        onSubmit() {
            this.$validator.validateAll().then(result => {
                if (!result) {
                    return;
                }

                this.loadingSubmit = true;

                let objCompany = this.company;
                objCompany.zip_code = objCompany.zip_code.replace(/\D/g, '');

                this.$axios.put(`companies/${this.$router.currentRoute.params.id}`, objCompany).then(() => {
                    this.$swal({
                        title: 'Sucesso',
                        text: 'Unidade editada com sucesso.',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#28A745',
                        allowOutsideClick: false,
                        focusConfirm: false,
                        customClass: {
                            confirmButton: 'custom-confirm-button-class',
                        }
                    });
                    this.loadingSubmit = false;
                    this.company = {};
                    this.$router.push({ name: 'companies' });
                }).catch(() => {
                    this.$swal({
                        title: 'Erro',
                        text: 'Não foi possível editar a unidade.',
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#C44C30',
                        allowOutsideClick: false,
                        customClass: {
                            confirmButton: 'custom-confirm-button-class',
                        }
                    });
                    this.loadingSubmit = false;
                });
            });
        },
    },
    watch: {
        'company.name': 'validateForm',
    }
}
</script>
