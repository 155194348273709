<template>
    <div class="d-flex align-items-center justify-content-center" style="height: 100%; width: 100%">
    <div style="height: 100%; width: 100%">
        <DefaultPageComponent
        :loading="loading"
        :title="'Editar perfil'"
        :haveBackButton="true"
        @onBack="$router.push({name: 'dashboard'})"
        >

        <!-- CONTENT -->
        <div class="py-3">
            <div class="mx-0 py-2 row d-flex justify-content-left align-items-center">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="inputUserName">Nome</label>
                        <input type="email" class="form-control form-control-sm" id="inputFirstName" style="box-shadow: none; min-height:56px;" v-model="profile.first_name">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="inputUserName">Sobrenome</label>
                        <input type="email" class="form-control form-control-sm" id="inputLastName" style="box-shadow: none; min-height:56px;" v-model="profile.last_name">
                    </div>
                </div>
            </div>
            <b-row v-if="!this.profile.first_name || !this.profile.last_name" class="mx-0 mb-1" style="color: #D01F1F">
                <b-col style="transform:translateY(-5px);">
                    <span v-if="!this.profile.first_name"> Campo obrigatório </span> 
                </b-col>
                <b-col style="transform:translateY(-5px);">
                    <span v-if="!this.profile.last_name"> Campo obrigatório </span>
                </b-col>
            </b-row>
            <div class="mx-0 py-2 row d-flex justify-content-left align-items-center">
                <div class="col-6">
                    <div class="form-group">
                        <label for="inputUserName">E-mail</label>
                        <input type="email" class="form-control form-control-sm" id="inputEmail" style="box-shadow: none; min-height:56px;" v-model="profile.email">
                    </div>
                </div>
            </div>
            <b-row v-if="!isValidEmail" class="mx-0 mb-1" style="color: #D01F1F">
                <b-col style="transform:translateY(-5px);">
                    <span v-if="this.profile.email"> Email inválido </span> 
                    <span v-else> Campo obrigatório </span> 
                </b-col>
                <b-col></b-col>
            </b-row>
            <div class="mx-0 py-2 row d-flex justify-content-left align-items-center">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="inputPassword">Senha</label>
                        <input @input="changedPassword" type="password" class="form-control form-control-sm" id="inputPassword" style="box-shadow: none; min-height:56px;" v-model="password">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="inputConfirmPassword">Confirmar senha</label>
                        <input @input="changedPassword" type="password" class="form-control form-control-sm" id="inputConfirmPassword" style="box-shadow: none; min-height:56px;" v-model="confirmPassword">
                    </div>
                </div>
            </div>
            <b-row v-if="!isValidEqual && editedPass" class="mx-0" style="color: #D01F1F">
                <b-col></b-col>
                <b-col style="transform:translateY(-5px);">
                    <span> As senhas devem ser iguais </span> 
                </b-col>
            </b-row>
            <b-row v-if="editedPass" class="mx-3 my-2 mt-3">
                <b-col>
                    <b-row>
                        <h3 class="form-subtitle" style="font-size:18px; font-weight:600; color: #5C5C62;"> Sua senha deve conter</h3>
                    </b-row>


                    <b-row v-if="isValidLength" style="color: #19B377">
                        <b-icon class="h5 mr-2" icon="check2"></b-icon><span style="transform: translateY(-2px)"> Mínimo 6 caracteres </span>
                    </b-row>
                    <b-row v-else style="color: #D01F1F">
                        <b-icon class="h5 mr-2" icon="x-circle"></b-icon><span style="transform: translateY(-2px)"> Mínimo 6 caracteres </span>
                    </b-row>


                    <b-row v-if="isValidNumber" style="color: #19B377">
                        <b-icon class="h5 mr-2" icon="check2"></b-icon><span style="transform: translateY(-2px)"> Pelo menos um número </span> 
                    </b-row>
                    <b-row v-else style="color: #D01F1F">
                        <b-icon class="h5 mr-2" icon="x-circle"></b-icon><span style="transform: translateY(-2px)"> Pelo menos um número </span> 
                    </b-row>


                    <b-row v-if="isValidSymbol" style="color: #19B377">
                        <b-icon class="h5 mr-2" icon="check2"></b-icon><span style="transform: translateY(-2px)"> Pelo menos um caractere especial </span> 
                    </b-row>
                    <b-row v-else style="color: #D01F1F">
                        <b-icon class="h5 mr-2" icon="x-circle"></b-icon><span style="transform: translateY(-2px)"> Pelo menos um caractere especial </span> 
                    </b-row>


                    <b-row v-if="isValidUpper" style="color: #19B377">
                        <b-icon class="h5 mr-2" icon="check2"></b-icon><span style="transform: translateY(-2px)"> Pelo menos uma letra maiúscula </span> 
                    </b-row>
                    <b-row v-else style="color: #D01F1F">
                        <b-icon class="h5 mr-2" icon="x-circle"></b-icon><span style="transform: translateY(-2px)"> Pelo menos uma letra maiúscula </span> 
                    </b-row>
                </b-col>
            </b-row>
        </div>

        <template #footer>
            <div class="row d-flex justify-content-end align-items-center">
                    <CancelButton
                    @click="$router.push({ name: 'dashboard' })"
                    >
                        Cancelar
                    </CancelButton>
                    <CustomButton
                    :disabled="!isValid"
                    :loading="loading || loadingSubmit"
                    @click="updateMe"
                    > 
                        Atualizar
                    </CustomButton>
            </div>
        </template>

        </DefaultPageComponent>

    </div>
    </div>
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import CancelButton from '@/components/CancelButton.vue';
import CustomButton from '@/components/CustomButton.vue';
export default {
    name: 'ProfileView',
    components:{
        DefaultPageComponent,
        CancelButton,
        CustomButton,
    },
    created() {
        this.editedPass = false;
        this.password = '';
        this.confirmPassword = '';
    },
    mounted() {
        this.editedPass = false;
        this.password = '';
        this.confirmPassword = '';
        this.getMe();
    },
    data() {
        return {
            loading: true,
            profile: {},
            editedPass: false,
            password: '',
            confirmPassword: '',
            loadingSubmit: false,
        }
    },
    computed: {
        isValidEmail() {
            return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.profile.email);
        },
        isValidLength(){
            return (this.password.length>=6);
        },
        isValidNumber(){
            return (this.password.replace(/\D/g, '').length>0);
        },
        isValidSymbol(){
            return /\W|_/.test(this.password);
        },
        isValidUpper(){
            return /[A-Z]/.test(this.password);
        },
        isValidEqual(){
            return (this.password == this.confirmPassword);
        },
        isValidPassword(){
            return (
                this.isValidLength &&
                this.isValidNumber &&
                this.isValidSymbol &&
                this.isValidUpper &&
                this.isValidEqual
            );
        },
        isValid(){
            return (
                (!this.editedPass || this.isValidPassword) &&
                this.profile.first_name &&
                this.profile.last_name &&
                this.isValidEmail
            )
        },
    },
    methods: {
        changedPassword(){
            if(!this.editedPass){
                this.password='';
                this.confirmPassword='';
            }
            this.editedPass=true;
        },
        getMe() {
            this.loading = true;
            this.$axios.get('me').then((response) => {
                this.profile = response.data.data;
                this.password = "          ";
                this.confirmPassword = "";
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar os dados do perfil.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                });
                return;
            })
            .finally(()=>{
                this.loading = false;
            })
        },
        updateMe() {
            if(this.editedPass && this.password != this.confirmPassword) {
                this.$swal({
                    title: 'Atenção',
                    text: 'Senha e confirmação devem ser iguais.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                });
                return;
            }
            if(!this.isValid) {
                this.$swal({
                    title: 'Atenção',
                    text: 'Preencha o formulário antes de atualizar.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                });
                return;
            }

            this.loadingSubmit = true;

            if(this.editedPass) this.profile.password=this.password;
            this.$axios.put('me', this.profile).then((response) => {
                this.$store.commit('user/STORE_USER', response.data.data);
                delete this.profile.password;
                this.password = "          ";
                this.confirmPassword = "";
                this.$swal({
                    title: 'Sucesso',
                    text: 'Perfil atualizado com sucesso.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28A745',
                    allowOutsideClick: false,
                    focusConfirm: true,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                this.$router.push({name: 'dashboard'})
                this.loadingSubmit = false;
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível atualizar seu perfil.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                this.loadingSubmit = false;
            });
        }
    }
}
</script>
