<template>
    <div class="d-flex align-items-center justify-content-center" style="height: 100%; width: 100%;">
    <div style="height: 100%; width: 100%;">
        <b-form @submit.stop.prevent="onSubmit" style="height: 100%; width: 100%;">
        <DefaultPageComponent
        :loading="loading"
        :title="'Nova célula'"
        :haveBackButton="true"
        @onBack="$router.push({name: 'cells'})"
        >

        <!-- CONTENT -->
        <div class="py-3">
            <div class="mx-0 py-2 row d-flex justify-content-left">
                <div class="col-md-3">
                    <b-form-group id="inputGroupAdvisor" label="Líder da célula" label-for="inputGroupAdvisor">
                        <b-form-select
                            id="inputAdvisor"
                            name="inputAdvisor"
                            v-model="cell.advisor_id"
                            class="form-control"
                            style="min-height:56px;"
                            v-validate="{ required: false }"
                            :state="validateState('inputAdvisor')"
                            aria-describedby="inputAdvisorFeedback"
                            data-vv-as="Líder da célula"
                            :disabled="loading"
                        >
                            <option value=""> Selecione </option>
                            <option v-for="item in advisors" :key="item.id" :value="item.id"> {{ item.first_name+' '+item.last_name }} </option>
                        </b-form-select>

                        <b-form-invalid-feedback id="inputAdvisorFeedback">{{ veeErrors.first('inputAdvisor') }}</b-form-invalid-feedback>
                    </b-form-group>
                </div>
                <div class="col-md-3">
                    <b-form-group id="inputGroupCell" label="Célula" label-for="inputGroupCell">
                        <b-form-input
                            id="inputCell"
                            name="inputCell"
                            v-model="cell.cell"
                            style="min-height:56px;"
                            v-validate="{ required: true }"
                            :state="validateState('inputCell')"
                            aria-describedby="inputCellFeedback"
                            data-vv-as="Célula"
                            :disabled="loading"
                        ></b-form-input>

                        <b-form-invalid-feedback id="inputCellFeedback">{{ veeErrors.first('inputCell') }}</b-form-invalid-feedback>
                    </b-form-group>
                </div>
                <div class="col-md-6">
                    <b-form-group id="inputGroupDescription" label="Descrição" label-for="inputGroupDescription">
                        <b-form-input
                            id="inputDescription"
                            name="inputDescription"
                            v-model="cell.description"
                            style="min-height:56px;"
                            v-validate="{ required: true }"
                            :state="validateState('inputDescription')"
                            aria-describedby="inputDescriptionFeedback"
                            data-vv-as="Descrição"
                            :disabled="loading"
                        ></b-form-input>

                        <b-form-invalid-feedback id="inputDescriptionFeedback">{{ veeErrors.first('inputDescription') }}</b-form-invalid-feedback>
                    </b-form-group>
                </div>
            </div>
            <div class="mx-0 py-2 row d-flex justify-content-left">
                <div class="col-md-12">
                    <b-form-group id="inputGroupExplanation" label="Detalhamento" label-for="inputGroupExplanation">
                        <b-form-textarea
                            id="inputExplanation"
                            name="inputExplanation"
                            v-model="cell.explanation"
                            v-validate="{}"
                            :state="validateState('inputExplanation')"
                            aria-describedby="inputExplanationFeedback"
                            data-vv-as="Detalhamento"
                            rows="5"
                            max-rows="5"
                            :disabled="loading"
                        ></b-form-textarea>

                        <b-form-invalid-feedback id="inputExplanationFeedback">{{ veeErrors.first('inputExplanation') }}</b-form-invalid-feedback>
                    </b-form-group>
                </div>
            </div>
        </div>

        <template #footer>
            <div class="mx-0 row d-flex align-items-center justify-content-end mx-0 mt-3">
                <CancelButton
                @click="$router.push({ name: 'cells' })"
                >
                    Cancelar
                </CancelButton>
                <CustomButton
                :loading="loading || loadingSubmit"
                :type="'submit'"
                > 
                    Cadastrar
                </CustomButton>
            </div>
        </template>

        </DefaultPageComponent>
        </b-form>
    </div>
    </div>
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import CancelButton from '@/components/CancelButton.vue';
import CustomButton from '@/components/CustomButton.vue';
export default {
    name: 'CellFormView',
    components:{
        DefaultPageComponent,
        CancelButton,
        CustomButton,
    },
    mounted() {
        this.getAdvisors();
        setTimeout(() => {
            this.loading = false;
        }, 1000);
    },
    created() {
        this.$validator.localize('en', {
            custom: {
                inputCell: {
                    required: 'Campo obrigatório.',
                },
                inputDescription: {
                    required: 'Campo obrigatório.',
                },
            },
        });
    },
    data() {
        return {
            loading: true,
            formIsValid: false,
            advisors: [],
            cell: {
                cell: '',
                advisor_id: '',
                description: '',
                explanation: '',
            },
            loadingSubmit: false,
        }
    },
    methods: {
        async getAdvisors(){
            const response = await this.$axios.get('advisors')
            this.advisors = response.data.data.filter(e=>e.status);
        },
        validateState(ref) {
            if (
                this.veeFields[ref] &&
                (this.veeFields[ref].dirty || this.veeFields[ref].validated)
            ) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        async validateForm() {
            let response = await this.$validator.validateAll();
            
            if (response)
                this.formIsValid = true;
            else
                this.formIsValid = false;
        },
        onSubmit() {
            this.$validator.validateAll().then(result => {
                if (!result) {
                    return;
                }

                this.loadingSubmit = true;

                this.$axios.post('cells', this.cell).then(() => {
                    this.$swal({
                        title: 'Sucesso',
                        text: 'Célula cadastrada com sucesso.',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#28A745',
                        allowOutsideClick: false,
                        focusConfirm: false,
                        customClass: {
                            confirmButton: 'custom-confirm-button-class',
                        }
                    });
                    this.loadingSubmit = false;
                    this.cell = {};
                    this.$router.push({ name: 'cells' });
                }).catch(() => {
                    this.$swal({
                        title: 'Erro',
                        text: 'Não foi possível cadastrar a célula.',
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#C44C30',
                        allowOutsideClick: false,
                        customClass: {
                            confirmButton: 'custom-confirm-button-class',
                        }
                    });
                    this.loadingSubmit = false;
                });
            });
        },
    },
    watch: {
        'cell.cell': 'validateForm',
        'cell.description': 'validateForm',
        'cell.explanation': 'validateForm',
    }
}
</script>
