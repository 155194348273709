<template>
    <div class="d-flex align-items-center justify-content-center" style="height: 100%; width: 100%;">
    <div style="height: 100%; width: 100%;">
        <b-form @submit.stop.prevent="onSubmit" style="height: 100%; width: 100%;">
        <DefaultPageComponent
        :loading="loading"
        :title="'Editar usuário'"
        :haveBackButton="true"
        @onBack="$router.push({name: 'users'})"
        >

        <!-- CONTENT -->
        <div class="py-3">
            <div class="mx-0 py-2 row d-flex justify-content-left">
                <div class="col-md-6">
                    <b-form-group id="inputGroupFirstName" label="Nome" label-for="inputGroupFirstName">
                        <b-form-input
                            id="inputFirstName"
                            name="inputFirstName"
                            v-model="user.first_name"
                            style="min-height:56px;"
                            v-validate="{ required: true }"
                            :state="validateState('inputFirstName')"
                            aria-describedby="inputFirstNameFeedback"
                            data-vv-as="Nome"
                            :disabled="loading"
                        ></b-form-input>

                        <b-form-invalid-feedback id="inputFirstNameFeedback">{{ veeErrors.first('inputFirstName') }}</b-form-invalid-feedback>
                    </b-form-group>
                </div>
                <div class="col-md-6">
                    <b-form-group id="inputGroupLastName" label="Sobrenome" label-for="inputGroupLastName">
                        <b-form-input
                            id="inputLastName"
                            name="inputLastName"
                            v-model="user.last_name"
                            style="min-height:56px;"
                            v-validate="{ required: true }"
                            :state="validateState('inputLastName')"
                            aria-describedby="inputLastNameFeedback"
                            data-vv-as="Sobrenome"
                            :disabled="loading"
                        ></b-form-input>

                        <b-form-invalid-feedback id="inputLastNameFeedback">{{ veeErrors.first('inputLastName') }}</b-form-invalid-feedback>
                    </b-form-group>
                </div>
            </div>
            <div class="mx-0 py-2 row d-flex justify-content-left">
                <div class="col-md-6">
                    <b-form-group id="inputGroupCPF" label="CPF" label-for="inputGroupCPF">
                        <b-form-input
                            id="inputCPF"
                            name="inputCPF"
                            v-model="user.n_document"
                            style="min-height:56px;"
                            v-validate="{ cpf: true }"
                            :state="validateState('inputCPF')"
                            aria-describedby="inputCPFFeedback"
                            data-vv-as="CPF"
                            v-mask="'###.###.###-##'"
                            :disabled="loading"
                        ></b-form-input>

                        <b-form-invalid-feedback id="inputCPFFeedback">{{ veeErrors.first('inputCPF') }}</b-form-invalid-feedback>
                    </b-form-group>
                </div>
                <div class="col-md-6">
                    <b-form-group id="inputGroupEmail" label="E-mail" label-for="inputGroupEmail">
                        <b-form-input
                            id="inputEmail"
                            name="inputEmail"
                            v-model="user.email"
                            style="min-height:56px;"
                            v-validate="{ required: true, email: true }"
                            :state="validateState('inputEmail')"
                            aria-describedby="inputEmailFeedback"
                            data-vv-as="Email"
                            :disabled="loading"
                        ></b-form-input>

                        <b-form-invalid-feedback id="inputEmailFeedback">{{ veeErrors.first('inputEmail') }}</b-form-invalid-feedback>
                    </b-form-group>
                </div>
            </div>
            <div class="mx-0 py-2 row d-flex justify-content-left">
                <div class="col-md-6">
                    <b-form-group id="inputGroupAdvisor" label="Assessor vinculado" label-for="inputGroupAdvisor">
                        <b-form-select
                            id="inputAdvisor"
                            name="inputAdvisor"
                            v-model="user.advisor_id"
                            class="form-control"
                            style="min-height:56px;"
                            v-validate="{ required: false }"
                            :state="validateState('inputAdvisor')"
                            aria-describedby="inputAdvisorFeedback"
                            data-vv-as="Assessor vinculado"
                            :disabled="loading"
                        >
                            <option value=""> Selecione </option>
                            <option v-for="item in advisors" :key="item.id" :value="item.id"> {{ item.first_name+' '+item.last_name }} </option>
                        </b-form-select>

                        <b-form-invalid-feedback id="inputAdvisorFeedback">{{ veeErrors.first('inputAdvisor') }}</b-form-invalid-feedback>
                    </b-form-group>
                </div>
                <div class="col-md-6">
                    <b-form-group id="inputGroupRole" label="Tipo usuário" label-for="inputGroupRole">
                        <b-form-select
                            id="inputRole"
                            name="inputRole"
                            v-model="user.role"
                            class="form-control"
                            style="min-height:56px;"
                            v-validate="{ required: true }"
                            :state="validateState('inputRole')"
                            aria-describedby="inputRoleFeedback"
                            data-vv-as="Tipo usuário"
                            :disabled="loading"
                        >
                            <option :value="null"> Selecione </option>
                            <option :value="0"> Comum </option>
                            <option :value="1"> Admin </option>
                        </b-form-select>

                        <b-form-invalid-feedback id="inputRoleFeedback">{{ veeErrors.first('inputRole') }}</b-form-invalid-feedback>
                    </b-form-group>
                </div>
            </div>
            <!-- <div class="mx-0 py-2 row d-flex justify-content-left">
                <div class="col-md-6">
                    <b-form-group id="inputGroupPassword" label="Senha" label-for="inputGroupPassword">
                        <b-form-input
                            type="password"
                            id="inputPassword"
                            name="inputPassword"
                            v-model="user.password"
                            style="min-height:56px;"
                            v-validate="{ required: true, min: 6, at_least_one_number: true, at_least_one_special_character: true, at_least_one_uppercase_letter: true }"
                            :state="validateState('inputPassword')"
                            aria-describedby="inputPasswordFeedback"
                            data-vv-as="Senha"
                            :disabled="loading"
                            @input="dirtyPassword = true"
                        ></b-form-input>

                        <b-form-invalid-feedback id="inputPasswordFeedback">{{ veeErrors.first('inputPassword') }}</b-form-invalid-feedback>
                    </b-form-group>
                </div>
                <div class="col-md-6">
                    <b-form-group id="inputGroupPasswordConfirmation" label="Confirmar Senha" label-for="inputGroupPasswordConfirmation">
                        <b-form-input
                            type="password"
                            id="inputPasswordConfirmation"
                            name="inputPasswordConfirmation"
                            v-model="user.password_confirmation"
                            style="min-height:56px;"
                            v-validate="{ required: true, confirmed:user.password }"
                            :state="validateState('inputPasswordConfirmation')"
                            aria-describedby="inputPasswordConfirmationFeedback"
                            data-vv-as="Confirmar Senha"
                            :disabled="loading || !dirtyPassword"
                        ></b-form-input>

                        <b-form-invalid-feedback id="inputPasswordConfirmationFeedback">{{ veeErrors.first('inputPasswordConfirmation') }}</b-form-invalid-feedback>
                    </b-form-group>
                </div>
            </div>
            <div class="mx-0 py-2 row d-flex justify-content-left">
                <div class="col-6" v-show="dirtyPassword">
                    <div class="form-group">
                        <span style="color: #5C5C62;">Sua senha deve conter</span> <br>
                        <b-icon :icon="user.password?.length < 6 ? 'x-circle' : 'check-circle'" class="mr-2" :color="user.password?.length < 6 ? '#D01F1F' : '#3CA40B'"></b-icon><span :style="user.password?.length < 6 ? 'color: #D01F1F !important;' : 'color: #3CA40B !important;'">Mínimo 6 caracteres</span><br>
                        <b-icon :icon="!/\d/.test(user.password) ? 'x-circle' : 'check-circle'" class="mr-2" :color="!/\d/.test(user.password) ? '#D01F1F' : '#3CA40B'"></b-icon><span :style="!/\d/.test(user.password) ? 'color: #D01F1F !important;' : 'color: #3CA40B !important;'">Pelo menos um número</span><br>
                        <b-icon :icon="!/[!@#$%^&*]/.test(user.password) ? 'x-circle' : 'check-circle'" class="mr-2" :color="!/[!@#$%^&*]/.test(user.password) ? '#D01F1F' : '#3CA40B'"></b-icon><span :style="!/[!@#$%^&*]/.test(user.password) ? 'color: #D01F1F !important;' : 'color: #3CA40B !important;'">Pelo menos um caractere especial</span><br>
                        <b-icon :icon="!/[A-Z]/.test(user.password) ? 'x-circle' : 'check-circle'" class="mr-2" :color="!/[A-Z]/.test(user.password) ? '#D01F1F' : '#3CA40B'"></b-icon><span :style="!/[A-Z]/.test(user.password) ? 'color: #D01F1F !important;' : 'color: #3CA40B !important;'">Pelo menos uma letra maiúscula</span><br>
                    </div>
                </div>
            </div> -->
        </div>

        <template #footer>
            <div class="mx-0 row d-flex align-items-center justify-content-end m-0 p-0 mt-3">
                <CancelButton
                @click="$router.push({ name: 'users' })"
                >
                    Cancelar
                </CancelButton>
                <CustomButton
                :type="'submit'"
                :loading="loading || loadingSubmit"
                > 
                    Salvar
                </CustomButton>
            </div>
        </template>

        </DefaultPageComponent>
        </b-form>
    </div>
    </div>
</template>

<script>
// import DefaultHeader from '@/components/DefaultHeader.vue';
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import CancelButton from '@/components/CancelButton.vue';
import CustomButton from '@/components/CustomButton.vue';
export default {
    name: 'UserEditView',
    components:{
        // DefaultHeader,
        DefaultPageComponent,
        CancelButton,
        CustomButton,
    },
    mounted() {
        this.getUser();
        this.getAdvisors();
        setTimeout(() => {
            this.loading = false;
        }, 1000);
    },
    created() {
        this.$validator.localize('en', {
            custom: {
                inputFirstName: {
                    required: 'Campo obrigatório.',
                },
                inputLastName: {
                    required: 'Campo obrigatório.',
                },
                inputEmail: {
                    required: 'Campo obrigatório.',
                    email: 'E-mail inválido.',
                },
                inputAdvisor: {
                    required: 'Campo obrigatório.',
                },
                inputRole: {
                    required: 'Campo obrigatório.',
                },
                // inputPassword: {
                //     required: 'Campo obrigatório.',
                //     // min: ' ',
                //     // at_least_one_number: ' ',
                //     // at_least_one_special_character: ' ',
                //     // at_least_one_uppercase_letter: ' ',
                // },
                // inputPasswordConfirmation: {
                //     confirmed: 'Senhas não conferem.',
                // }
            },
        });
    },
    data() {
        return {
            loading: true,
            formIsValid: false,
            dirtyPassword: false,
            user: {
                first_name: '',
                last_name: '',
                n_document: '',
                role: null,
                email: '',
                // password: '',
                // password_confirmation: '',
            },
            loadingSubmit: false,
            advisors: [],
        }
    },
    methods: {
        async getAdvisors(){
            const response = await this.$axios.get('advisors')
            this.advisors = response.data.data.filter(e=>e.status);
        },
        getUser() {
            this.$axios.get(`users/${this.$router.currentRoute.params.id}`).then((response) => {
                this.user = response.data.data;
                // this.user.password = 'Password1#';
                // this.user.password_confirmation = 'Password1#';
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar o usuário.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                });
                return;
            });
        },
        validateState(ref) {
            if (
                this.veeFields[ref] &&
                (this.veeFields[ref].dirty || this.veeFields[ref].validated)
            ) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        async validateForm() {
            let response = await this.$validator.validateAll();
            
            if (response)
                this.formIsValid = true;
            else
                this.formIsValid = false;
        },
        onSubmit() {
            this.$validator.validateAll().then(result => {
                if (!result) {
                    return;
                }

                this.loadingSubmit = true;

                let objUser = this.user;
                objUser.n_document = objUser.n_document.replace(/\D/g, '');

                this.$axios.put(`users/${this.$router.currentRoute.params.id}`, objUser).then(() => {
                    this.$swal({
                        title: 'Sucesso',
                        text: 'Usuário atualizado com sucesso.',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#28A745',
                        allowOutsideClick: false,
                        focusConfirm: false,
                        customClass: {
                            confirmButton: 'custom-confirm-button-class',
                        }
                    });
                    this.loadingSubmit = false;
                    this.user = {};
                    this.$router.push({ name: 'users' });
                }).catch(() => {
                    this.$swal({
                        title: 'Erro',
                        text: 'Não foi possível atualizar o usuário.',
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#C44C30',
                        allowOutsideClick: false,
                        customClass: {
                            confirmButton: 'custom-confirm-button-class',
                        }
                    });
                    this.loadingSubmit = false;
                });
            });
        },
    },
    watch: {
        'user.first_name': 'validateForm',
        'user.last_name': 'validateForm',
        'user.advisor_id': 'validateForm',
        'user.role': 'validateForm',
        'user.email': 'validateForm',
        // 'user.password': 'validateForm',
        // 'user.password_confirmation': 'validateForm',
    }
}
</script>
