<template>
    <div style="height: 100%;">
        <DefaultPageComponent
        :loading="loading"
        :title="'Bancos'"
        :haveBackButton="true"
        @onBack="$router.push({name: 'dashboard'})"
        :haveContentMargin="true"
        >

        <template #filters>
            <div class="row d-flex justify-content-start align-items-center mt-1">
                <div class="col-4">
                    <div class="form-group">
                        <label for="inputBankCode">Código do Banco</label>
                        <input type="text" class="form-control form-control-sm" id="inputBankCode" placeholder="Digite o código do banco" style="box-shadow: none; min-height:56px;" v-model="filter.code">
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <label for="inputBankStatus">Status</label>
                        <select class="form-control form-control-sm" id="inputBankStatus" style="box-shadow: none; min-height:56px;" v-model="filter.status">
                            <option value="">Todos</option>
                            <option value="1">Ativo</option>
                            <option value="0">Inativo</option>
                        </select>
                    </div>
                </div>
                <b-col></b-col>
                <b-col cols="3" class="d-flex justify-content-end">
                    <GlassButton
                    :loading="loading"
                    @click="search"
                    > 
                        <img src="@/assets/search.png" />
                        Pesquisar
                    </GlassButton>
                    <!-- <div class="form-group">
                        <button 
                            style="min-width: 100%; min-height: 56px; font-size: 16px; font-weight: 600; color: #EB694A; background: rgba(235, 105, 74, 0.12); border: none; border-radius: 10px;"
                            @click="search"
                        >
                            <span v-if="!loading">
                                <img src="@/assets/search.png" />
                                Pesquisar
                            </span>
                            <span v-else>
                                <b-spinner small />
                            </span>
                        </button>
                    </div> -->
                </b-col>
            </div>
        </template>

        <!-- CONTENT -->
        <b-table class="custom-table" borderless sticky-header :fields="columns" :items="banks" show-empty empty-text="Sem registros para mostrar no momento...">
            <template #cell(actions)="row">
                <div class="d-flex justify-content-end align-items-center">
                    <b-form-checkbox switch size="sm" v-model="row.item.status" @change="activateOrInactivateBank(row.item, row.item.status)"></b-form-checkbox>
                </div>
            </template>
        </b-table>

        <template #footer>
            <div></div>
        </template>

        </DefaultPageComponent>

    </div>
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import GlassButton from '@/components/GlassButton.vue';
export default {
    name: 'BankView',
    components:{
        DefaultPageComponent,
        GlassButton,
    },
    mounted() {
        this.getBanks();
    },
    data() {
        return {
            loading:false,
            columns: [
                {
                    'code': 
                    {
                        label: 'Código do Banco',
                    }
                },
                {
                    'name': 
                    {
                        label: 'Banco',
                        class: 'text-left'
                    }
                },
                {
                    'ACTIONS': 
                    {
                        label: '',
                    }
                },
            ],
            banks: [],
            filter: {
                code: '',
                status: '1',
            }
        }
    },
    methods: {
        getBanks() {
            this.loading=true;
            this.$axios.get('banks').then((response) => {
                this.banks = response.data.data;
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar bancos.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                });
                return;
            })
            .finally(()=>{
                this.loading=false;
            })
        },
        activateOrInactivateBank(bank, status) {
            this.$swal({
                title: !status ? 'Inativar banco?' : 'Ativar banco?',
                text: !status ? `Deseja realmente inativar o banco ${bank.name}?` : `Deseja realmente ativar o banco ${bank.name}?`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Cancelar',
                confirmButtonColor: '#C7300C',
                cancelButtonText: !status ? 'Inativar' : 'Ativar',
                cancelButtonColor: '#28A745',
                allowOutsideClick: false,
                customClass: {
                    confirmButton: 'custom-confirm-button-class',
                    cancelButton: 'custom-confirm-button-class',
                }
            }).then((result) => {
                if (!result.isConfirmed) {
                    let url = !status ? `banks/${bank.id}/inactive` : `banks/${bank.id}/active`;

                    this.$axios.put(url).then((response) => {
                        if(response.status != 200) {
                            this.$swal({
                                title: 'Erro',
                                text: status ? 'Erro ao ativar o banco.' : 'Erro ao inativar o banco.',
                                icon: 'error',
                                confirmButtonText: 'OK',
                                confirmButtonColor: '#C44C30',
                                allowOutsideClick: false,
                            });
                            return;
                        }
                        this.$swal({
                            title: 'Sucesso',
                            text: status ? 'Banco ativado com sucesso.' : 'Banco inativado com sucesso.',
                            icon: 'success',
                            confirmButtonText: 'OK',
                            confirmButtonColor: '#C44C30',
                            allowOutsideClick: false,
                        });
                        this.filter.code = '';
                        this.filter.status = 1;
                        this.search();
                    });
                } else {
                    this.search();
                }
            });
        },
        search() {
            this.$axios.post('banks/search', this.filter).then((response) => {
                this.banks = response.data.data;
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar bancos.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                });
                return;
            });
        },
    }
}
</script>

<style>
.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff !important;
    border-color: #28A745 !important;
    background-color: #28A745 !important;
    box-shadow: none !important;
}

.custom-control-input:not(checked) ~ .custom-control-label::before {
    border-color: #CCC !important;
    box-shadow: none !important;
}

tr:nth-child(odd) {
    background-color: #DDD !important;
}
</style>
