var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-button',{style:({
    'border-radius': '10px',
    'min-height': '56px',
    'line-height': '0px',
    'width': _vm.width || '160px',
    'font-size': _vm.fontSize || '18px',
    'font-weight': _vm.fontWeight ||'600',
    'border': '1px solid ' + (_vm.borderColor || 'transparent'),
    'background-color': _vm.bgColor || 'transparent',
    'color': _vm.textColor || '#474646',
    'opacity': (_vm.disabled ? '0.3' : '1'),
}),attrs:{"variant":_vm.variant || 'secondary',"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click')}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }