<template>
    <div class="stratification-card p-3">
        <b-row class="m-0" style="height:100%">
            <b-col style="height:100%;" class="p-0 d-flex flex-column" :class="custody ? 'justify-content-between' : 'justify-content-start'">
                <b-row class="mx-0"> <span style="color:#eb694a;"> {{ title }} </span> </b-row>
                <b-row v-if="custody" class="mx-2"> <span> Custódia total: {{ custody }} </span> </b-row>
                <b-row v-if="receive" class="mx-2" :class="custody ? '' : 'mt-1'"> <span> Receita total: {{ receive }} </span> </b-row>
            </b-col>
            <b-col class="p-0" cols="3">
                <b-col v-if="roa" class="p-3 d-flex flex-column justify-content-center" style="background-color:#eb694a; height:100%; border-radius:5px;">
                    <b-row class="m-0" align-h="center"> <span> ROA </span> </b-row>
                    <b-row class="m-0" align-h="center"> <span> {{ roa }} </span> </b-row>
                </b-col>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: 'StratificationCard',
    props:{
        title: String,
        custody: String,
        receive: String,
        roa: String,
    }
}
</script>

<style scoped>
.stratification-card{
    box-shadow: 0px 0px 5px #eb694a;
    border-radius: 10px;
    height: 100%;
    min-height: 110px;
}
</style>