<template>
    <div id="dashboard">
        <b-col class="px-0 mx-0" style="width:100%; height:100%;">
            <DefaultPageComponent
            :loading="loading"
            :title="'Detalhes'"
            :haveBackButton="true"
            @onBack="$emit('back')"
            :haveContentMargin="true"
            >

            <template #filters>
                <b-row class="mx-0">
                    <b-col class="px-1">
                        <div class="form-group">
                            <label for="">Assessor:</label>
                            <input class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; height: 56px;" :value="selected.ADVISOR" disabled/>
                        </div>
                    </b-col>
                    <b-col class="px-1" style="max-width:10%">
                        <div class="form-group">
                            <label for="">Cliente:</label>
                            <input class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; height: 56px;" :value="selected.CUSTOMER_OBJ?.id" disabled/>
                        </div>
                    </b-col>
                    <b-col class="px-1">
                        <div class="form-group">
                            <label for="">Saldo:</label>
                            <input class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; height: 56px;" :value="selected.AMOUNT_FORMAT" disabled/>
                        </div>
                    </b-col>
                    <b-col class="px-1">
                        <div class="form-group">
                            <label for="">FGC:</label>
                            <input class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; height: 56px;" :value="selected.FGC_FORMAT" disabled/>
                        </div>
                    </b-col>
                    <b-col class="px-1">
                        <div class="form-group">
                            <label for="">Data da posição:</label>
                            <input :value="formatDateBR(selected.POSITION_DATE)" type="text" class="form-control form-control-sm" id="inputFilterDate" style="box-shadow: none; min-height:56px; text-align:end; padding-right:20px;" disabled>
                        </div>
                    </b-col>
                    <!-- <b-col class="d-flex flex-column">
                        <label for="">Assessor:</label>
                        <select class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; max-width: 240px;" v-model="advisorId" @change="getFGC()">
                            <option value="">Selecione</option>
                            <option v-for="advisor in advisors" :key="advisor.id" :value="advisor.id">{{ advisor.first_name + " " + advisor.last_name }} </option>
                        </select>
                    </b-col>
                    <div class="d-flex flex-column mr-3">
                        <label for="">Data da posição:</label>
                        <input type="text" class="form-control form-control-sm" id="inputFilterDate" style="box-shadow: none; max-width: 240px;" disabled>
                    </div> -->
                </b-row>
            </template>

            <!-- CONTENT -->
            <b-table class="custom-table" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" borderless sticky-header :fields="columns" :items="items" show-empty>
                <template #empty="">
                    <b-col class="text-center">
                        Não há dados disponíveis
                    </b-col>
                </template>
                <template #head(ADVISOR)="data">
                    <b-col class="text-left p-0">
                        {{data.label}}
                    </b-col>
                </template>
                <template #head(CUSTOMER)="data">
                    <b-col class="text-left p-0">
                        {{data.label}}
                    </b-col>
                </template>
                <template #head(PRODUCT)="data">
                    <b-col class="text-left p-0">
                        {{data.label}}
                    </b-col>
                </template>
                <template #head(SUBPRODUCT)="data">
                    <b-col class="text-left p-0">
                        {{data.label}}
                    </b-col>
                </template>
                <template #head(DUE_DATE)="data">
                    <b-col class="text-right p-0">
                        {{ data.label }}
                        <b-icon icon="arrow-up-short" :style="'transition:0.25s;' + (sortBy==data.column&&!sortDesc ? 'rotate: 180deg;' : '')"></b-icon>
                    </b-col>
                </template>
                <template #head(ISSUER)="data">
                    <b-col class="text-left p-0">
                        {{data.label}}
                        <b-icon icon="arrow-up-short" :style="'transition:0.25s;' + (sortBy==data.column&&!sortDesc ? 'rotate: 180deg;' : '')"></b-icon>
                    </b-col>
                </template>
                <template #head(AMOUNT)="data">
                    <b-col class="text-right p-0">
                        {{data.label}}
                    </b-col>
                </template>

                <template #cell(DUE_DATE)="row">
                    <b-col class="text-right p-0">
                        {{ formatDateBR(row.item.DUE_DATE) }}
                    </b-col>
                </template>
                <template #cell(AMOUNT)="row">
                    <!-- <b-col class="text-right p-0"> -->
                        {{ 'R$ ' }} {{ Number(row.item.AMOUNT).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                    <!-- </b-col> -->
                </template>
                <template #cell(FGC)="row">
                    <b-col class="text-left p-0">
                        {{ Number(row.item.FGC).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                    </b-col>
                </template>
            </b-table>

            <template #footer>
                <div></div>
            </template>

            </DefaultPageComponent>

        </b-col>
    </div>
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
export default {
    name: 'FGCDetailsView',
    components:{
        DefaultPageComponent,
    },
    props:{
        selected: Object,
        dueDateStart: String,
        dueDateEnd: String,
    },
    data () {
        return {
            details: false,
            filterBy: 'advisor',
            sortBy: null,
            sortDesc: null,
            items: [],
            columns: [
                {
                    'PRODUCT': 
                    {
                        label: 'Produto',
                        class: 'text-left',
                        // thStyle: {
                        //     width: '20%'
                        // }
                    }
                },
                {
                    'SUBPRODUCT': 
                    {
                        label: 'Subproduto',
                        class: 'text-left',
                        // thStyle: {
                        //     width: '20%'
                        // }
                    }
                },
                {
                    'ACTIVE': 
                    {
                        label: 'Ativo',
                        class: 'text-left',
                        // thStyle: {
                        //     width: '10%'
                        // }
                    }
                },
                {
                    'DUE_DATE': 
                    {
                        label: 'Data Vencimento',
                        class: 'text-left',
                        sortable: true,
                        // thStyle: {
                        //     width: '10%'
                        // }
                    }
                },
                {
                    'ISSUER': 
                    {
                        label: 'Emissor',
                        class: 'text-left',
                        sortable: true,
                        // thStyle: {
                        //     width: '10%'
                        // }
                    }
                },
                {
                    'AMOUNT': 
                    {
                        label: 'Saldo',
                        class: 'text-right',
                        // thStyle: {
                        //     width: '10%'
                        // }
                    }
                },
            ],
            // positionDate: '',
            // customers: [],
            // issuers: [],
            // customer: '',
            // issuer: '',
            // dueDateStart: '',
            // dueDateEnd: '',
            cells: [],
            advisors: [],
            cellId: '',
            advisorId: '',
            loading: false,
            minDate: null,
            maxDate: null,
        }
    },
    mounted() {
        this.getDetails();
        this.removeClass();
        // this.getFGC();
        // this.getCells();
        // this.getAdvisors();
        // this.getPositionDate();
        // this.applyFilter();
        // const dataAtual = new Date();
        // const ano = dataAtual.getFullYear();
        // const mes = (dataAtual.getMonth() + 1).toString().padStart(2, '0');
        // document.getElementById('inputFilterDate').value = `${ano}-${mes}`;
    },
    methods: {
        removeClass() {
            setInterval(() => {
                const allElements = document.querySelectorAll('*');
                allElements.forEach(element => {
                    if (element.classList.contains('position-relative'))
                        element.classList.remove('position-relative');
                });
            }, 250);
        },
        async getDetails(){
            var queries = '';
            var listQuery = [];

            if(this.selected.ADVISOR_OBJ?.id || this.selected.CUSTOMER_OBJ?.id || this.selected.ISSUER || (this.dueDateStart && this.dueDateEnd))
                queries += '?';
            
            if(this.selected.ADVISOR_OBJ?.id)
                listQuery.push('advisor='+this.selected.ADVISOR_OBJ?.id);

            if(this.selected.CUSTOMER_OBJ?.id)
                listQuery.push('customer='+this.selected.CUSTOMER_OBJ?.id);
        
            if(this.selected.ISSUER)
                listQuery.push('issuer='+this.selected.ISSUER);

            if(this.dueDateStart && this.dueDateEnd){
                listQuery.push('due_date_start='+this.dueDateStart);
                listQuery.push('due_date_end='+this.dueDateEnd);
            }

            queries += listQuery.join('&');
            
            const response = await this.$axios.get('fgc/details' + queries);
            this.items = response.data.diversifications;
        },


        // async getPositionDate(){
        //     this.$axios.get('fgc/position-date')
        //     .then((response)=>{this.positionDate = response.data.positionDate})
        // },
        // async getSelectOptions(){
        //     if(!this.advisorId) return;
        //     this.$axios.get('fgc/position-date?advisor='+this.advisorId)
        //     .then((response)=>{this.positionDate = response.data.positionDate})
        //     this.$axios.get('fgc/customers?advisor='+this.advisorId)
        //     .then((response)=>{this.customers = response.data.customers})
        //     this.$axios.get('fgc/issuers?advisor='+this.advisorId)
        //     .then((response)=>{this.issuers = response.data.issuers})               
        // },
        // async getCustomers(issuer){
        //     if(!issuer) return;
        //     this.$axios.get('fgc/customers?advisor='+this.advisorId+'&issuer='+issuer)
        //     .then((response)=>{this.customers = response.data.customers})
        // },
        // async getIssuers(customer){
        //     if(!customer) return;
        //     this.$axios.get('fgc/issuers?advisor='+this.advisorId+'&customer='+customer)
        //     .then((response)=>{this.issuers = response.data.issuers})
        // },


        // async applyFilter(){
        //     if(!this.advisorId) {
        //         this.$swal({
        //             title: 'Assessor não selecionado',
        //             text: 'Para utilizar os demais filtros é necessário a seleção de um assessor',
        //             icon: 'warning',
        //             confirmButtonText: 'OK',
        //             confirmButtonColor: '#28A745',
        //             allowOutsideClick: false,
        //             customClass: {
        //                 confirmButton: 'custom-confirm-button-class',
        //             }
        //         });
        //         return;
        //     }

        //     if((!this.dueDateStart && this.dueDateEnd) || (this.dueDateStart && !this.dueDateEnd)) {
        //         this.$swal({
        //             title: 'Datas não selecionadas',
        //             text: 'Para realizar a pesquisa, é necessário que ambas as datas ("Data de vencimento de" e "Data de vencimento até") sejam informadas ou que nenhuma delas seja informada.',
        //             icon: 'warning',
        //             confirmButtonText: 'OK',
        //             confirmButtonColor: '#28A745',
        //             allowOutsideClick: false,
        //             customClass: {
        //                 confirmButton: 'custom-confirm-button-class',
        //             }
        //         });
        //         return;
        //     }

        //     try{
        //         this.loading=true;

        //         var queries = '';
        //         var listQuery = [];

        //         if(this.advisorId || this.customer || this.issuer || (this.dueDateStart && this.dueDateEnd)) 
        //             queries += '?';
                
        //         if(this.advisorId)
        //             listQuery.push('advisor='+this.advisorId);

        //         if(this.customer)
        //             listQuery.push('customer='+this.customer);
            
        //         if(this.issuer)
        //             listQuery.push('issuer='+this.issuer);

        //         if(this.dueDateStart && this.dueDateEnd){
        //             listQuery.push('due_date_start='+this.dueDateStart);
        //             listQuery.push('due_date_end='+this.dueDateEnd);
        //         }

        //         queries += listQuery.join('&');
                
        //         const response = await this.$axios.get('fgc' + queries);
        //         this.diversifications = response.data.diversifications;
        //     }
        //     catch(error){
        //         console.log(error);
        //     }
        //     finally{ this.loading=false; }
        // },
        
        getMinDate(date){
            this.minDate = new Date(date).toISOString().split('T')[0]
        },
        getMaxDate(date){
            this.maxDate = new Date(date).toISOString().split('T')[0]
        },

        // getFGC () {
        //     let url = "fgc";
        //     if (this.cellId != '' && this.filterBy == 'cell') {
        //         url += `?cell_id=${this.cellId}`;
        //     }

        //     if (this.advisorId != '' && this.filterBy == 'advisor') {
        //         url += `?advisor_id=${this.advisorId}`;
        //     }

        //     if(url != "fgc") {
        //         this.$axios.get(url).then((response) => {
        //             this.diversifications = response.data.diversifications;
        //             const date = this.diversifications[0].POSITION_DATE;
        //             const [year, month, day] = date.split("-");
        //             this.positionDate = `${day}/${month}/${year}`;
        //         });
        //     }
        // },
        getCells () {
            this.$axios.get('cells').then((response) => {
                this.cells = response.data.data;
            });
        },
        getAdvisors () {
            this.$axios.get('advisors').then((response) => {
                this.advisors = response.data.data;
            });
        },
        formatDateBR(dateString) {
            if (!dateString) {
                return '';
            }

            const [year, month, day] = dateString.split('-');
            const formattedDate = `${day}/${month}/${year}`;

            return formattedDate;
        },
    }
}
</script>

<style>
    #dashboard {
        height: calc(100vh - 125px);
    }

    .form-control {
        background-color: #262625 !important;
        border: 1px solid #535252 !important;
        color: #E5E2E1 !important;
        border-radius: 10px !important;
    }

    table thead tr th {
        background: #535252 !important;
        color: #F3F0EF !important;
        padding: 2px 20px !important;
        font-size: 22px;
        font-weight: 600;
        text-align: right;
    }

    table thead tr th:first-child {
        /* border-radius: 10px 0px 0px 0px !important; */
        text-align: left;
    }

    /* table thead tr th:last-child {
        border-radius: 0px 10px 0px 0px !important;
    } */

    table tbody tr td {
        background-color: #242323 !important;
        color: #F3F0EF !important;
        padding: 0px 20px !important;
        padding-top: 15px !important;
        font-weight: 600 !important;
    }

    #tableDetalhes tbody tr td {
        background-color: #242323f0 !important;
    }

    table tbody tr {
        border-bottom: 1px solid #535252 !important;
    }

    table tbody tr:last-child {
        border-bottom: 0px solid #535252 !important;
    }

    table tbody tr th {
        background-color: #363636f0 !important;
        color: #F3F0EF !important;
        /* padding: 0px 20px !important; */
        padding-top: 15px !important;
        font-weight: 600 !important;
    }
</style>
