var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-overlay',{style:({
    'border-radius': '10px',
    'width': _vm.width || '200px',
  }),attrs:{"show":_vm.loading,"opacity":"0.75","bg-color":"black","spinner-small":"","spinner-variant":"light"}},[_c('b-button',{staticClass:"d-flex align-items-center justify-content-center",style:(_vm.disabled 
    ? {
        'border-radius': '10px',
        'min-height': '56px',
        'line-height': '0px',
        'width': _vm.width || '200px',
        'font-size': _vm.fontSize || '18px',
        'font-weight': _vm.fontWeight ||'600',
        'border': '1px solid ' + (_vm.borderColor || '#EB694A'),
        'background-color': _vm.bgColor || 'transparent',
        'color': _vm.textColor || '#EB694A',
    }
    : {
        'border-radius': '10px',
        'min-height': '56px',
        'line-height': '0px',
        'width': _vm.width || '200px',
        'font-size': _vm.fontSize || '18px',
        'font-weight': _vm.fontWeight ||'600',
        'border': '1px solid ' + (_vm.borderColor || '#EB694A'),
        'background-color': _vm.bgColor || '#EB694A',
        'color': _vm.textColor || 'white',
    }),attrs:{"variant":_vm.variant || 'secondary',"type":_vm.type || 'button'},on:{"click":_vm.click}},[_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }