<template>
    <div id="dashboard">
        <DefaultPageComponent
        :loading="loading"
        :title="'Importação de tabelas para estratificação'"
        :subtitle="'Para realizar a estratificação, é necessário importar todas as tabelas solicitadas conforme o modelo de importação estabelecido, caso esteja disponível.'"
        :haveBackButton="true"
        @onBack="$router.push({name: 'dashboard'})"
        >

        <template #header>
            <b-col class="px-0">
                <input type="month" class="form-control form-control-sm text-right" id="inputFilterDate" style="box-shadow: none; max-width: 240px; min-height: 56px;" disabled>
            </b-col>
        </template>

        <!-- CONTENT -->
        <div class="py-3">
            <b-row style="min-height: 98px;" class="px-4 mx-0 mt-4">
                <b-col cols="3" class="d-flex align-items-center">
                    <span style="font-size: 22px; font-wight: 600;">Tabela de Diversificação</span>
                </b-col>
                <b-col style="border: 1px dashed #535252; display: flex; align-items: center; justify-content: space-between">
                    <span v-if="importations.diversification.quantity == 0">
                        <GlassButton class="ml-4"
                        :loading="importations.diversification.loading"
                        @click="openFileImportDiversification"
                        >
                            Escolher arquivos...
                        </GlassButton>
                        <!-- <button 
                            style="min-width: 189px; min-height: 48px; font-size: 16px; font-weight: 600; color: #EB694A; background: rgba(235, 105, 74, 0.12); border: none; border-radius: 10px;"
                            @click="openFileImportDiversification"
                        >
                            <span v-if="!importations.diversification.loading">
                                Escolher arquivos...
                            </span>
                            <span v-else>
                                <b-spinner small class="mr-2" />
                                Upload em andamento...
                            </span>
                        </button> -->
                        <!-- <a href="#" class="ml-4">
                            Arraste o arquivo aqui
                        </a> -->
                        <input 
                            type="file"
                            ref="fileInputDiversification"
                            style="display: none;"
                            @change="handleFileImportDiversification"
                        />
                    </span>
                    <span v-else>
                        <img src="@/assets/check_circle_green_outline.png" alt="" class="mr-2">
                        {{ importations.diversification.name }}
                    </span>
                    <span>
                        <img src="@/assets/calc.png" alt="" class="mr-2" v-if="importations.diversification.quantity === 0">
                        <img src="@/assets/check_circle_green_outline.png" alt="" class="mr-2" v-else>
                        <label :style="importations.diversification.quantity === 0 ? '' : 'color: #4CAF50;'">{{ importations.diversification.quantity }} / 1</label>
                    </span>
                </b-col>
            </b-row>
            <b-row style="min-height: 98px;" class="px-4 mx-0">
                <b-col cols="3" class="d-flex align-items-center">
                    <span style="font-size: 22px; font-wight: 600;">Tabela de XPUS</span>
                </b-col>
                <b-col style="border: 1px dashed #535252; display: flex; align-items: center; justify-content: space-between">
                    <span v-if="importations.diversificationXPUS.quantity == 0">
                        <GlassButton class="ml-4"
                        :loading="importations.diversificationXPUS.loading"
                        @click="openFileImportDiversificationXPUS"
                        :disabled="importations.diversificationXPUS.quantity > 0"
                        >
                            Escolher arquivos...
                        </GlassButton>
                        <!-- <button 
                            style="min-width: 189px; min-height: 48px; font-size: 16px; font-weight: 600; color: #EB694A; background: rgba(235, 105, 74, 0.12); border: none; border-radius: 10px;"
                            @click="openFileImportDiversificationXPUS"
                        >
                            <span v-if="!importations.diversificationXPUS.loading">
                                Escolher arquivos...
                            </span>
                            <span v-else>
                                <b-spinner small class="mr-2" />
                                Upload em andamento...
                            </span>
                        </button> -->
                        <!-- <a href="#" class="ml-4">
                            Arraste o arquivo aqui
                        </a> -->
                        <input 
                            type="file"
                            ref="fileInputDiversificationXPUS"
                            style="display: none;"
                            @change="handleFileImportDiversificationXPUS"
                        />
                    </span>
                    <span v-else>
                        <img src="@/assets/check_circle_green_outline.png" alt="" class="mr-2">
                        {{ importations.diversificationXPUS.name }}
                    </span>
                    <span>
                        <img src="@/assets/calc.png" alt="" class="mr-2" v-if="importations.diversificationXPUS.quantity === 0">
                        <img src="@/assets/check_circle_green_outline.png" alt="" class="mr-2" v-else>
                        <label :style="importations.diversificationXPUS.quantity === 0 ? '' : 'color: #4CAF50;'">{{ importations.diversificationXPUS.quantity }} / 1</label>
                    </span>
                </b-col>
            </b-row>
            <b-row style="min-height: 98px;" class="px-4 mt-4 mx-0">
                <b-col cols="3" class="d-flex flex-column">
                    <span style="font-size: 22px; font-wight: 600;">Tabela guia de fundos</span>
                    <span>
                        <button 
                            class="mt-2 d-flex justify-content-center align-items-center" style="min-width: 137px; min-height: 40px; font-size: 16px; font-weight: 600; color: #EB694A; background: #1C1B1B; border: 1px solid #474646; border-radius: 10px;"
                            @click="getModelSheetFund"
                        >
                            <img src="@/assets/download.png" class="mr-2" />
                            Modelo
                        </button>
                    </span>
                </b-col>
                <b-col style="border: 1px dashed #535252; display: flex; align-items: center; justify-content: space-between">
                    <span class="d-flex align-items-center" v-if="importations.fund.quantity == 0">
                        <GlassButton class="ml-4"
                        :loading="importations.fund.loading"
                        :disabled="importations.fund.quantity > 0"
                        @click="openFileImportFund"
                        >
                            Escolher arquivos...
                        </GlassButton>
                        <!-- <button 
                            style="min-width: 189px; min-height: 48px; font-size: 16px; font-weight: 600; color: #EB694A; background: rgba(235, 105, 74, 0.12); border: none; border-radius: 10px;"
                            @click="openFileImportFund"
                            :disabled="importations.fund.quantity > 0"
                        >
                            <span v-if="!importations.fund.loading">
                                Escolher arquivos...
                            </span>
                            <span v-else>
                                <b-spinner small class="mr-2" />
                                Upload em andamento...
                            </span>
                        </button> -->
                        <!-- <a href="#" class="ml-4">
                            Arraste o arquivo aqui
                        </a> -->
                        <input 
                            type="file"
                            ref="fileInputFund"
                            style="display: none;"
                            @change="handleFileImportFund"
                        />
                    </span>
                    <span v-else>
                        <img src="@/assets/check_circle_green_outline.png" alt="" class="mr-2">
                        {{ importations.fund.name }}
                    </span>
                    <span>
                        <img src="@/assets/calc.png" alt="" class="mr-2" v-if="importations.fund.quantity === 0">
                        <img src="@/assets/check_circle_green_outline.png" alt="" class="mr-2" v-else>
                        <label :style="importations.fund.quantity === 0 ? '' : 'color: #4CAF50;'">{{ importations.fund.quantity }} / 1</label>
                    </span>
                </b-col>
            </b-row>
            <b-row style="min-height: 98px;" class="px-4 mt-4 mx-0">
                <b-col cols="3" class="d-flex flex-column">
                    <span style="font-size: 22px; font-wight: 600;">Tabela guia de fundos previdência</span>
                    <span>
                        <button 
                            class="mt-2 d-flex justify-content-center align-items-center" 
                            style="min-width: 137px; min-height: 40px; font-size: 16px; font-weight: 600; color: #EB694A; background: #1C1B1B; border: 1px solid #474646; border-radius: 10px;"
                            @click="getModelSheetPensionGuide"
                        >    
                            <img src="@/assets/download.png" class="mr-2" />
                            Modelo
                        </button>
                    </span>
                </b-col>
                <b-col style="border: 1px dashed #535252; display: flex; align-items: center; justify-content: space-between">
                    <span class="d-flex align-items-center" v-if="importations.pensionGuide.quantity == 0">
                        <GlassButton class="ml-4"
                        :loading="importations.pensionGuide.loading"
                        @click="openFileImportPensionGuide"
                        :disabled="importations.pensionGuide.quantity > 0"
                        >
                            Escolher arquivos...
                        </GlassButton>
                        <!-- <button 
                            style="min-width: 189px; min-height: 48px; font-size: 16px; font-weight: 600; color: #EB694A; background: rgba(235, 105, 74, 0.12); border: none; border-radius: 10px;"
                            @click="openFileImportPensionGuide"
                            :disabled="importations.pensionGuide.quantity > 0"
                        >
                            <span v-if="!importations.pensionGuide.loading">
                                Escolher arquivos...
                            </span>
                            <span v-else>
                                <b-spinner small class="mr-2" />
                                Upload em andamento...
                            </span>
                        </button> -->
                        <!-- <a href="#" class="ml-4">
                            Arraste o arquivo aqui
                        </a> -->
                        <input 
                            type="file"
                            ref="fileInputPensionGuide"
                            style="display: none;"
                            @change="handleFileImportPensionGuide"
                        />
                    </span>
                    <span v-else>
                        <img src="@/assets/check_circle_green_outline.png" alt="" class="mr-2">
                        {{ importations.pensionGuide.name }}
                    </span>
                    <span>
                        <img src="@/assets/calc.png" alt="" class="mr-2" v-if="importations.pensionGuide.quantity === 0">
                        <img src="@/assets/check_circle_green_outline.png" alt="" class="mr-2" v-else>
                        <label :style="importations.pensionGuide.quantity === 0 ? '' : 'color: #4CAF50;'">{{ importations.pensionGuide.quantity }} / 1</label>
                    </span>
                </b-col>
            </b-row>
            <b-row style="min-height: 98px;" class="px-4 mt-4 mx-0">
                <b-col cols="3" class="d-flex align-items-center">
                    <span style="font-size: 22px; font-wight: 600;">Tabelas de comissão</span>
                </b-col>

                <b-col style="border: 1px dashed #535252; display: flex; align-items: center; justify-content: space-between">
                    <span class="d-flex align-items-center">
                        <GlassButton class="ml-4"
                        :loading="importations.commission.loading"
                        :disabled="importations.commission.quantity >= 9"
                        @click="openFileImportCommission"
                        >
                            Escolher arquivos...
                        </GlassButton>
                        <!-- <button 
                            style="min-width: 189px; min-height: 48px; font-size: 16px; font-weight: 600; color: #EB694A; background: rgba(235, 105, 74, 0.12); border: none; border-radius: 10px;"
                            @click="openFileImportCommission"
                            :disabled="importations.commission.quantity === 9"
                        >
                            <span v-if="!importations.commission.loading">
                                Escolher arquivos...
                            </span>
                            <span v-else>
                                <b-spinner small class="mr-2" />
                                Upload em andamento...
                            </span>
                        </button> -->
                        <!-- <a href="#" class="ml-4">
                            Arraste o arquivo aqui
                        </a> -->
                        <span v-if="importations.commission.quantity > 0" class="ml-2">
                            {{ importations.commission.quantity }} arquivo(s) importado(s)
                        </span>
                        <input 
                            type="file"
                            ref="fileInputCommission"
                            style="display: none;"
                            @change="handleFileImportCommission"
                        />
                    </span>
                    <span>
                        <img src="@/assets/calc.png" alt="" class="mr-2" v-if="importations.commission.quantity < 9">
                        <img src="@/assets/check_circle_green_outline.png" alt="" class="mr-2" v-else>
                        <label :style="importations.commission.quantity < 9 ? '' : 'color: #4CAF50;'">{{ importations.commission.quantity }} / 9</label>
                    </span>
                </b-col>
            </b-row>
            <b-row style="min-height: 98px;" class="px-4 mt-4 mx-0">
                <b-col cols="3" class="d-flex align-items-center">
                    <span style="font-size: 22px; font-wight: 600;">Tabela de comissão PJ2</span>
                </b-col>

                <b-col style="border: 1px dashed #535252; display: flex; align-items: center; justify-content: space-between">
                    <span class="d-flex align-items-center">
                        <GlassButton class="ml-4"
                        :loading="importations.commissionOnly.loading"
                        @click="openFileImportCommissionOnly"
                        :disabled="importations.commissionOnly.quantity > 0"
                        >
                            Escolher arquivos...
                        </GlassButton>
                        <!-- <button 
                            style="min-width: 189px; min-height: 48px; font-size: 16px; font-weight: 600; color: #EB694A; background: rgba(235, 105, 74, 0.12); border: none; border-radius: 10px;"
                            @click="openFileImportCommissionOnly"
                            :disabled="importations.commissionOnly.quantity === 1"
                            v-if="importations.commissionOnly.quantity == 0"
                        >
                            <span v-if="!importations.commissionOnly.loading">
                                Escolher arquivos...
                            </span>
                            <span v-else>
                                <b-spinner small class="mr-2" />
                                Upload em andamento...
                            </span>
                        </button> -->
                        <!-- <a href="#" class="ml-4">
                            Arraste o arquivo aqui
                        </a> -->
                        <span v-if="importations.commissionOnly.quantity > 0" class="ml-2">
                            <img src="@/assets/check_circle_green_outline.png" alt="" class="mr-2">
                            {{ importations.commissionOnly.name }}
                        </span>
                        <input 
                            type="file"
                            ref="fileInputCommissionOnly"
                            style="display: none;"
                            @change="handleFileImportCommissionOnly"
                        />
                    </span>
                    <span>
                        <img src="@/assets/calc.png" alt="" class="mr-2" v-if="importations.commissionOnly.quantity < 1">
                        <img src="@/assets/check_circle_green_outline.png" alt="" class="mr-2" v-else>
                        <label :style="importations.commissionOnly.quantity < 1 ? '' : 'color: #4CAF50;'">{{ importations.commissionOnly.quantity }} / 1</label>
                    </span>
                </b-col>
            </b-row>
        </div>

        <template #footer>
            <b-row class="mx-0" align-h="end">
                    <CustomButton
                    :loading="importations.diversification.loading || loading || loadingSubmit"
                    > 
                        Gerar estratificação
                    </CustomButton>
            </b-row>
        </template>

        </DefaultPageComponent>

    </div>  
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import GlassButton from '@/components/GlassButton.vue';
import CustomButton from '@/components/CustomButton.vue';
export default {
    name: 'DashboardView',
    components:{
        CustomButton,
        GlassButton,
        DefaultPageComponent,
    },
    data () {
        return {
            loading: false,
            loadingSubmit: false,
            importations: {
                diversification: {
                    loading: false,
                    quantity: 0,
                    name: '',
                },
                diversificationXPUS: {
                    loading: false,
                    quantity: 0,
                    name: '',
                },
                fund: {
                    loading: false,
                    quantity: 0,
                    name: '',
                },
                pensionGuide: {
                    loading: false,
                    quantity: 0,
                    name: '',
                },
                commission: {
                    loading: false,
                    quantity: 0,
                    names: [],
                },
                commissionOnly: {
                    loading: false,
                    quantity: 0,
                    name: '',
                },
            },
        }
    },
    mounted() {
        const dataAtual = new Date();
        const ano = dataAtual.getFullYear();
        const mes = (dataAtual.getMonth() + 1).toString().padStart(2, '0');
        document.getElementById('inputFilterDate').value = `${ano}-${mes}`;
    },
    methods: {
        openFileImportDiversification () {
            if (this.importations.diversification.loading || this.importations.diversification.quantity == 1)
                return;

            this.$refs.fileInputDiversification.click();
        },
        handleFileImportDiversification (event) {
            this.importations.diversification.loading = true;
            const file = event.target.files[0];

            if (file) {
                const formData = new FormData();
                formData.append('sheet', file);

                this.$axios.post('diversifications/import', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(response => {
                        if (response.status == 200) {
                            this.importations.diversification.name = file.name;
                            this.importations.diversification.quantity++;
                        } else { 
                            console.log(response);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
                    .finally(() => this.importations.diversification.loading = false)
            } else {
                this.importations.diversification.loading = false;
            }
        },
        openFileImportDiversificationXPUS () {
            if (this.importations.diversificationXPUS.loading || this.importations.diversificationXPUS.quantity == 1)
                return;

            this.$refs.fileInputDiversificationXPUS.click();
        },
        handleFileImportDiversificationXPUS (event) {
            this.importations.diversificationXPUS.loading = true;
            const file = event.target.files[0];

            if (file) {
                const formData = new FormData();
                formData.append('sheet', file);

                this.$axios.post('diversifications/import', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(response => {
                        if (response.status == 200) {
                            this.importations.diversificationXPUS.name = file.name;
                            this.importations.diversificationXPUS.quantity++;
                        } else { 
                            console.log(response);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
                    .finally(() => this.importations.diversificationXPUS.loading = false)
            } else {
                this.importations.diversificationXPUS.loading = false;
            }
        },
        openFileImportFund () {
            if (this.importations.fund.loading || this.importations.fund.quantity == 1)
                return;

            this.$refs.fileInputFund.click();
        },
        handleFileImportFund (event) {
            this.importations.fund.loading = true;
            const file = event.target.files[0];

            if (file) {
                const formData = new FormData();
                formData.append('sheet', file);

                this.$axios.post('funds/import', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(response => {
                        if (response.status == 200) {
                            this.importations.fund.name = file.name;
                            this.importations.fund.quantity++;
                        } else { 
                            console.log(response);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
                    .finally(() => this.importations.fund.loading = false)
            } else {
                this.importations.fund.loading = false;
            }
        },
        openFileImportPensionGuide () {
            if (this.importations.pensionGuide.loading || this.importations.pensionGuide.quantity == 1)
                return;

            this.$refs.fileInputPensionGuide.click();
        },
        handleFileImportPensionGuide (event) {
            this.importations.pensionGuide.loading = true;
            const file = event.target.files[0];

            if (file) {
                const formData = new FormData();
                formData.append('sheet', file);

                this.$axios.post('pension-guide/import', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(response => {
                        if (response.status == 200) {
                            this.importations.pensionGuide.name = file.name;
                            this.importations.pensionGuide.quantity++;
                        } else { 
                            console.log(response);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
                    .finally(() => this.importations.pensionGuide.loading = false)
            } else {
                this.importations.pensionGuide.loading = false;
            }
        },
        openFileImportCommission () {
            if (this.importations.commission.loading || this.importations.commission.quantity == 10)
                return;

            this.$refs.fileInputCommission.click();
        },
        handleFileImportCommission (event) {
            this.importations.commission.loading = true;
            const file = event.target.files[0];

            if (file) {
                const formData = new FormData();
                formData.append('sheet', file);

                this.$axios.post('commissions/import', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(response => {
                        if (response.status == 200) {
                            this.importations.commission.names.push(file.name);
                            this.importations.commission.quantity++;
                        } else { 
                            console.log(response);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
                    .finally(() => this.importations.commission.loading = false)
            } else {
                this.importations.commission.loading = false;
            }
        },
        openFileImportCommissionOnly () {
            if (this.importations.commissionOnly.loading || this.importations.commissionOnly.quantity == 10)
                return;

            this.$refs.fileInputCommissionOnly.click();
        },
        handleFileImportCommissionOnly (event) {
            this.importations.commissionOnly.loading = true;
            const file = event.target.files[0];

            if (file) {
                const formData = new FormData();
                formData.append('sheet', file);

                this.$axios.post('commission-only/import', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(response => {
                        if (response.status == 200) {
                            this.importations.commissionOnly.name = file.name;
                            this.importations.commissionOnly.quantity++;
                        } else { 
                            console.log(response);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
                    .finally(() => this.importations.commissionOnly.loading = false)
            } else {
                this.importations.commissionOnly.loading = false;
            }
        },
        getModelSheetFund () {
            this.$axios({
                url: '/funds/download',
                method: 'GET',
                responseType: 'blob',
            })
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Modelo - Guia de Fundos.xlsx');
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(error => {
                    console.error('Error downloading file:', error);
                });
        },
        getModelSheetPensionGuide () {
            this.$axios({
                url: '/pension-guide/download',
                method: 'GET',
                responseType: 'blob',
            })
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Modelo - Guia de Previdência.xlsx');
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(error => {
                    console.error('Error downloading file:', error);
                });
        }
    }
}
</script>

<style>
    #dashboard {
        height: calc(100vh - 125px);
    }

    .form-control {
        background-color: #262625 !important;
        border: 1px solid #535252 !important;
        color: #E5E2E1 !important;
        border-radius: 10px !important;
    }
</style>
