<template>
    <div id="goals" style="height:100%; width:100%;">
        <b-col class="px-0 mx-0" style="height:100%; width:100%;">
            <DefaultPageComponent
            :loading="loading"
            :title="'Metas ' + year"
            :haveBackButton="true"
            @onBack="$router.push({name: 'dashboard'})"
            :haveNextButton="true"
            :buttonText="'Nova meta'"
            @onTap="openNew"
            :haveContentMargin="true"
            >

            <template #filters>
                <b-row class="mx-0">
                    <b-col class="px-1">
                        <div class="form-group">
                            <label for="">Ano:</label>
                            <select class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; height: 56px;" v-model="selectedYear">
                                <option v-for="item in years" :key="item" :value="item"> {{ item }} </option>
                            </select>
                        </div>
                    </b-col>
                    <b-col class="px-1">
                        <div class="form-group">
                            <label for="">Célula:</label>
                            <select class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; height: 56px;" v-model="cell" @change="advisor=null;">
                                <option :value="null">Todos</option>
                                <option v-for="cell in cells" :key="cell.id" :value="cell.id">{{ cell.cell }} </option>
                            </select>
                        </div>
                    </b-col>
                    <b-col class="px-1">
                        <div class="form-group">
                            <label for="">Assessor:</label>
                            <select class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; height: 56px;" v-model="advisor">
                                <option :value="null">Todos</option>
                                <option v-for="advisor in advisorOptions" :key="advisor.advisorCommercialPartner[0].code" :value="advisor.advisorCommercialPartner[0].code">{{ advisor.first_name + " " + advisor.last_name }} </option>
                            </select>
                        </div>
                    </b-col>
                    <b-col></b-col>
                    <b-col>
                        <b-row align-h="end">
                            <CustomButton
                            :disabled="!selectedYear"
                            :loading="loading"
                            @click="getGoals"
                            :width="'260px'"
                            > Filtrar
                            </CustomButton>
                        </b-row>
                    </b-col>
                </b-row>
            </template>

            <!-- CONTENT -->
            <b-table class="custom-table" borderless sticky-header :fields="columns" :items="items" show-empty>
                <template #empty="">
                    <b-col class="text-center">
                        Não há dados disponíveis
                    </b-col>
                </template>
                <template #head(GOAL_YEAR)="data">
                    <b-col class="text-right p-0">
                        {{data.label}}
                    </b-col>
                </template>
                <template #head(ADVISOR)="data">
                    <b-col class="text-left p-0">
                        {{data.label}}
                    </b-col>
                </template>
                <template #head(CELL)="data">
                    <b-col class="text-left p-0">
                        {{data.label}}
                    </b-col>
                </template>
                <template #head(CUSTODY)="data">
                    <b-col class="text-right p-0">
                        {{data.label}}
                    </b-col>
                </template>
                <template #head(ACTIVATION)="data">
                    <b-col class="text-right p-0">
                        {{ data.label }}
                    </b-col>
                </template>
                <template #head(ROA)="data">
                    <b-col class="text-left p-0">
                        {{data.label}}
                    </b-col>
                </template>

                <template #cell(ADVISOR)="row">
                    <b-col class="text-left p-0">
                        {{ row.item.first_name+' '+row.item.last_name }}
                    </b-col>
                </template>

                <!-- <template #head(actions)="data">
                    <b-col class="text-center p-0">
                        {{data.label}}
                    </b-col>
                </template> -->

                <!-- <template #cell(CUSTODY)="row">
                    <b-col class="text-right p-0">
                        {{ 'R$' }} {{ Number(row.item.FGC).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                    </b-col>
                </template> -->
                <!-- <template #cell(actions)="row">
                    <b-row class="m-0" align-h="center">
                        <b-button 
                            style="color: #EB694A; width: 39px; height: 40px; background-color: #242323; display: flex; justify-content: center; align-items: center; font-size: 0.875rem; border-color: #535252; box-shadow: none;"
                            @click="showDetails(row.item)"
                        >
                            <b-icon icon="eye-fill"></b-icon>
                        </b-button>
                    </b-row>
                </template> -->
            </b-table>

            <template #footer>
                <div></div>
            </template>

            </DefaultPageComponent>

        </b-col>
        <card-modal title="Nova meta" ref="modalNewGoal" style="z-index:2;">
            <div class="row d-flex justify-content-center align-items-center">
                <b-col class="mx-4">

                    <!-- TITLE -->
                    <b-row class="pb-2" style="border-bottom:1px solid #535252">
                        Importação das Metas
                    </b-row>

                    <!-- INPUT -->
                    <b-row align-v="center" class="py-5">
                        <b-col class="mr-5 p-0" style="max-width:fit-content">Arquivo de Metas</b-col>
                        <b-col style="border: 1px solid #535252; flex-direction:column; border-radius:8px;" class="py-3 d-flex align-items-center justify-content-center">
                            <CustomButton
                            v-if="!file"
                            @click="openFile"
                            > Escolher arquivo
                            </CustomButton>
                            <!-- <b-button v-if="!file" @click="openFile"
                            style="width:80%; min-height:56px; background-color: #EB694A; border-radius:8px; border:none !important; box-shadow: none !important; font-weight:600;">
                                Escolher arquivo
                            </b-button> -->
                            <b-row v-else align-v="center" align-h="start" class="mx-0">
                                <b-icon icon="check-circle" class="mr-2" style="color:#00FF29"></b-icon>
                                {{ file.name }}
                            </b-row>
                        </b-col>
                    </b-row>

                    <input 
                    type="file"
                    ref="fileInput"
                    style="display: none;"
                    @change="handleFile"
                    />

                    <!-- BUTTONS -->
                    <b-row align-h="end" align-v="center">
                        <div class="form-group" style="max-width:160px;">
                            <div class="text-right">
                                <CancelButton
                                :disabled="loadingSubmit"
                                @click="$refs.modalNewGoal.closeModal()"
                                > Cancelar
                                </CancelButton>
                                <!-- <button class="btn" @click="$refs.modalNewGoal.closeModal()" :disabled="loadingSubmit" style="background-color: transparent; color: #474646; width: 120px;" type="button">
                                    <span style="font-size:18px; font-weight:600;">
                                        Cancelar
                                    </span>
                                </button> -->
                            </div>
                        </div>
                        <div style="width:20px;"></div>
                        <CustomButton class="mx-0"
                        :loading="loading || loadingSubmit"
                        :disabled="isDisabled"
                        @click="saveNew" 
                        > Salvar
                        </CustomButton>
                        <!-- <b-button @click="()=>{}" :disabled="isDisabled" :style="isDisabled ? 'opacity:0.25;' : ''"
                            style="min-width: 100%; min-height: 56px; font-size: 16px; font-weight: 600; color: white; background-color: #EB694A; border: none; border-radius: 10px;"
                        >
                            <span v-if="loadingSubmit" style="font-size:18px;">
                                <b-spinner small class="mr-2" />
                            </span>
                            <span v-else style="font-size:18px;">
                                Salvar
                            </span>
                        </b-button> -->
                    </b-row>
                </b-col>
            </div>
        </card-modal>
    </div>
</template>

<script>
import CardModal from './CardModal.vue';
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import CustomButton from '@/components/CustomButton.vue';
import CancelButton from '@/components/CancelButton.vue';
export default {
    name: "GoalsView",
    components:{
        CancelButton,
        CustomButton,
        'card-modal': CardModal,
        DefaultPageComponent,
    },
    data(){
        return {
            loading: false,
            loadingSubmit: false,
            year: new Date().getFullYear(),
            selectedYear: new Date().getFullYear(),
            years: [],
            cells: [],
            advisors: [],
            cell: null,
            advisor: null,
            file: null,
            items: [],
            columns: [
                {
                    'ADVISOR': 
                    {
                        label: 'Assessor',
                        class: 'text-left',
                        // thStyle: {
                        //     width: '20%'
                        // }
                    }
                },
                {
                    'cell': 
                    {
                        label: 'Célula',
                        class: 'text-left',
                        // thStyle: {
                        //     width: '10%'
                        // }
                    }
                },
                {
                    'net': 
                    {
                        label: 'Custódia',
                        class: 'text-right',
                        formatter: (value)=>{
                            return 'R$'+' '+Number(value).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                        },
                        // thStyle: {
                        //     width: '20%'
                        // }
                    }
                },
                {
                    'max_entries': 
                    {
                        label: 'Máximo entradas',
                        class: 'text-right',
                        formatter: (value)=>{
                            return 'R$'+' '+Number(value).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                        },
                    }
                },
                {
                    'max_exits': 
                    {
                        label: 'Máximo saídas',
                        class: 'text-right',
                        formatter: (value)=>{
                            return 'R$'+' '+Number(value).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                        },
                    }
                },
                {
                    'net_inflow': 
                    {
                        label: 'Captação líquida',
                        class: 'text-right',
                        formatter: (value)=>{
                            return 'R$'+' '+Number(value).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                        },
                    }
                },
                {
                    'average_ticket': 
                    {
                        label: 'Ticket médio',
                        class: 'text-right',
                        formatter: (value)=>{
                            return 'R$'+' '+Number(value).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                        },
                    }
                },
                {
                    'activation': 
                    {
                        label: 'Ativação',
                        class: 'text-right',
                        // thStyle: {
                        //     width: '10%'
                        // }
                    }
                },
                {
                    'nps': 
                    {
                        label: 'NPS',
                        class: 'text-right',
                        // thStyle: {
                        //     width: '10%'
                        // }
                    }
                },
                {
                    'roa': 
                    {
                        label: 'ROA',
                        class: 'text-left',
                        formatter: (value)=>{
                            return value + '%'
                        },
                        // thStyle: {
                        //     width: '10%'
                        // }
                    }
                },
                // {
                //     'actions': 
                //     {
                //         label: 'Ações',
                //         class: 'text-right',
                //     }
                // },
            ],
        }
    },
    created(){
        this.getYears();
        this.getCells();
        this.getAdvisors();
        this.getGoals();
    },
    computed:{
        isDisabled(){
            return this.loading || this.loadingSubmit || !this.file;
        },
        advisorOptions(){
            if(this.cell)
                return this.advisors.filter(advisor => {
                    return advisor.cellHistories.length > 0 && advisor.cellHistories[0].cell.id === this.cell
                });
            else return this.advisors;
        }
    },
    methods: {
        async getGoals(){
            try{
                this.loading=true;
                var endpoint = 'goals-cockpit';
                if(this.selectedYear) endpoint+='?year='+this.selectedYear;
                if(this.advisor) endpoint+='&advisor='+this.advisor;
                if(this.cell) endpoint+='&cell='+this.cell;
    
                const response = await this.$axios.get(endpoint);
                this.items = response.data.goals_cockpit;
            }
            catch(error){
                console.log(error);
            }
            finally{ this.loading=false; }
        },
        async getYears(){
            const response = await this.$axios.get('goals-cockpit/years');
            this.years = response.data.years;
        },
        async getCells(){
            const response = await this.$axios.get('cells');
            this.cells = response.data.data;
        },
        async getAdvisors(){
            const response = await this.$axios.get('advisors');
            this.advisors = response.data.data.filter(e=>e.advisorCommercialPartner[0]?.code);
        },
        openFile(){
            this.$refs.fileInput.click();
        },
        handleFile(event){
            const file = event.target.files[0];
            if(file.name.split('.').pop().includes('xlsx')){
                this.file = file;
            }
            else{
                this.$swal({
                    title: 'Arquivo inválido',
                    text: 'Arquivo inválido, por favor, verifique o modelo de importação.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28A745',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            }
        },
        openNew(){
            this.file=null;
            this.$refs.modalNewGoal.openModal();
        },
        async saveGoalsFileImport () {    
            const formData = new FormData();
            formData.append('sheet', this.file);
            formData.append('file_name', this.file.name);
            const response = await this.$axios.post('goals-cockpit/import', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            return response;
        },
        clear(){
            this.file = null;
        },
        async saveNew(){
            if(!this.file) {
                this.$swal({
                    title: 'Atenção',
                    text: 'A importação do arquivo é obrigatória.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28A745',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return false;
            }
            try{
                this.loading = true;
                this.loadingSubmit = true;

                const post = await this.saveGoalsFileImport();
                
                await Promise.all([post]);
                
                this.$swal({
                    title: 'Sucesso',
                    text: 'Arquivo importado com sucesso.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28A745',
                    allowOutsideClick: false,
                    focusConfirm: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
    
                this.clear();
                this.$refs.modalNewGoal.closeModal();
            }
            catch(error){
                console.log(error);
                this.$swal({
                    title: 'Atenção',
                    text: 'Houve um erro com a importação do arquivo!',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28A745',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            }
            finally {
                this.loading = false;
                this.loadingSubmit = false;
            }
        },
    },
}
</script>

<style scoped>
/* * /deep/ .modal-card{
    max-width:fit-content !important;
} */
</style>