<template>
    <div id="attachment">
        <DefaultPageComponent
        :loading="loading"
        :title="'Anexos'"
        :haveBackButton="true"
        @onBack="$router.push({name: 'dashboard'})"
        :haveNextButton="true"
        :buttonText="'Novo anexo'"
        @onTap="$router.push('/attachments/add')"
        :haveContentMargin="true"
        >

        <template #filters>
            <b-row>
                <b-col cols="3">
                    <div class="form-group">
                        <label for="">Título:</label>
                        <input type="text" class="form-control form-control-sm" id="inputUserName" placeholder="Pesquise pelo título" style="box-shadow: none; height: 56px;" v-model="filters.filter" @change="filterAttachments">
                    </div>
                </b-col>
            </b-row>
        </template>

        <!-- CONTENT -->
        <b-table class="custom-table" borderless :fields="columns" :items="filteredAttachments" sticky-header>
            <template #head(title)="data">
                <b-col class="text-left p-0">
                    {{data.label}}
                </b-col>
            </template>
            <template #head(file_name)="data">
                <b-col class="text-left p-0">
                    {{data.label}}
                </b-col>
            </template>
            <template #head(format)="data">
                <b-col class="text-left p-0">
                    {{data.label}}
                </b-col>
            </template>
            <template #head(actions)="data">
                <b-col class="text-center p-0">
                    {{data.label}}
                </b-col>
            </template>
            <template #cell(actions)="row">
                <div class="d-flex justify-content-end align-items-center">
                    <div style="display: flex; justify-content: center; align-itens: center;" class="text-center pb-3">
                        <div style="display: flex; justify-content: center; align-items: center;">
                            <b-button 
                                style="width: 39px; height: 40px; background-color: #242323; display: flex; justify-content: center; align-items: center; font-size: 0.875rem; border-color: #535252; box-shadow: none;"
                                class="mr-2"
                                @click="showAttachment(row.item)"
                            >
                                <img src="@/assets/visibility.png" />
                            </b-button>
                            <b-button 
                                style="width: 39px; height: 40px; background-color: #242323; display: flex; justify-content: center; align-items: center; font-size: 0.875rem; border-color: #535252; box-shadow: none;"
                                @click.prevent="editAttachment(row.item.id)"
                            >
                                <img src="@/assets/pencil.png" />
                            </b-button>
                        </div>
                    </div>
                </div>
            </template>
        </b-table>

        <template #footer>
            <div></div>
        </template>

        </DefaultPageComponent>

        <div style="position: fixed; top: 10%; left: 30%; width: 40vw; height: 80vh; background: #1C1B1B; z-index: 10000; border-radius: 25px;" class="p-4" v-if="modals.attachment">
            <b-row>
                <b-col style="font-size: 28px; font-weight: 700; display: flex; align-items: center; justify-content: space-between;">
                    <span>
                        {{ attachment.title }}
                    </span>
                    <span style="font-size: 18px; font-weight: 700; cursor: pointer;" @click="modals.attachment = false">
                        x
                    </span>
                </b-col>
            </b-row>
            <b-row class="mt-4" style="max-height:65vh; overflow:auto;">
                <div class="d-flex align-items-center justify-content-center w-100">
                    <img :src="attachment.file_path" alt="" height="550px">
                </div>
                <div class="d-flex align-items-center justify-content-center w-100 mt-4">
                    {{ attachment.file_name }}
                </div>
            </b-row>
        </div>
        <div class="modal-overlay" v-if="modals.attachment"></div>
    </div>  
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
export default {
    name: 'AttachmentsView',
    components:{
        DefaultPageComponent,
    },
    data () {
        return {
            columns: [
                {
                    'title': 
                    {
                        label: 'Título',
                    }
                },
                {
                    'file_name': 
                    {
                        label: 'Nome arquivo',
                    }
                },
                {
                    'format': 
                    {
                        label: 'Formato',
                    }
                },
                {
                    'actions': 
                    {
                        label: '',
                        class: 'text-right',
                    }
                },
            ],
            loading: false,
            attachments: [],
            filteredAttachments: [],
            filters: {
                filter: '',
            },
            attachment: {},
            modals: {
                attachment: false,
            }
        }
    },
    mounted () {
        this.getAttachments();
    },
    methods: {
        async getAttachments() {
            this.loading=true;
            this.$axios.get('attachments').then((response) => {
                this.attachments = response.data.data;
                this.filteredAttachments = this.attachments;
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar os anexos.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            })
            .finally(()=>{
                this.loading=false;
            })
        },
        async editAttachment(attachamentID) {
            const response = await this.$axios.get('attachments/check-message-usage/'+attachamentID);
            if(response.data.result){
                this.$swal({
                    title: 'Atenção',
                    text: 'Esse anexo não pode ser editado, pois já foi utilizado em uma mensagem.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            }
            this.$router.push({ name: 'attachmentEdit', params: { id: attachamentID }});
        },
        filterAttachments() {
            const normalizeString = str => {
                return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            };

            const normalizedFilter = normalizeString(this.filters.filter.toLowerCase());

            this.filteredAttachments = this.attachments.filter(attachment => {
                const normalizedTitle = normalizeString(attachment.title.toLowerCase());
                return normalizedTitle.includes(normalizedFilter);
            });
        },
        showAttachment(attachment) {
            this.attachment = attachment;
            this.modals.attachment = true;
        }
    },
    watch: {
        'filters.filter': function () {
            this.filterAttachments()
        }
    }
}
</script>

<style>
    #attachment {
        height: calc(100vh - 125px);
    }

    .form-control {
        background-color: #262625 !important;
        border: 1px solid #535252 !important;
        color: #E5E2E1 !important;
        border-radius: 10px !important;
        padding-left: 20px !important;
    }

    input::-webkit-input-placeholder {
        color: #E5E2E1 !important;
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-image: url('data:image/svg+xml;utf8,<svg fill="white" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
        background-repeat: no-repeat;
        background-position-x: calc(100% - 15px);
        background-position-y: center;
        padding-left: 20px !important;
    }
</style>
