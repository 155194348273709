<template>
    <div id="tag">
        <DefaultPageComponent
        :loading="loading"
        :title="'Tags'"
        :haveBackButton="true"
        @onBack="$router.push({name: 'dashboard'})"
        :haveNextButton="true"
        :buttonText="'Nova tag'"
        @onTap="$router.push('/tags/add')"
        :haveContentMargin="true"
        >

        <template #filters>
            <b-row>
                <b-col cols="3">
                    <div class="form-group">
                        <label for="">Descrição:</label>
                        <input type="text" class="form-control form-control-sm" id="inputUserName" placeholder="Pesquise pela descrição" style="box-shadow: none; height: 56px;" v-model="filters.filter" @change="filterTags">
                    </div>
                </b-col>
            </b-row>
        </template>

        <!-- CONTENT -->
        <b-table class="custom-table" borderless :fields="columns" :items="filteredTags" sticky-header>
            <template #head(tag)="data">
                <b-col class="text-left p-0">
                    {{data.label}}
                </b-col>
            </template>
            <template #head(description)="data">
                <b-col class="text-left p-0">
                    {{data.label}}
                </b-col>
            </template>
            <template #head(type)="data">
                <b-col class="text-left p-0">
                    {{data.label}}
                </b-col>
            </template>
            <template #head(actions)="data">
                <b-col class="text-center p-0">
                    {{data.label}}
                </b-col>
            </template>
            <template #cell(type)="row">
                <b-col class="text-left p-0" v-if="row.item.type">
                    Sim
                </b-col>
                <b-col class="text-left p-0" v-else>
                    Não
                </b-col>
            </template>
            <template #cell(actions)="row">
                <div class="d-flex justify-content-end align-items-center">
                    <div style="display: flex; justify-content: center; align-itens: center;" class="text-center pb-3">
                        <div style="display: flex; justify-content: center; align-items: center;">
                            <!-- <b-form-checkbox switch size="sm" class="mr-2" style="min-width: 80px;" v-model="row.item.status">
                                {{ row.item.status ? 'Ativo' : 'Inativo' }}
                            </b-form-checkbox> -->
                            <b-button 
                                style="width: 39px; height: 40px; background-color: #242323; display: flex; justify-content: center; align-items: center; font-size: 0.875rem; border-color: #535252; box-shadow: none;"
                                @click.prevent="editTag(row.item.id)"
                            >
                                <img src="@/assets/pencil.png" />
                            </b-button>
                        </div>
                    </div>
                </div>
            </template>
        </b-table>

        <template #footer>
            <div></div>
        </template>

        </DefaultPageComponent>

    </div>  
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
export default {
    name: 'TagsView',
    components:{
        DefaultPageComponent,
    },
    data () {
        return {
            columns: [
                {
                    'tag': 
                    {
                        label: 'Tag',
                    }
                },
                {
                    'description': 
                    {
                        label: 'Descrição',
                    }
                },
                {
                    'type': 
                    {
                        label: 'Utiliza na tabela',
                    }
                },
                {
                    'actions': 
                    {
                        label: '',
                        class: 'text-right',
                    }
                },
            ],
            loading: false,
            tags: [],
            filteredTags: [],
            filters: {
                filter: '',
            },
        }
    },
    mounted () {
        this.getTags();
    },
    methods: {
        async getTags() {
            this.loading=true;
            this.$axios.get('tags').then((response) => {
                this.tags = response.data.data;
                this.filteredTags = this.tags;
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar as tags.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            })
            .finally(()=>{
                this.loading=false;
            })
        },
        async editTag(tagID) {
            const response = await this.$axios.get('tags/check-message-usage/'+tagID);
            if(response.data.result){
                this.$swal({
                    title: 'Atenção',
                    text: 'Essa tag não pode ser editada, pois já foi utilizada em uma mensagem.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            }
            this.$router.push({ name: 'tagEdit', params: { id: tagID }});
        },
        filterTags() {
            const normalizeString = str => {
                return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            };

            const normalizedFilter = normalizeString(this.filters.filter.toLowerCase());

            this.filteredTags = this.tags.filter(tag => {
                const normalizedDescription = normalizeString(tag.description.toLowerCase());
                return normalizedDescription.includes(normalizedFilter);
            });
        }
    },
    watch: {
        'filters.filter': function () {
            this.filterTags()
        }
    }
}
</script>

<style>
    #tag {
        height: calc(100vh - 125px);
    }

    .form-control {
        background-color: #262625 !important;
        border: 1px solid #535252 !important;
        color: #E5E2E1 !important;
        border-radius: 10px !important;
        padding-left: 20px !important;
    }

    input::-webkit-input-placeholder {
        color: #E5E2E1 !important;
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-image: url('data:image/svg+xml;utf8,<svg fill="white" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
        background-repeat: no-repeat;
        background-position-x: calc(100% - 15px);
        background-position-y: center;
        padding-left: 20px !important;
    }
</style>
