<template>
    <div v-if="renderComponent" id="cockpit" style="width:100%; height:100%;">
        <DefaultPageComponent
        :loading="loading"
        :title="'Importação de tabelas Cockpit'"
        :haveBackButton="true"
        @onBack="$router.push({name: 'dashboard'})"
        >

        <template #header>
            <b-col class="d-flex align-items-center justify-content-end px-0">
                <div class="d-flex flex-column">
                    <GlassButton
                    @click="showStatus"
                    > Importações
                    </GlassButton>
                </div>
            </b-col>
        </template>

        <!-- CONTENT -->
        <div>
            <ImportationComponent  class="my-4 mx-0"
            :title="'Tabela Positivador'"
            :inputRef="'inputFilePositivator'"
            :disabled="isPositivatorDisabled || loading"
            @change="getFilePositivator"
            >
                <b-col cols="3" align-self="center">
                    <InputDatePicker
                    @input="(value)=>{ positionDatePositivator=value; verifyForm(); }"
                    :inputId="'inputDatePositivator'"
                    style="width:80%;"
                    label="Data da posição:"
                    placeholder="Selecione"
                    :value="positionDatePositivator"
                    :maxDate="today"
                    />
                </b-col>
            </ImportationComponent>

            <ImportationComponent  class="my-4 mx-0"
            :title="'Tabela Custódia XP US'"
            :inputRef="'inputFileXPUS'"
            :disabled="isXPUSDisabled || !quotation || loading"
            @clickDisabled="showAttention(quotation)"
            @change="getFileXPUS"
            >
                <b-col cols="3" align-self="center">
                    <InputDatePicker
                    @input="(value)=>{ positionDateXPUS=value; getQuotation(); }"
                    :inputId="'inputDateXPUS'"
                    style="width:80%;"
                    label="Data da posição:"
                    placeholder="Selecione"
                    :value="positionDateXPUS"
                    :maxDate="today"
                    />
                    <span style="color:#EB694A"> Cotação: {{ quotation ? quotation : 'Sem informação' }} </span>
                </b-col>
            </ImportationComponent>

            <ImportationComponent  class="my-4 mx-0"
            :title="'Tabela Captação XP US'"
            :inputRef="'inputFileCapXPUS'"
            :disabled="isCapXPUSDisabled || !quotationCap || loading"
            @clickDisabled="showAttention(quotationCap)"
            @change="getFileCapXPUS"
            >
                <b-col cols="3" align-self="center">
                    <InputDatePicker
                    @input="(value)=>{ positionDateCapXPUS=value; getCapQuotation(); }"
                    :inputId="'inputDateCapXPUS'"
                    style="width:80%;"
                    label="Data da posição:"
                    placeholder="Selecione"
                    :value="positionDateCapXPUS"
                    :maxDate="today"
                    />
                    <span style="color:#EB694A"> Cotação: {{ quotationCap ? quotationCap : 'Sem informação' }} </span>
                </b-col>
            </ImportationComponent>

            <ImportationComponent  class="my-4 mx-0"
            :title="'Transferência de clientes'"
            :inputRef="'inputFileCustomers'"
            :disabled="isCustomerDisabled || loading"
            @change="getFileCustomer"
            >
                <b-col cols="3" align-self="center">
                    <InputDatePicker
                    @input="(value)=>{ positionDateCustomers=value; verifyForm(); }"
                    :inputId="'inputDateCustomers'"
                    style="width:80%;"
                    label="Data da posição:"
                    placeholder="Selecione"
                    :value="positionDateCustomers"
                    :maxDate="today"
                    />
                </b-col>
            </ImportationComponent>
        </div>

        <template #footer>
            <b-row align-h="end" class="mx-0">
                <CustomButton
                :disabled="isDisabled"
                :loading="loading"
                @click="save"
                > Salvar
                </CustomButton>
            </b-row>
        </template>

        </DefaultPageComponent>

        <card-modal title="Status de importações" ref="modalImportStatus" style="z-index:2;">
            <div class="row d-flex justify-content-center align-items-center">
                <b-col class="mx-4">
                    <b-row>
                        <b-col style="max-width: 200px;">
                            <div class="form-group" style="width:100%;">
                                <label for="">Período</label>
                                <input 
                                @change="getStatus(statusPeriod)"
                                id="inputDatePositionImports" 
                                type="month" 
                                class="form-control form-control-sm custom-month-input" 
                                style="box-shadow: none; min-height:56px; text-align:end; padding-right:20px; color-scheme: dark;" 
                                v-model="statusPeriod"
                                :max="todayMonth"
                                />
                            </div>
                        </b-col>
                    </b-row>
                    <div class="mt-2 mb-4" style="width:100%; border-bottom:1px solid #535252"></div>
                    <b-table borderless sticky-header="64vh" :fields="columns" :items="items" show-empty class="p-3" style="background-color: #262625; border-radius: 10px;">
                        
                        <template #empty="">
                            <b-col class="text-center">
                                Não há dados disponíveis
                            </b-col>
                        </template>

                        <template #head(date)="data">
                            <b-col class="text-left p-0">
                                {{data.label}}
                            </b-col>
                        </template>
                        <template #head(positive)="data">
                            <b-col class="text-center p-0">
                                {{data.label}}
                            </b-col>
                        </template>
                        <template #head(xp_us)="data">
                            <b-col class="text-center p-0">
                                {{data.label}}
                            </b-col>
                        </template>
                        <template #head(fundraising_xp_us)="data">
                            <b-col class="text-center p-0">
                                {{data.label}}
                            </b-col>
                        </template>
                        <template #head(customer_transfer)="data">
                            <b-col class="text-center p-0">
                                {{data.label}}
                            </b-col>
                        </template>

                        <template #cell(date)="row">
                            {{ formatDateBR(row.item.date) }}
                        </template>
                        <template #cell(positive)="row">
                            <b-col class="text-center p-0" style="font-size:22px;">
                                <b-icon v-if="row.item.positive" icon="check-circle" style="color:#28A745"></b-icon>
                                <b-icon v-else icon="x-circle" style="color:#CB3411"></b-icon>
                            </b-col>
                        </template>
                        <template #cell(xp_us)="row">
                            <b-col class="text-center p-0" style="font-size:22px;">
                                <b-icon v-if="row.item.xp_us" icon="check-circle" style="color:#28A745"></b-icon>
                                <b-icon v-else icon="x-circle" style="color:#CB3411"></b-icon>
                            </b-col>
                        </template>
                        <template #cell(fundraising_xp_us)="row">
                            <b-col class="text-center p-0" style="font-size:22px;">
                                <b-icon v-if="row.item.fundraising_xp_us" icon="check-circle" style="color:#28A745"></b-icon>
                                <b-icon v-else icon="x-circle" style="color:#CB3411"></b-icon>
                            </b-col>
                        </template>
                        <template #cell(customer_transfer)="row">
                            <b-col class="text-center p-0" style="font-size:22px;">
                                <b-icon v-if="row.item.customer_transfer" icon="check-circle" style="color:#28A745"></b-icon>
                                <b-icon v-else icon="x-circle" style="color:#CB3411"></b-icon>
                            </b-col>
                        </template>

                    </b-table>
                </b-col>
            </div>
        </card-modal>

    </div>  
</template>

<script>
import ImportationComponent from '@/components/ImportationComponent.vue';
import CustomButton from '@/components/CustomButton.vue';
import GlassButton from '@/components/GlassButton.vue';
import CardModal from './CardModal.vue';
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import InputDatePicker from '@/components/InputDatePicker.vue';
export default {
    name: 'ImportCockpitView',
    components: {
        DefaultPageComponent,
        ImportationComponent,
        InputDatePicker,
        CustomButton,
        'card-modal': CardModal,
        GlassButton,
    },
    data () {
        return {
            renderComponent: true,
            loading: false,
            isDisabled: true,
            importations: {
                diversification: {
                    loading: false,
                    quantity: 0,
                    name: '',
                },
            },
            today: null,
            todayMonth: null,
            statusPeriod: null,
            positionDatePositivator: '',
            positionDateXPUS: '',
            positionDateCapXPUS: null,
            positionDateCustomers: '',
            quotation: null,
            quotationCap: null,
            filePositivator: null,
            fileXPUS: null,
            fileCapXPUS: null,
            fileCustomers: null,
            items: [],
            columns: [
                {
                    'date': {
                        label: 'Data',
                        class: 'text-left'
                    }
                },
                {
                    'positive': {
                        label: 'Positivador',
                        class: 'text-center'
                    }
                },
                {
                    'xp_us': {
                        label: 'XP US',
                        class: 'text-center'
                    }
                },
                {
                    'fundraising_xp_us': {
                        label: 'Captação XP US',
                        class: 'text-center'
                    }
                },
                {
                    'customer_transfer': {
                        label: 'Transferência clientes',
                        class: 'text-center'
                    }
                },
            ]
        }
    },
    mounted() {
        this.today = new Date().toISOString().split('T')[0];
        this.todayMonth = new Date().toISOString().split('T')[0].substring(0, 7);
        this.statusPeriod = new Date().toISOString().split('T')[0].substring(0, 7);
    },
    computed:{
        isPositivatorDisabled(){
            return !this.positionDatePositivator;
        },
        isXPUSDisabled(){
            return !this.positionDateXPUS;
        },
        isCapXPUSDisabled(){
            return !this.positionDateCapXPUS;
        },
        isCustomerDisabled(){
            return !this.positionDateCustomers;
        },
        isPositivatorValid(){
            return this.positionDatePositivator && this.filePositivator;
        },
        isXPUSValid(){
            return this.positionDateXPUS && this.fileXPUS;
        },
        isCapXPUSValid(){
            return this.positionDateCapXPUS && this.fileCapXPUS;
        },
        isCustomerValid(){
            return this.positionDateCustomers && this.fileCustomers;
        },
    },
    methods: {
        async forceRender() {
            this.renderComponent = false;
            await this.$nextTick();
            this.renderComponent = true;
        },
        async getStatus(value){
            var month = value.substring(5,7)
            var year = value.substring(0,4)
            const response = await this.$axios.get('import-dates?month='+month+'&year='+year);
            this.items = response.data;
        },
        showStatus(){
            this.statusPeriod = new Date().toISOString().split('T')[0].substring(0, 7);
            this.getStatus(this.statusPeriod);
            this.$refs.modalImportStatus.openModal();
        },
        getFilePositivator(value){
            this.filePositivator = value;
            this.verifyForm();
        },
        getFileXPUS(value){
            this.fileXPUS = value;
            this.verifyForm();
        },
        getFileCapXPUS(value){
            this.fileCapXPUS = value;
            this.verifyForm();
        },
        getFileCustomer(value){
            this.fileCustomers = value;
            this.verifyForm();
        },
        showAttention(quotation){
            if(!quotation){
                this.$swal({
                    title: 'Atenção',
                    text: 'Antes da importação da tabela XP US é necessário que informe a Data posição do arquivo para consultar a cotação.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28A745',
                    allowOutsideClick: false,
                });
                return;
            }
        },
        async getQuotation(){
            this.verifyForm();
            var day = this.positionDateXPUS.substring(8,10);
            var month = this.positionDateXPUS.substring(5,7);
            var year = this.positionDateXPUS.substring(0,4);
            const date = month+'-'+day+'-'+year;
            const url = "https://olinda.bcb.gov.br/olinda/servico/PTAX/versao/v1/odata/CotacaoDolarDia(dataCotacao=@dataCotacao)?@dataCotacao='"+ date +"'&$top=1&$format=json&$select=cotacaoCompra"
            const response = await this.$axios.get(url);
            this.quotation = response.data.value[0]?.cotacaoCompra || null;
        },
        async getCapQuotation(){
            this.verifyForm();
            var day = this.positionDateCapXPUS.substring(8,10);
            var month = this.positionDateCapXPUS.substring(5,7);
            var year = this.positionDateCapXPUS.substring(0,4);
            const date = month+'-'+day+'-'+year;
            const url = "https://olinda.bcb.gov.br/olinda/servico/PTAX/versao/v1/odata/CotacaoDolarDia(dataCotacao=@dataCotacao)?@dataCotacao='"+ date +"'&$top=1&$format=json&$select=cotacaoCompra"
            const response = await this.$axios.get(url);
            this.quotationCap = response.data.value[0]?.cotacaoCompra || null;
        },
        formatDateBR(dateString) {
            if (!dateString) {
                return '';
            }

            const [year, month, day] = dateString.split('-');
            const formattedDate = `${day}/${month}/${year}`;

            return formattedDate;
        },
        clear(){
            this.positionDatePositivator = '';
            this.positionDateXPUS = '';
            this.positionDateCapXPUS = '';
            this.positionDateCustomers = '';
            this.filePositivator = null;
            this.fileXPUS = null;
            this.fileCapXPUS = null;
            this.fileCustomers = null;
            this.quotation = null;
            this.quotationCap = null;
            this.forceRender();
        },
        async save(){
            if(this.isDisabled){
                this.$swal({
                    title: 'Atenção',
                    text: 'Preencha o formulário antes de salvar.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28A745',
                    allowOutsideClick: false,
                });
                return;
            }
            try{
                this.loading=true;
                const positivator = await this.savePositivatorFileImport();
                const xpus = await this.saveXPUSFileImport();
                const cap_xpus = await this.saveCapXPUSFileImport();
                const customer = await this.saveCustomersFileImport();
                await Promise.all([positivator, xpus, cap_xpus, customer]);
                this.$swal({
                    title: 'Sucesso',
                    text: 'Arquivo(s) importado(s) com sucesso.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28A745',
                    allowOutsideClick: false,
                    focusConfirm: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                this.clear();
            }
            catch(error){
                console.log(error);
                this.$swal({
                    title: 'Atenção',
                    text: 'Houve um erro durante a importação de um arquivo!',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28A745',
                    allowOutsideClick: false,
                });
            }
            finally{ this.loading=false; }
        },
        async savePositivatorFileImport () {
            if(!this.filePositivator) return;
            const formData = new FormData();
            formData.append('sheet', this.filePositivator);
            formData.append('position_date', this.positionDatePositivator);
            formData.append('file_name', this.filePositivator.name);
            const response = await this.$axios.post('positive/import', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            return response;
        },
        async saveXPUSFileImport () {
            if(!this.fileXPUS) return;
            const formData = new FormData();
            formData.append('sheet', this.fileXPUS);
            formData.append('reference_date', this.positionDateXPUS);
            formData.append('exchange_rate_usd', this.quotation);
            const response = await this.$axios.post('xp-us/import', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            return response;
        },
        async saveCapXPUSFileImport () {
            if(!this.fileCapXPUS) return;
            const formData = new FormData();
            formData.append('sheet', this.fileCapXPUS);
            formData.append('reference_date', this.positionDateCapXPUS);
            formData.append('exchange_rate_usd', this.quotationCap);
            const response = await this.$axios.post('fundraising-xp-us/import', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            return response;
        },
        async saveCustomersFileImport () {
            if(!this.fileCustomers) return;
            const formData = new FormData();
            formData.append('sheet', this.fileCustomers);
            formData.append('reference_date', this.positionDateCustomers);
            formData.append('file_name', this.fileCustomers.name);
            const response = await this.$axios.post('customer-transfer/import', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            return response;
        },
        verifyForm(){
            if(
                (this.positionDatePositivator && !this.filePositivator) ||
                (this.positionDateXPUS && !this.fileXPUS) ||
                (this.positionDateCapXPUS && !this.fileCapXPUS) ||
                (this.positionDateCustomers && !this.fileCustomers) || 
                (!this.positionDatePositivator && !this.positionDateXPUS && !this.positionDateCustomers)
            ) this.isDisabled = true;
            else this.isDisabled = false;
        },
    }
}
</script>

<style>
    #dashboard {
        height: calc(100vh - 125px);
    }

    .form-control {
        background-color: #262625 !important;
        border: 1px solid #535252 !important;
        color: #E5E2E1 !important;
        border-radius: 10px !important;
    }
</style>
