<template>
    <div id="default-message">
        <DefaultPageComponent
        :loading="loading"
        :title="'Nova mensagem padrão'"
        :haveBackButton="true"
        @onBack="$router.push('/default-messages')"
        >

        <!-- CONTENT -->
        <div class="py-4">
            <b-row class="mx-0">
                <b-col cols="3">
                    <div>
                        Listagem de tags
                    </div>
                    <div class="mt-3" style="border: 1px solid #535252; height: 60vh; overflow-y: auto; overflow-x: hidden;">
                        <b-row class="px-3 pt-3 pb-2" v-for="tag in tags" :key="tag.id">
                            <b-col cols="3">
                                <div style="border: 1px solid #535252; height: 48px;" class="d-flex align-items-center justify-content-center">
                                    {{ tag.tag }}
                                </div>
                            </b-col>
                            <b-col>
                                <div style="border: 1px solid #535252; height: 48px;" class="d-flex align-items-center justify-content-center">
                                    {{ tag.description }}
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </b-col>
                <b-col cols="6">
                    <div>
                        Título
                    </div>
                    <div class="mt-3 mb-3">
                        <b-input style="box-shadow: none;" v-model="defaultMessage.title"></b-input>
                    </div>
                    <div>
                        Mensagem
                    </div>
                    <div style="height: calc(100% - 125px);" class="mt-2">
                        <b-form-textarea style="height: 100%; box-shadow: none;" v-model="defaultMessage.message"></b-form-textarea>
                    </div>
                </b-col>
                <b-col cols="3">
                    <div>
                        Listagem de anexos
                    </div>
                    <div class="mt-3" style="border: 1px solid #535252; height: 60vh;">
                        <b-row class="px-4 pt-3 pb-2" style="display: flex; align-items: center; justify-content: space-between;" v-for="attachment in attachments" :key="attachment.id">
                            <span class="ml-2">
                                <b-form-checkbox size="sm" v-model="attachment.selected">{{ attachment.title }}</b-form-checkbox>
                            </span>
                            <b-button 
                                size="sm"
                                @click="showAttachment(attachment)" class="px-2"
                                style="color: #EB694A; border:1px solid #474646; border-radius:4px; box-shadow: none; background-color: transparent; font-size:16px;"
                            >
                                <b-icon icon="eye-fill"></b-icon>
                            </b-button>
                        </b-row>
                    </div>
                </b-col>
            </b-row>
        </div>

        <template #footer>
            <b-row class="mx-0">
                <b-col class="d-flex justify-content-start align-items-center">
                    <b-form-checkbox switch size="sm" v-model="defaultMessage.type">Relatório Salesforce</b-form-checkbox>
                </b-col>
                <b-col class="d-flex justify-content-end">
                    <CancelButton
                    @click="$router.push('/default-messages')"
                    >
                        Cancelar
                    </CancelButton>
                    <CustomButton
                    :loading="loading || loadingSubmit"
                    :disabled="defaultMessage.title === '' || defaultMessage.message === ''"
                    @click="saveMessage"
                    > 
                        Salvar
                    </CustomButton>
                    <!-- <button 
                        style="width: 240px; min-height: 56px; font-size: 16px; font-weight: 600; color: #474646; background: transparent; border: none; border-radius: 10px;"
                        class="d-flex align-items-center justify-content-center"
                        @click="$router.push('/default-messages')"
                    >
                        <span>
                            Cancelar
                        </span>
                    </button>
                    <button 
                        style="width: 240px; min-height: 56px; font-size: 16px; font-weight: 600; border-radius: 10px;"
                        class="d-flex align-items-center justify-content-center"
                        :style="defaultMessage.title === '' || defaultMessage.message === '' ? 'background: transparent; border: 1px solid #EB694A; color: #EB694A;' : 'background: #EB694A; border: none; color: #FFFFFF;'"
                        @click="saveMessage"
                    >
                        <span v-if="!loading">
                            Salvar
                        </span>
                        <span v-else>
                            <b-spinner small />
                        </span>
                    </button> -->
                </b-col>
            </b-row>
        </template>

        </DefaultPageComponent>

        <div style="position: fixed; top: 10%; left: 30%; width: 40vw; height: 80vh; background: #1C1B1B; z-index: 10000; border-radius: 25px;" class="p-4" v-if="modals.attachment">
            <b-row>
                <b-col style="font-size: 28px; font-weight: 700; display: flex; align-items: center; justify-content: space-between;">
                    <span>
                        {{ attachment.title }}
                    </span>
                    <span style="font-size: 18px; font-weight: 700; cursor: pointer;" @click="modals.attachment = false">
                        x
                    </span>
                </b-col>
            </b-row>
            <b-row class="mt-4" style="max-height:65vh; overflow: auto">
                <div class="d-flex align-items-center justify-content-center w-100">
                    <img :src="attachment.file_path" alt="" height="550px">
                </div>
                <div class="d-flex align-items-center justify-content-center w-100 mt-4">
                    {{ attachment.file_name }}
                </div>
            </b-row>
        </div>
        <div class="modal-overlay" v-if="modals.attachment"></div>
    </div>  
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import CancelButton from '@/components/CancelButton.vue';
import CustomButton from '@/components/CustomButton.vue';
export default {
    name: 'FormTagView',
    components:{
        DefaultPageComponent,
        CancelButton,
        CustomButton,
    },
    data () {
        return {
            loading: false,
            loadingSubmit: false,
            tags: [],
            attachments: [],
            defaultMessage: {
                title: '',
                message: '',
                type: 0,
                attachments: [],
            },
            attachment: {},
            modals: {
                attachment: false,
            }
        }
    },
    mounted () {
        this.getTags();
        this.getAttachments();
    },
    methods: {
        getTags() {
            this.$axios.get('tags').then((response) => {
                this.tags = response.data.data;
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar as tags.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            });
        },
        getAttachments() {
            this.$axios.get('attachments').then((response) => {
                this.attachments = response.data.data;
                this.attachments.forEach(attachment => attachment.selected = false);
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar os anexos.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            });
        },
        showAttachment(attachment) {
            this.attachment = attachment;
            this.modals.attachment = true;
        },
        async saveMessage () {
            if (this.defaultMessage.title === '' || this.defaultMessage.message === '')
                return;

            this.loading = true;

            this.defaultMessage.attachments = [];

            this.attachments.forEach(attachment => {
                if (attachment.selected)
                    this.defaultMessage.attachments.push(attachment.id)
            });

            let defaultMessage = this.defaultMessage;

            this.$axios.post('default-messages', defaultMessage).then(() => {
                this.$swal({
                    title: 'Registro cadastrado',
                    text: 'Registro cadastrado com sucesso.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#EB694A',
                    allowOutsideClick: false,
                    focusConfirm: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                this.$router.push('/default-messages');
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível cadastrar o anexo.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            }).finally(() => {
                this.loading = false;
            });
        }
    }
}
</script>

<style>
    #default-message {
        height: calc(100vh - 125px);
    }

    .custom-control-input:checked ~ .custom-control-label::before {
        color: #fff !important;
        border-color: #EB694A !important;
        background-color: #EB694A !important;
        box-shadow: none !important;
    }
</style>
