<template>
    <div class="page-view">
        
        <DefaultHeader v-if="!hideHeader"
        style="margin:0px !important;"
        :title="title"
        :subtitle="subtitle"
        :backButton="haveBackButton"
        :nextButton="haveNextButton"
        :nextButtonText="buttonText"
        :loading="loading"
        :disabled="disabled"
        @onBack="$emit('onBack')"
        @onTap="$emit('onTap')"
        >
            <slot name="header"></slot>
        </DefaultHeader>

        <div v-if="$slots.filters" class="filters py-4" :style="hideHeader ? 'padding-top:0px !important;' : ''">
            <slot name="filters"></slot>
        </div>

        <div v-if="$slots.default" class="content" ref="contentDiv" :class="haveContentMargin ? 'my-4' : ''" :style="loading ? 'position:relative; overflow:hidden;' : ''">
            <b-overlay :show="loading" bg-color="black" class="h-100" :style="loading ? 'position:fixed; width:100%;' : ''">
                <slot></slot>
            </b-overlay>
        </div>

        <div v-if="$slots.footer" class="footer pt-3">
            <slot name="footer"></slot>
        </div>
        
    </div>
</template>

<script>
import DefaultHeader from './DefaultHeader.vue'
export default {
    name: 'DefaultPageComponent',
    components:{
        DefaultHeader,
    },
    props:{
        loading: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        hideHeader: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: null,
        },
        subtitle: {
            type: String,
            default: null,
        },
        buttonText: {
            type: String,
            default: null,
        },
        haveBackButton: {
            type: Boolean,
            default: false,
        },
        haveNextButton: {
            type: Boolean,
            default: false,
        },
        haveContentMargin: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        loading(value) { if (value) this.scrollToTop(); },
    },
    methods:{
        scrollToTop(){ this.$refs.contentDiv.scrollTop = 0; },
    }
}
</script>

<style scoped>
.page-view{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */
}
.filters{
    border-bottom: 1px solid #535252;   
}
.content{
    height: 100%;
    overflow: auto;
}
.footer{
    border-top: 1px solid #535252;
}
</style>