<template>
    <div style="height: 100%;">
        <DefaultPageComponent
        :loading="loading"
        :title="'Unidades'"
        :haveBackButton="true"
        @onBack="$router.push({name: 'dashboard'})"
        :haveNextButton="true"
        :buttonText="'Nova unidade'"
        @onTap="$router.push({name: 'companiesNew'})"
        :haveContentMargin="true"
        >

        <!-- CONTENT -->
        <b-table class="custom-table" borderless sticky-header :fields="columns" :items="companies" show-empty empty-text="Sem registros para mostrar no momento...">
            <template #cell(actions)="row">
                <div class="d-flex justify-content-end align-items-center">
                    <b-button 
                        style="color: #EB694A; width: 39px; height: 40px; background-color: #242323; display: flex; justify-content: center; align-items: center; font-size: 0.875rem; border-color: #535252; box-shadow: none;"
                        @click="editCompany(row.item.id)"
                    >
                        <b-icon icon="pencil-fill"></b-icon>
                    </b-button>
                    <div class="px-1"></div>
                    <b-button 
                        style="color: #EB694A; width: 39px; height: 40px; background-color: #242323; display: flex; justify-content: center; align-items: center; font-size: 0.875rem; border-color: #535252; box-shadow: none;"
                        class="mr-2"
                        @click="deleteCompany(row.item.id)"
                    >
                        <b-icon icon="trash-fill"></b-icon>
                    </b-button>
                </div>
            </template>
        </b-table>

        <template #footer>
            <div></div>
        </template>

        </DefaultPageComponent>
    </div>
</template>

<script>
// import DefaultHeader from '@/components/DefaultHeader.vue';
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
export default {
    name: 'CompanyView',
    components:{
        // DefaultHeader,
        DefaultPageComponent,
    },
    mounted() {
        this.getCompanies();
    },
    data() {
        return {
            loading:false,
            columns: [
                {
                    'id': 
                    {
                        label: 'ID',
                        class: 'text-left'
                    }
                },
                {
                    'name': 
                    {
                        label: 'Nome',
                        class: 'text-left'
                    }
                },
                {
                    'state.acronym': 
                    {
                        label: 'UF',
                        class: 'text-left'
                    }
                },
                {
                    'address': 
                    {
                        label: 'Endereço',
                        class: 'text-left'
                    }
                },
                {
                    'neighborhood': 
                    {
                        label: 'Bairro',
                        class: 'text-left'
                    }
                },
                {
                    'ACTIONS': 
                    {
                        label: '',
                        class: 'text-right',
                    }
                },
            ],
            companies: [],
        }
    },
    methods: {
        getCompanies() {
            this.loading=true;
            this.$axios.get('companies').then((response) => {
                this.companies = response.data.data;
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar unidades.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                });
                return;
            })
            .finally(()=>{
                this.loading=false;
            })
        },
        editCompany(companyID) {
            this.$router.push({ name: 'companiesEdit', params: { id: companyID }});
        },
        deleteCompany(companyID) {
            this.$axios.get(`companies/${companyID}/has-advisor`).then((response) => {
                if(!response.data.canDeleted) {
                    this.$swal({
                        title: 'Atenção',
                        text: 'Não é possível excluir a unidade pois há assessores vinculados.',
                        icon: 'warning',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#28A745',
                        allowOutsideClick: false,
                    });
                    return;
                }

                this.$swal({
                    title: 'Excluir unidade?',
                    text: 'Deseja realmente excluir essa unidade?',
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonText: 'Cancelar',
                    confirmButtonColor: '#C7300C',
                    cancelButtonText: 'Excluir',
                    cancelButtonColor: '#28A745',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                        cancelButton: 'custom-confirm-button-class',
                    }
                }).then((result) => {
                    if (!result.isConfirmed) {
                        this.$axios.delete(`companies/${companyID}`).then((response) => {
                            if(response.status != 200) {
                                this.$swal({
                                    title: 'Erro',
                                    text: 'Não foi possível excluir a unidade.',
                                    icon: 'error',
                                    confirmButtonText: 'OK',
                                    confirmButtonColor: '#C44C30',
                                    allowOutsideClick: false,
                                });
                                return;
                            }
                            this.getCompanies();
                        });
                    }
                });
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar unidades.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                });
                return;
            });
        },
    }
}
</script>

<style>
.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff !important;
    border-color: #28A745 !important;
    background-color: #28A745 !important;
    box-shadow: none !important;
}

.custom-control-input:not(checked) ~ .custom-control-label::before {
    border-color: #CCC !important;
    box-shadow: none !important;
}

tr:nth-child(odd) {
    background-color: #DDD !important;
}
</style>
