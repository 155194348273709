<template>
    <b-row align-h="between">
        <b-col class="d-flex align-items-center justify-content-center py-3" style="max-width:fit-content;">
            <CockpitComponent v-if="onlyTitle" :onlyTitle="onlyTitle" :subtitle="subtitle" style="min-width:300px; max-width:300px;"/>
            <CockpitComponent v-else class="py-5" style="min-width:300px; max-width:300px; max-height:400px; min-height:400px;"
                :title="title" :type="type"
                :subtitle="subtitle"
                :goal="
                    type=='value' ? 'R$ '+formatLargeNumber(goal) :
                    type=='integer' ? goal.toString() :
                    goal + '%'
                "
                :value="type =='percentage' ? parseFloat(value.toFixed(2)) : value" 
                :target="type =='percentage' ? parseFloat(target) : target"
                />
        </b-col>
        <!-- <b-col class="d-flex align-items-center justify-content-center py-3" style="max-width:35%;"> -->
        <b-col :style="onlyTitle ? 'min-width:300px; max-width:300px;' : ''" class="d-flex align-items-center justify-content-center py-3">
            <GraphicBarComponent v-if="!onlyTitle && !hideBarGraphic" style="max-height:400px;"
            :type="type"
            :dataKey="dataKey"
            :dataKeyTarget="dataKeyTarget"
            :dataKeyCurrent="dataKeyCurrent"
            :data="data"
            />
        </b-col>
        <!-- <b-col class="d-flex align-items-center justify-content-center py-3" style="max-width:35%;">
            <GraphicLineComponent style="max-height:400px; min-height:400px;" :title="'Custódia'" :goal="'R$ 15,5 bi'" :value="10"/>
        </b-col> -->
    </b-row>
</template>

<script>
import CockpitComponent from '@/components/CockpitComponent.vue'
import GraphicBarComponent from '@/components/GraphicBarComponent.vue'
export default {
    name: 'CockpitRow',
    components:{
        CockpitComponent,
        GraphicBarComponent,
    },
    props:{
        onlyTitle: String,
        title: String,
        subtitle: String,
        type: String,
        goal: Number,
        value: Number,
        target: Number,
        dataKey: String,
        dataKeyTarget: String,
        dataKeyCurrent: String,
        data: Object,
        hideBarGraphic: Boolean,
    },
    methods:{
        formatLargeNumber(number) {
            if (number >= 1e12) {
                return (number / 1e12).toFixed(1) + ' tri';
            } else if (number >= 1e9) {
                return (number / 1e9).toFixed(1) + ' bi';
            } else if (number >= 1e6) {
                return (number / 1e6).toFixed(1) + ' mi';
            } else {
                return Number(number).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            }
        }
    },
}
</script>

<style>

</style>