<template>
    <div class="px-5 pb-5 pt-4" style="background-color: #262625; min-height:100%;">
        <div class="pb-4"><span style="font-size:24px; color:#C95D43;"> 
            <!-- <a @click="$emit('back')" class="px-1" style="font-size: 20px; line-height:20px; cursor: pointer; border:1px solid #C95D43; color:#C95D43; border-radius:4px; margin-right:10px;">
                <b-icon icon="arrow-left-short"></b-icon>
            </a> -->
            Painel
        </span></div>
        <b-row cols-md="3" cols="1" align-h="between">
            <b-col class="d-flex align-items-center justify-content-center py-3">
                <CockpitComponent style="min-width:300px; max-width:300px; max-height:350px; min-height:350px;" 
                :title="'Custódia'" :type="'value'"
                :goal="'R$ '+formatLargeNumber(data.net.net_target)"
                :value="data.net.net_current" 
                :target="data.net.net_target"
                @show="$emit('show', 'cockpit/net')" 
                :showVisibility="true"/>
            </b-col>
            <b-col class="d-flex align-items-center justify-content-center py-3">
                <CockpitComponent style="min-width:300px; max-width:300px; max-height:350px; min-height:350px;"
                :title="'Ativação'" :type="'integer'"
                :goal="data.activation.activation_target.toString()"
                :value="data.activation.activation_current"
                :target="data.activation.activation_target" 
                @show="$emit('show', 'cockpit/activation')" 
                :showVisibility="true"/>
            </b-col>
            <b-col class="d-flex align-items-center justify-content-center py-3">
                <CockpitComponent style="min-width:300px; max-width:300px; max-height:350px; min-height:350px;"
                :title="'Receita - ROA'" :type="'percentage'"
                :goal="data.roa.roa_target + '%'"
                :value="parseFloat(data.roa.roa_current.toFixed(2))" 
                :target="parseFloat(data.roa.roa_target)"
                :periodReference="'Período referência: ' + getDateBR(data.roa.roa_reference)" 
                @show="$emit('show')" 
                :showVisibility="false"/>
            </b-col>
            <b-col class="d-flex align-items-center justify-content-center py-3">
                <CockpitComponent style="min-width:300px; max-width:300px; max-height:350px; min-height:350px;" 
                :title="'NPS Aniversário - Semestre'" :type="'integer'"
                :titleFontSize="16"
                @show="$emit('show', 'cockpit/nps')"
                :showVisibility="true"/>
                <!-- :goal="data.nps.nps_target"
                :value="data.nps.nps_current" 
                :target="data.nps.nps_target"
                @show="$emit('show', 'cockpit/nps')" -->
            </b-col>
            <!-- <b-col class="d-flex align-items-center justify-content-center py-3">
                <CockpitComponent style="min-width:300px; max-width:300px; max-height:350px; min-height:350px;" :title="'PJ2'" :goal="'R$ 15,5 bi'" :value="50" @show="$emit('show')" :showVisibility="true"/>
            </b-col>
            <b-col class="d-flex align-items-center justify-content-center py-3">
                <CockpitComponent style="min-width:300px; max-width:300px; max-height:350px; min-height:350px;" :title="'Campanhas'" :goal="'R$ 15,5 bi'" :value="60" @show="$emit('show')" :showVisibility="true"/>
            </b-col> -->
        </b-row>
    </div>
</template>

<script>
import CockpitComponent from '@/components/CockpitComponent.vue'

export default {
    name: "CockpitTable",
    components:{
        CockpitComponent,
    },
    props:{
        data: Object,
    },
    methods:{
        getDateBR(value){
            if(!value) return '---';
            var day = value.substring(8,10);
            var month = value.substring(5,7);
            var year = value.substring(0,4);
            var dateBR = day+'/'+month+'/'+year;
            return dateBR;
        },
        formatLargeNumber(number) {
            if (number >= 1e12) {
                return (number / 1e12).toFixed(1) + ' tri';
            } else if (number >= 1e9) {
                return (number / 1e9).toFixed(1) + ' bi';
            } else if (number >= 1e6) {
                return (number / 1e6).toFixed(1) + ' mi';
            } else {
                return Number(number).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            }
        }
    }
}
</script>

<style>

</style>