import Vue from "vue";

Vue.filter('validateCPF', (value)=>{
    // Remove non-numeric characters
    const cpf = value.replace(/\D/g, '');

    if (cpf.length !== 11) {
        return false;
    }

    // Check for repeated digits (e.g., 111.111.111-11)
    if (/^(\d)\1+$/.test(cpf)) {
        return false;
    }

    // Validate the CPF digits
    let sum = 0;
    let remainder;

    for (let i = 1; i <= 9; i++) {
        sum += parseInt(cpf[i - 1]) * (11 - i);
    }

    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) {
        remainder = 0;
    }

    if (remainder !== parseInt(cpf[9])) {
        return false;
    }

    sum = 0;

    for (let i = 1; i <= 10; i++) {
        sum += parseInt(cpf[i - 1]) * (12 - i);
    }

    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) {
        remainder = 0;
    }

    if (remainder !== parseInt(cpf[10])) {
        return false;
    }

    return true;
})

Vue.filter('validateCNPJ', (value)=>{
    // Remove any non-numeric characters
    const cnpj = value.replace(/[^\d]/g, '');

    // CNPJ must be 14 characters
    if (cnpj.length !== 14) {
        return false;
    }

    // Check CNPJ validation algorithm
    let numbers = cnpj.substring(0, 12);
    const digits = cnpj.substring(12);

    let sum = 0;
    let position = 5;

    for (let i = 0; i < 12; i++) {
        sum += parseInt(numbers.charAt(i)) * position;
        position--;
        if (position < 2) {
            position = 9;
        }
    }

    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

    if (result !== parseInt(digits.charAt(0))) {
        return false;
    }

    numbers = cnpj.substring(0, 13);
    sum = 0;
    position = 6;

    for (let i = 0; i < 13; i++) {
        sum += parseInt(numbers.charAt(i)) * position;
        position--;
        if (position < 2) {
            position = 9;
        }
    }

    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

    if (result !== parseInt(digits.charAt(1))) {
        return false;
    }

    return true;
})