<template>
  <div class="modal-container" v-if="showModal">
    <div class="modal-card">
      <div class="card-header d-flex align-items-center justify-content-between" style="border-radius: 8px 8px 0px 0px;">
          <span>{{ title }}</span>
          <a style="font-size: 12px; cursor: pointer;" @click="closeModal">
            <b-icon icon="x-lg"></b-icon>
        </a>
      </div>
      <div class="modal-body">
        <!-- Modal content goes here -->
        <slot></slot>
      </div>
    </div>
    <div class="modal-overlay" @click="closeModal"></div>
  </div>
</template>

<script>
export default {
  name: 'CardModal',
  props: {
    title: String,
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>

<style>
.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.2);
    z-index: 9999;
}

.modal-card {
    background: #1C1B1B;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    min-width: 50%;
    min-height: 25%;
    overflow: auto;
    z-index: 99999;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background: #1C1B1B;
    color: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.close {
    background: none;
    border: none;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
    padding: 0;
}

.modal-body {
    padding: 20px;
}
</style>
