<template>
    <b-button
    :variant="variant || 'secondary'"
    :style="{
        'border-radius': '10px',
        'min-height': '56px',
        'line-height': '0px',
        'width': width || '160px',
        'font-size': fontSize || '18px',
        'font-weight': fontWeight ||'600',
        'border': '1px solid ' + (borderColor || 'transparent'),
        'background-color': bgColor || 'transparent',
        'color': textColor || '#474646',
        'opacity': (disabled ? '0.3' : '1'),
    }"
    :disabled="disabled"
    @click="$emit('click')"
    >

        <slot></slot>

    </b-button>
</template>

<script>
export default {
    name: 'CancelButton',
    props: {
        variant: String,
        borderColor: String,
        bgColor: String,
        textColor: String,
        width: String,
        fontSize: String,
        fontWeight: String,
        disabled: Boolean,
    }
}
</script>

<style>

</style>