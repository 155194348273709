<template>
    <div id="objective">
        <DefaultPageComponent
        :loading="loading"
        :title="'Parametrização dos objetivos WFLOW ' + year"
        :haveBackButton="true"
        @onBack="$router.push({name: 'dashboard'})"
        :haveContentMargin="true"
        >

        <!-- CONTENT -->
        <b-table class="custom-table" borderless :fields="columns" :items="year !== '' ? items : []" sticky-header>
            <template #cell(name)="row">
                <b-col class="text-left p-0">
                    <div style="width: 100%;" class="d-flex justify-content-start mt-2" :style="row.item.type === 'SUBCLASSIFICATION' ? 'margin-left: 30px;' : ''">
                        {{ row.item.name }}
                    </div>
                </b-col>
            </template>
            <template #cell(ideal_representation)="row">
                <b-col class="text-left p-0">
                    <div style="width: 100%;" class="d-flex justify-content-end">
                        <b-input style="max-width: 90px; box-shadow: none;" class="mb-3" v-money="percentage" v-model="row.item.ideal_representation" @blur="change"></b-input>
                    </div>
                </b-col>
            </template>
            <template #cell(ideal_roa)="row">
                <b-col class="text-left p-0">
                    <div style="width: 100%;" class="d-flex justify-content-end">
                        <b-input style="max-width: 90px; box-shadow: none;" class="mb-3" v-money="percentage" v-model="row.item.ideal_roa" @blur="change"></b-input>
                    </div>
                </b-col>
            </template>
        </b-table>

        <template #footer>
            <b-row v-if="year != ''">
                <b-col class="d-flex justify-content-end">
                    <CancelButton
                    @click="cancel"
                    >
                        Cancelar
                    </CancelButton>
                    <CustomButton
                    :loading="loading || loadingSubmit"
                    @click="save"
                    > 
                        Salvar
                    </CustomButton>
                </b-col>
            </b-row>
        </template>

        </DefaultPageComponent>

    </div>  
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import CancelButton from '@/components/CancelButton.vue';
import CustomButton from '@/components/CustomButton.vue';
export default {
    name: 'ObjectivesView',
    components: {
        DefaultPageComponent,
        CancelButton,
        CustomButton,
    },
    data () {
        return {
            years: [],
            objectives: [],
            year: '',
            columns: [
                {
                    'name': 
                    {
                        label: 'Ativo',
                        thStyle: {
                            width: '50%'
                        }
                    }
                },
                {
                    'ideal_representation': 
                    {
                        label: 'Representatividade ideal',
                        thStyle: {
                            width: '25%'
                        }
                    }
                },
                {
                    'ideal_roa': 
                    {
                        label: 'ROA ideal',
                        thStyle: {
                            width: '20%'
                        }
                    }
                },
            ],
            items: [],
            loading: false,
            loadingSubmit: false,
            filters: {
                period: '',
            },
            percentage: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: '%',
                precision: 2,
                masked: false
            }
        }
    },
    async mounted () {
        this.getYears();
        this.year = new Date().getFullYear();
        await this.getClassifications();
        this.getObjectivesByYear();
    },
    methods: {
        getYears () {
            this.loading = true;
            this.$axios.get('objectives?groupBy=year').then((response) => {
                this.years = response.data;
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar os anos.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            })
            .finally(() => {
                this.loading = false;
            });
        },
        getObjectivesByYear() {
            this.loading = true;
            this.$axios.get(`objectives?year=${this.year}`).then((response) => {
                this.objectives = response.data;
                
                this.items.forEach(item => {
                    const matchingObjective = this.objectives.find(obj => obj.classification_id === item.classification_id && obj.sub_classification_id === item.subclassification_id);
                    if (matchingObjective) {
                        item.ideal_representation = Number(matchingObjective.ideal_representativeness).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                        item.ideal_roa = Number(matchingObjective.ideal_roa).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                    } else {
                        item.ideal_representation = Number(0).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                        item.ideal_roa = Number(0).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                    }
                });
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar os objetivos.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            })
            .finally(() => {
                this.loading = false;
            });
        },
        async getClassifications () {
            this.loading = true;
            return this.$axios.get('classifications').then((response) => {
                this.items = response.data;
                this.items = this.items.filter(item => item.classification_id != 19 && item.classification_id != 20);
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar as classificações.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            })
            .finally(() => {
                this.loading = false;
            });
        },
        change () {
            this.items.forEach(classification => {
                if (classification.type === "CLASSIFICATION") {
                    // let perc = 0;
                    this.items.forEach(subclassification => {
                        if(subclassification.type === "SUBCLASSIFICATION" && subclassification.classification_id === classification.classification_id) {
                            if (subclassification.ideal_representation == "")
                                subclassification.ideal_representation = "0,00";

                            // perc += Number(subclassification.ideal_representation.replace(",", "."));
                        }
                    });
                    // classification.ideal_representation = Number(perc).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                }
            })
        },
        cancel () {
            this.$router.push("/");
        },
        save () {
            if (this.loading)
                return;

            this.loading = true;
            this.loadingSubmit = true;
            this.$axios.post('objectives', this.items).then(() => {
                this.$swal({
                    title: 'Sucesso',
                    text: 'Objetivos criados/atualizados com sucesso.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28A745',
                    allowOutsideClick: false,
                    focusConfirm: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível criar/atualizar objetivos.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            }).finally(() => {
                this.loading = false;
                this.loadingSubmit = false;
            });
        }
    }
}
</script>

<style>
    #objective {
        height: calc(100vh - 125px);
    }

    .form-control {
        background-color: #262625 !important;
        border: 1px solid #535252 !important;
        color: #E5E2E1 !important;
        border-radius: 10px !important;
        padding-left: 20px !important;
    }

    input::-webkit-input-placeholder {
        color: #E5E2E1 !important;
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-image: url('data:image/svg+xml;utf8,<svg fill="white" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
        background-repeat: no-repeat;
        background-position-x: calc(100% - 15px);
        background-position-y: center;
        padding-left: 20px !important;
    }
</style>
