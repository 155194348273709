<template>
    <b-overlay
      :show="loading"
      opacity="0.75"
      bg-color="black"
      spinner-small
      spinner-variant="light"
      :style="{
        'border-radius': '10px',
        'width': width || '200px',
      }"
    >
        <b-button
        class="d-flex align-items-center justify-content-center"
        :variant="variant || 'secondary'"
        :style="{
            'border-radius': '10px',
            'min-height': '56px',
            'width': width || '200px',
            'line-height': '0px',
            'font-size': fontSize || '18px',
            'font-weight': fontWeight ||'600',
            'border': '1px solid ' + (borderColor || 'transparent'),
            'background-color': bgColor || '#EB694A20',
            'color': textColor || '#EB694A',
            'opacity': (disabled ? '0.3' : '1'),
        }"
        @click="$emit('click')"
        >

            <slot></slot>

        </b-button>
    </b-overlay>
</template>

<script>
export default {
    name: 'GlassButton',
    props: {
        loading: Boolean,
        variant: String,
        borderColor: String,
        bgColor: String,
        textColor: String,
        width: String,
        fontSize: String,
        fontWeight: String,
        disabled: Boolean,
    }
}
</script>

<style scoped>
* /deep/ .b-overlay div {
    border-radius: 9px;
}
</style>