<template>
    <div style="height: 100%;">
        <DefaultPageComponent
        :loading="loading"
        :title="'Parceiro comercial'"
        :haveBackButton="true"
        @onBack="$router.push({name: 'dashboard'})"
        :haveNextButton="true"
        :buttonText="'Novo'"
        @onTap="$router.push({name: 'commercialPartnerNew'})"
        :haveContentMargin="true"
        >

        <template #filters>
            <div class="row d-flex justify-content-start align-items-center">
                <div class="col-4">
                    <div class="form-group">
                        <label for="inputUserName">Nome</label>
                        <input type="email" class="form-control form-control-sm" id="inputUserName" style="box-shadow: none; min-height:56px;" placeholder="Digite o nome do parceiro comercial">
                    </div>
                </div>
                <div class="col-3">
                    <div class="form-group">
                        <label for="inputUserSatus">Status</label>
                        <select class="form-control form-control-sm" id="inputUserSatus" style="box-shadow: none; min-height:56px;">
                            <option value="">Todos</option>
                            <option value="1">Ativo</option>
                            <option value="0">Inativo</option>
                        </select>
                    </div>
                </div>
                <div class="col"></div>
                <div class="col-3 d-flex justify-content-end">
                    <GlassButton
                    :loading="loading"
                    @click="()=>{}"
                    > 
                        <img src="@/assets/search.png" />
                        Pesquisar
                    </GlassButton>
                    <!-- <button class="btn btn-success ml-1" @click="$router.push({ name: 'advisorNew' })">Novo assessor</button> -->
                </div>
            </div>
        </template>

        <!-- CONTENT -->
        <b-table class="custom-table" borderless sticky-header :fields="columns" :items="commercialPartners" show-empty empty-text="Sem registros para mostrar no momento...">
            <template #cell(n_document)="row">
                <div class="d-flex justify-content-start align-items-center">
                    {{ formatCNPJ(row.item.n_document) }}
                </div>
            </template>
            <template #cell(actions)="row">
                <div class="d-flex justify-content-end align-items-center">
                    <b-form-checkbox switch size="sm" v-model="row.item.status" @change="changeSituation(row.item)"></b-form-checkbox>
                    <b-button 
                        size="sm"
                        @click="editCommercialPartner(row.item.id)" class="px-2"
                        style="color: #EB694A; border:1px solid #474646; border-radius:4px; box-shadow: none; background-color: transparent; font-size:16px;"
                    >
                        <b-icon icon="pencil-fill"></b-icon>
                    </b-button>
                </div>
            </template>
        </b-table>

        <template #footer>
            <div></div>
        </template>

        </DefaultPageComponent>
    </div>
</template>

<script>
// import DefaultHeader from '@/components/DefaultHeader.vue';
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import GlassButton from '@/components/GlassButton.vue';
export default {
    name: 'CommercialPartnerView',
    components:{
        DefaultPageComponent,
        GlassButton,
        // DefaultHeader,
    },
    mounted() {
        this.getCommercialPartners();
    },
    data() {
        return {
            loading: false,
            columns: [
                {
                    'id': 
                    {
                        label: 'ID',
                        class: 'text-left'
                    }
                },
                {
                    'corporate_name': 
                    {
                        label: 'Razão Social',
                        class: 'text-left'
                    }
                },
                {
                    'trade_name': 
                    {
                        label: 'Nome Fantasia',
                        class: 'text-left'
                    }
                },
                {
                    'n_document': 
                    {
                        label: 'CNPJ',
                        class: 'text-left'
                    }
                },
                {
                    'contact': 
                    {
                        label: 'Contato',
                        class: 'text-left'
                    }
                },
                {
                    'phone.number': 
                    {
                        label: 'Telefone',
                        class: 'text-left'
                    }
                },
                {
                    'ACTIONS': 
                    {
                        label: '',
                        class: 'text-right',
                    }
                },
            ],
            commercialPartners: [],
        }
    },
    methods: {
        getCommercialPartners() {
            this.loading=true;
            this.$axios.get('commercial-partner').then((response) => {
                this.commercialPartners = response.data.data;
                this.commercialPartners.forEach(e=>e.status=true);
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar parceiros.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            })
            .finally(()=>{
                this.loading=false;
            })
        },
        editCommercialPartner(commercialPartnerID) {
            this.$router.push({ name: 'commercialPartnerEdit', params: { id: commercialPartnerID }});
        },
        changeSituation(item) {
            this.$swal({
                title: !item.status ? 'Inativar parceiro comercial?' : 'Ativar parceiro comercial?',
                text: !item.status ? `Deseja realmente inativar o parceiro comercial ${item.trade_name}?` : `Deseja realmente ativar o parceiro comercial ${item.trade_name}?`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Cancelar',
                confirmButtonColor: '#C7300C',
                cancelButtonText: !item.status ? 'Inativar' : 'Ativar',
                cancelButtonColor: '#28A745',
                allowOutsideClick: false,
                customClass: {
                    confirmButton: 'custom-confirm-button-class',
                    cancelButton: 'custom-confirm-button-class',
                }
            })
            return;
        },
        formatCNPJ(cnpj) {
            if (cnpj && cnpj.length === 14) {
                return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
            }
            return cnpj;
        },
    },
}
</script>

<style>
.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff !important;
    border-color: #28A745 !important;
    background-color: #28A745 !important;
    box-shadow: none !important;
}

.custom-control-input:not(checked) ~ .custom-control-label::before {
    border-color: #CCC !important;
    box-shadow: none !important;
}

tr:nth-child(odd) {
    background-color: #DDD !important;
}
</style>
