<template>
    <div id="dashboard">
        <DefaultPageComponent
        :loading="loading"
        :title="'Importação diária da tabela de Diversificação'"
        :haveBackButton="true"
        @onBack="$router.push({name: 'dashboard'})"
        >

        <template #header>
            <b-col class="px-0" style="min-width:fit-content;">    
                <div class="d-flex flex-column">
                    <label for="">Data da Última importação</label>
                    <input class="form-control form-control-sm text-left" id="inputFilterDate" style="box-shadow: none; max-width: 240px; min-height: 56px;" disabled>
                </div>
            </b-col>
        </template>

        <!-- CONTENT -->
        <div class="py-3">
            <b-row style="min-height: 98px;" class="mx-0">
                <b-col cols="3" class="d-flex align-items-center pl-0 pr-3">
                    <span style="font-size: 22px; font-wight: 600;">Tabela de Diversificação</span>
                </b-col>
                <b-col style="border: 1px dashed #535252; display: flex; align-items: center; justify-content: space-between">
                    <span v-if="importations.diversification.quantity == 0">
                        <GlassButton class="ml-4"
                        :loading="importations.diversification.loading || loading"
                        @click="openFileImportDiversification"
                        > 
                            Escolher arquivos...
                        </GlassButton>
                        <!-- <a href="#" class="ml-4">
                            Arraste o arquivo aqui
                        </a> -->
                        <input 
                            type="file"
                            ref="fileInputDiversification"
                            style="display: none;"
                            @change="handleFileImportDiversification"
                        />
                    </span>
                    <span v-else>
                        <img src="@/assets/check_circle_green_outline.png" alt="" class="mr-2">
                        {{ importations.diversification.name }}
                    </span>
                    <span>
                        <img src="@/assets/calc.png" alt="" class="mr-2" v-if="importations.diversification.quantity === 0">
                        <img src="@/assets/check_circle_green_outline.png" alt="" class="mr-2" v-else>
                        <label :style="importations.diversification.quantity === 0 ? '' : 'color: #4CAF50;'">{{ importations.diversification.quantity }} / 1</label>
                    </span>
                </b-col>
            </b-row>
            <!-- <b-row style="min-height: 98px;" class="px-4" v-if="importations.commission.quantity > 0">
                <span v-for="name in importations.commission.names" :key="name">
                    <b-row>
                        <b-col cols="3"></b-col>
                        <b-col style="border: 1px dashed #535252;">
                            <img src="@/assets/check_circle_green_outline.png" alt="" class="mr-3">
                            {{ name }}
                        </b-col>
                    </b-row>
                </span>
            </b-row> -->
        </div>

        <template #footer>
            <b-row class="mx-0" align-h="end">
                    <CustomButton
                    :loading="importations.diversification.loading || loading || loadingSubmit"
                    @click="$router.push('/')"
                    > 
                        Fechar
                    </CustomButton>
            </b-row>
        </template>

        </DefaultPageComponent>

    </div>  
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import CustomButton from '@/components/CustomButton.vue';
import GlassButton from '@/components/GlassButton.vue';
export default {
    name: 'ImportDiversificationDaily',
    components:{
        DefaultPageComponent,
        GlassButton,
        CustomButton,
    },
    data () {
        return {
            loading: false,
            loadingSubmit: false,
            importations: {
                diversification: {
                    loading: false,
                    quantity: 0,
                    name: '',
                },
            },
        }
    },
    mounted() {
        this.saveLog();
        this.getImportDate();
    },
    methods: {
        getImportDate () {
            this.loading=true;
            this.$axios.get('diversification-daily/date').then((response) => {
                if (
                    response.data.updated_date !== ''
                ) {
                    const [year, month, day] = response.data.updated_date.split('-');
                    const formattedDate = `${day}/${month}/${year}`;
                    document.getElementById('inputFilterDate').value = formattedDate;
                }
            })
            .finally(()=>{
                this.loading=false;
            })
        },
        openFileImportDiversification () {
            if (this.importations.diversification.loading || this.importations.diversification.quantity == 1)
                return;

            this.$refs.fileInputDiversification.click();
        },
        handleFileImportDiversification (event) {
            this.importations.diversification.loading = true;
            const file = event.target.files[0];

            if (file) {
                const formData = new FormData();
                formData.append('sheet', file);

                this.$axios.post('diversification-daily/import', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(response => {
                        if (response.status == 200) {
                            this.importations.diversification.name = file.name;
                            this.importations.diversification.quantity++;
                        } else { 
                            console.log(response);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
                    .finally(() => this.importations.diversification.loading = false)
            } else {
                this.importations.diversification.loading = false;
            }
        },
        saveLog () {
            let objLog = {
                description: 'Importação FGC'
            }

            this.$axios.post('logs', objLog).then((response) => {
                if(response.status != 201) {
                    console.log(response);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    }
}
</script>

<style>
    #dashboard {
        height: calc(100vh - 125px);
    }

    .form-control {
        background-color: #262625 !important;
        border: 1px solid #535252 !important;
        color: #E5E2E1 !important;
        border-radius: 10px !important;
    }
</style>
