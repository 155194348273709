<template>
    <div>
        <b-navbar id="header" toggleable="lg" type="dark" variant="dark">
            <b-navbar-brand href="#">
                <b-button id="iconNavBar" v-b-toggle.sidebar>
                    <b-icon icon="list" />
                </b-button>
                <img src="@/assets/logo.svg" alt="Logo WFLOW" style="margin-left: 10px; height: 35px;">
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav class="ml-auto" style="display: flex; align-items: center; justify-content: center;">
                    <b-nav-item-dropdown :text="user.first_name + ' ' + user.last_name" right id="menuUser">
                        <b-dropdown-item to="/profile">
                            <b-icon icon="person" class="mr-2"></b-icon>
                            Perfil
                        </b-dropdown-item>
                        <b-dropdown-item @click="handleLogout">
                            <b-icon icon="door-closed" class="mr-2"></b-icon>
                            Sair
                        </b-dropdown-item>
                    </b-nav-item-dropdown>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
import Cookie from '@/service/cookie'
import { mapState } from 'vuex';

export default {
    name: 'NavbarComponent',
    data () {
        return {
        };
    },
    computed: {
        ...mapState('user', {
            user: (state) => state.user,
        }),
    },
    methods: {
        handleLogout () {
            this.saveLog();
            Cookie.deleteToken();
            this.$store.commit('user/STORE_USER', '');
            this.$router.push({ name: 'login' });
        },
        saveLog () {
            let objLog = {
                description: 'Logout'
            }

            this.$axios.post('logs', objLog).then((response) => {
                if(response.status != 201) {
                    console.log(response);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    }
}
</script>

<style>
    /* Navbar */
    #header {
        height: 62px;
        background-color: #000 !important;
    }

    #iconNavBar {
        background-color: #000 !important; 
        border: 0 !important;
        box-shadow: none !important;
        color: #FFF !important;
    }

    #menuUser a {
        color: #FFF;
    }

    #menuUser a:hover {
        color: #CCC;
    }

    #menuUser ul {
        margin-top: 10px;
        background-color: #C34D2F;
    }

    #menuUser li a {
        font-size: 16px;
        background-color: #C34D2F;
        color: #FFF;
    }
</style>
