<template>
    <div id="integration">
        <DefaultPageComponent
        :loading="loading"
        :title="'Listagem de mensagens'"
        :haveBackButton="true"
        @onBack="$router.push({name: 'dashboard'})"
        :haveNextButton="true"
        :buttonText="'Nova mensagem'"
        @onTap="$router.push('/messages/add')"
        :haveContentMargin="true"
        >

        <template #filters>
            <b-row>
                <div class="d-flex">
                    <div class="form-group">
                        <b-col>
                            <label for="">Mensagem:</label>
                            <select v-model="filters.messageId" class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; min-width: 326px; max-width: 326px; min-height: 56px;">
                                <option value="">Selecione uma mensagem</option>
                                <option v-for="message in messages" :key="message.id" :value="message.id">{{ message.title }} </option>
                            </select>
                        </b-col>
                    </div>
                    <div class="form-group">
                        <b-col>
                            <label for="">Assessor:</label>
                            <select v-model="filters.advisorId" class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; min-width: 326px; max-width: 326px; min-height: 56px;">
                                <option value="">Todos</option>
                                <option v-for="advisor in advisors" :key="advisor.id" :value="advisor.id">{{ advisor.first_name + " " + advisor.last_name }} </option>
                            </select>
                        </b-col>
                    </div>
                </div>
            </b-row>
        </template>

        <!-- CONTENT -->
        <b-table class="custom-table" borderless :fields="columns" :items="filteredItems" sticky-header show-empty empty-text="Não há dados disponíveis">
            <template #head(title)="data">
                <b-col class="text-left p-0">
                    {{data.label}}
                </b-col>
            </template>
            <template #head(advisor)="data">
                <b-col class="text-left p-0">
                    {{data.label}}
                </b-col>
            </template>
            <template #head(customers)="data">
                <b-col class="text-center p-0">
                    {{data.label}}
                </b-col>
            </template>
            <template #head(date)="data">
                <b-col class="text-right p-0">
                    {{data.label}}
                </b-col>
            </template>
            <template #head(actions)="data">
                <b-col class="text-center p-0">
                    {{data.label}}
                </b-col>
            </template>
            <template #cell(title)="row">
                <b-col class="text-left p-0">
                    {{ row.item.default_message.title }}
                </b-col>
            </template>
            <template #cell(advisor)="row">
                <b-col class="text-left p-0">
                    {{ row.item.advisor.first_name +' '+ row.item.advisor.last_name }}
                </b-col>
            </template>
            <template #cell(customers)="row">
                <b-row align-h="center">
                    <b-button style="width: 45px; height: 40px; background-color:transparent !important; display: flex; justify-content: center; align-items: center; font-size: 0.875rem; border-color:#474646;" @click="showModalMessages(row.item.items_message)">
                        <span class="px-2" style="background-color: #EB694A; color:black; font-weight:600">{{ row.item.items_message.length }}</span>
                    </b-button>
                </b-row>
            </template>
            <template #cell(date)="row">
                <b-col v-if="row.item.message_sent==1" class="text-right p-0">
                    <span>{{ formatDateTimeBR(row.item.updated_at) }}</span>
                </b-col>
                <b-col v-else class="text-right p-0">
                    <span style="color:#CB3411;">{{ 'Pendente' }}</span>
                </b-col>
            </template>
            <template #cell(actions)="row">
                <div class="d-flex justify-content-end align-items-center">
                    <div style="display: flex; justify-content: center; align-itens: center;" class="text-center pb-3">
                        <div style="display: flex; justify-content: center; align-items: center;">
                            <!-- <b-form-checkbox switch size="sm" class="mr-2" style="min-width: 80px;" v-model="row.item.status">
                                {{ row.item.status ? 'Ativo' : 'Inativo' }}
                            </b-form-checkbox> -->
                            <b-button style="width: 45px; height: 40px; background-color:transparent !important; display: flex; justify-content: center; align-items: center; font-size: 18px; border-color:#474646;"
                            @click="exportDownload(row.item.id)"
                            >
                                <img src="@/assets/cloud_download.png" alt="Ícone de download">
                            </b-button>
                            <div class="px-2"></div>
                            <b-button style="width: 45px; height: 40px; background-color:transparent !important; display: flex; justify-content: center; align-items: center; font-size: 18px; border-color:#474646;"
                            @click="showDetails(row.item.id)"
                            >
                                <b-icon icon="eye-fill" style="color:#EB694A"></b-icon>
                            </b-button>
                        </div>
                    </div>
                </div>
            </template>
        </b-table>

        <template #footer>
            <div></div>
        </template>

        </DefaultPageComponent>

        <card-modal title="Clientes" ref="modalMessages">
            <div class="row d-flex justify-content-center align-items-center">
                <div class="col mt-2">
                    <b-table class="custom-table" striped borderless small sticky-header :fields="messages_column" :items="items_message" style="max-height:60vh !important; height:100%;" show-empty empty-text="Sem registros para mostrar no momento...">
                        <template #head(name)="data">
                            <b-col class="text-left p-0">
                                {{ data.label }}
                            </b-col>
                        </template>
                        <template #cell(name)="row">
                            <b-col class="text-left p-0">
                                {{ row.item.customer.first_name +' '+ row.item.customer.last_name }}
                            </b-col>
                        </template>
                        <template #head(value)="data">
                            <b-col class="text-right p-0">
                                {{ data.label }}
                            </b-col>
                        </template>
                        <template #cell(value)="row">
                            <b-col class="text-right p-0">
                                {{ 'R$ ' + Number(row.item.value).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                            </b-col>
                        </template>
                        <template #cell(group_name)="row">
                            <b-col class="text-left p-0">
                                {{ row.item.customer.group_name }}
                            </b-col>
                        </template>
                    </b-table>
                </div>
            </div>
        </card-modal>

    </div>  
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import CardModal from './CardModal.vue';
export default {
    name: 'IntegrationView',
    components:{
        DefaultPageComponent,
        'card-modal': CardModal,
    },
    data () {
        return {
            columns: [
                {
                    'title': 
                    {
                        label: 'Título mensagem',
                    }
                },
                {
                    'advisor': 
                    {
                        label: 'Assessor mensagem',
                    }
                },
                {
                    'customers': 
                    {
                        label: 'Clientes',
                    }
                },
                {
                    'date': 
                    {
                        label: 'Data envio',
                    }
                },
                {
                    'actions': 
                    {
                        label: '',
                        class: 'text-right',
                    }
                },
            ],
            messages_column: [
                {
                    'code_xp': 
                    {
                        label: 'Código',
                        class: 'text-right pb-2'
                    }
                },
                {
                    'name': 
                    {
                        label: 'Nome do cliente',
                        class: 'text-left pb-2'
                    }
                },
                {
                    'value': 
                    {
                        label: 'Valores',
                        class: 'text-right pb-2'
                    }
                },
                {
                    'group_name': 
                    {
                        label: 'Grupo WhatsApp',
                        class: 'text-left pb-2'
                    }
                },
            ],
            loading: false,
            items_message: [],
            messages: [],
            advisors: [],
            validIds: [67, 70, 30, 24],
            filteredMessages: [],
            filters: {
                messageId: '',
                advisorId: '',
            },
        }
    },
    computed: {
        filteredItems(){
            if(this.filters.messageId || this.filters.advisorId)
                return this.filteredMessages.filter(e=>{
                    if(this.filters.messageId && this.filters.advisorId)
                        return (e.advisor_id==this.filters.advisorId) && (e.default_message_id==this.filters.messageId)
                    else if(this.filters.messageId)
                        return (e.default_message_id==this.filters.messageId)
                    else if(this.filters.advisorId)
                        return (e.advisor_id==this.filters.advisorId)
                    else return false;
                })
            else return this.filteredMessages;
        },
    },
    mounted () {
        this.saveLog();
        this.getMessages();
        this.getFilteredMessages();
        this.getAdvisors();
        this.getIntegration();
    },
    methods: {
        getAdvisors () {
            this.loading=true;
            this.$axios.get('advisors').then((response) => {
                const filteredAdvisors = response.data.data.filter(advisor => 
                    this.validIds.includes(advisor.id)
                );
                this.advisors = filteredAdvisors;
            })
            .finally(()=>{
                this.loading=false;
            })
        },
        getMessages () {
            this.loading=true;
            this.$axios.get('default-messages').then((response) => {
                this.messages = response.data.data;
            })
            .finally(()=>{
                this.loading=false;
            })
        },
        getFilteredMessages () {
            this.loading=true;
            this.$axios.get('messages').then((response) => {
                this.filteredMessages = response.data.data;
            })
            .finally(()=>{
                this.loading=false;
            })
        },
        async exportDownload(id){
            try{
                var file_url = '';
                const response = await this.$axios('messages/export/' + id)
                file_url = response.data.file_url

                this.$axios({
                    url: file_url,
                    method: 'GET',
                    responseType: 'blob',
                })
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', file_url.split('/').pop());
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(error => {
                    console.error('Error downloading file:', error);
                    this.$swal({
                        title: 'Erro',
                        text: 'Não foi possível realizar o download.',
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#C44C30',
                        allowOutsideClick: false,
                    });
                });
            }
            catch(error){
                console.error('Error downloading file:', error);
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível realizar o download.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                });
            }
        },
        showDetails(id){
            this.$router.push({ name: 'messageEdit', params: { id: id }})
        },
        showModalMessages(list){
            this.items_message = list;
            this.$refs.modalMessages.openModal();
        },
        async getIntegration() {
            // this.$axios.get('banks').then((response) => {
            //     this.banks = response.data.data;
            // }).catch(() => {
            //     this.$swal({
            //         title: 'Erro',
            //         text: 'Não foi possível consultar bancos.',
            //         icon: 'error',
            //         confirmButtonText: 'OK',
            //         confirmButtonColor: '#C44C30',
            //         allowOutsideClick: false,
            //     });
            //     return;
            // });
        },
        async saveIntegration () {
            // this.loading = true;

            // let customer = this.customer;

            // customer.n_document = customer.n_document.replace(/\D/g, '');
            // customer.phone = customer.phone.replace(/\D/g, '');

            // this.$axios.post('customers', customer).then(() => {
            //     this.$swal({
            //         title: 'Sucesso',
            //         text: 'Cliente cadastrado com sucesso.',
            //         icon: 'success',
            //         confirmButtonText: 'OK',
            //         confirmButtonColor: '#28A745',
            //         allowOutsideClick: false,
            //         focusConfirm: false,
            //         customClass: {
            //             confirmButton: 'custom-confirm-button-class',
            //         }
            //     });
            //     this.$router.push('/customers');
            // }).catch(() => {
            //     this.$swal({
            //         title: 'Erro',
            //         text: 'Não foi possível cadastrar o cliente.',
            //         icon: 'error',
            //         confirmButtonText: 'OK',
            //         confirmButtonColor: '#C44C30',
            //         allowOutsideClick: false,
            //         customClass: {
            //             confirmButton: 'custom-confirm-button-class',
            //         }
            //     });
            // }).finally(() => {
            //     this.loading = false;
            // });
        },
        formatDateBR(dateString) {
            if (!dateString) {
                return '';
            }

            const [year, month, day] = dateString.split('-');
            const formattedDate = `${day}/${month}/${year}`;

            return formattedDate;
        },
        saveLog () {
            let objLog = {
                description: 'Mensagem'
            }

            this.$axios.post('logs', objLog).then((response) => {
                if(response.status != 201) {
                    console.log(response);
                }
            }).catch((error) => {
                console.log(error);
            });
        },
        formatDateTimeBR(value){
            if(!value) return '---';
            var day = value.substring(8,10);
            var month = value.substring(5,7);
            var year = value.substring(0,4);
            var hour = value.substring(11,13);
            var minute = value.substring(14,16);
            var dateBR = day+'/'+month+'/'+year+' '+hour+':'+minute;
            return dateBR;
        },
    }
}
</script>

<style>
    #integration {
        height: calc(100vh - 125px);
    }
</style>
