<template>
    <b-col class="d-flex p-3" style="width:100%; height:100%; flex-direction:column; background-color:#1C1B1B !important; border-radius:10px;">
        <div style="position:absolute; top:13px; right:13px;">
            <b-button v-if="showVisibility"
                style="width: 35px; height: 30px; background-color: #242323; color:#EB694A; display: flex; justify-content: center; align-items: center; font-size: 0.875rem; border-color: #535252;"
                @click="$emit('show')"
            >
                <b-icon icon="eye-fill"></b-icon>
            </b-button>
        </div>
        <b-row align-h="center"><span :style="'color:white; text-align:center; font-size:'+ ( titleFontSize || 20 )+'px; font-weight:600;'"> {{ onlyTitle || title || '---' }} </span></b-row>
        <b-row align-h="center"><span style="color:white; text-align:center; font-size:14px; font-weight:500;"> {{ subtitle }} </span></b-row>
        <ECharts v-if="!onlyTitle" ref="chart" :option="option" style="transform:translateY(20px);"/>
        <!-- <b-row v-if="periodReference && !onlyTitle" align-h="center" style="transform:translateY(-10px);"><span style="color:#EB694A; text-align:center">{{ periodReference || '---' }}</span></b-row> -->
    </b-col>
</template>

<script>
import { createComponent } from 'echarts-for-vue';
import * as echarts from 'echarts';

export default {
    name: 'CockpitPieComponent',
    components: {
        ECharts: createComponent({ echarts }),      // use as a component
    },
    props:{
        type: {
            type: String,
            default: 'integer'
        },
        onlyTitle: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: null
        },
        subtitle: {
            type: String,
            default: null
        },
        titleFontSize: {
            type: Number,
            default: null
        },
        list: {
            type: Array,
            default: ()=>{ return []; }
        },
        periodReference: {
            type: String,
            default: null
        },
        showVisibility: {
            type: Boolean,
            default: false
        },
    },
    mounted(){
        this.getListColors(this.list);
        this.getOptions(this.list, this.type);
    },
    data(){
        return {
            option:{},
        }
    },
    methods: {
        // getOptions(list, type){
        getOptions(list){
            this.option = {
                backgroundColor: '#1C1B1B',
                title: {
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item',
                    textColor: '#FFFFFF',
                    color: '#FFFFFF',
                    backgroundColor: '#000000',
                    formatter: (params) => {
                        return `
                            <div style="color:#FFFFFF">
                            ${params.name}: ${params.value}
                            </div>
                        `;
                    },
                },
                legend: {
                    orient: 'vertical',
                    left: 'center',
                    textStyle: {
                        color: '#FFFFFF',
                    },
                    formatter: (item) => {
                        return `${item}: ${list.find(e=>e.name==item).value}`;
                    },
                },
                series: [
                    {
                        name: 'Access From',
                        type: 'pie',
                        radius: '50%',
                        color: list.map(e=>e.color),
                        label: {
                            show: false
                        },
                        data: list,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        },
                    }
                ]
            };
        },
        formatLargeNumber(number) {
            if (number >= 1e12) {
                return (number / 1e12).toFixed(1) + ' tri';
            } else if (number >= 1e9) {
                return (number / 1e9).toFixed(1) + ' bi';
            } else if (number >= 1e6) {
                return (number / 1e6).toFixed(1) + ' mi';
            } else {
                return Number(number).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            }
        },
        getListColors(list) {
            var green = '#28A745';
            var yellow = '#FFC107';
            var red = '#DC3545';
            list.forEach(item=>{
                switch (item.name){
                    case 'Promotores':
                    case 'Respondidos':
                        item.color = green;
                        break;
                    case 'Neutros':
                        item.color = yellow;
                        break;
                    case 'Detratores':
                    case 'Não respondidos':
                        item.color = red;
                        break;
                    default:
                        item.color = '#FFFFFF';
                        break;
                }
            })
        },
    },
}
</script>

<style>

</style>