<template>
    <div class="d-flex align-items-center justify-content-center" style="height: 100%; width: 100%;">
    <div style="height: 100%; width: 100%;">
        <b-form @submit.stop.prevent="onSubmit" style="height: 100%; width: 100%;">
        <DefaultPageComponent
        :loading="loading"
        :title="'Editar parceiro comercial'"
        :haveBackButton="true"
        @onBack="$router.push({name: 'commercialPartner'})"
        >

        <!-- CONTENT -->
        <div class="py-3">
                <div class="mx-0 py-2 row d-flex justify-content-left">
                    <div class="col-md-6">
                        <b-form-group id="inputGroupCNPJ" label="CNPJ" label-for="inputGroupCNPJ">
                            <b-form-input
                                id="inputCNPJ"
                                name="inputCNPJ"
                                v-model="commercialPartner.n_document"
                                style="min-height:56px;"
                                v-validate="{ required: true, cnpj : true }"
                                :state="validateState('inputCNPJ')"
                                aria-describedby="inputCNPJFeedback"
                                data-vv-as="CNPJ"
                                v-mask="'##.###.###/####-##'"
                                disabled
                            ></b-form-input>

                            <b-form-invalid-feedback id="inputCNPJFeedback">{{ veeErrors.first('inputCNPJ') }}</b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                </div>
                <div class="mx-0 py-2 row d-flex justify-content-left">
                    <div class="col-md-6">
                        <b-form-group id="inputGroupCorporateName" label="Razão Social" label-for="inputGroupCorporateName">
                            <b-form-input
                                id="inputCorporateName"
                                name="inputCorporateName"
                                v-model="commercialPartner.corporate_name"
                                style="min-height:56px;"
                                v-validate="{ required: true }"
                                :state="validateState('inputCorporateName')"
                                aria-describedby="inputCorporateNameFeedback"
                                data-vv-as="Razão Social"
                                disabled
                            ></b-form-input>

                            <b-form-invalid-feedback id="inputCorporateNameFeedback">{{ veeErrors.first('inputCorporateName') }}</b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                    <div class="col-md-6">
                        <b-form-group id="inputGroupTradeName" label="Nome Fantasia" label-for="inputGroupTradeName">
                            <b-form-input
                                id="inputTradeName"
                                name="inputTradeName"
                                v-model="commercialPartner.trade_name"
                                style="min-height:56px;"
                                v-validate="{ required: true }"
                                :state="validateState('inputTradeName')"
                                aria-describedby="inputTradeNameFeedback"
                                data-vv-as="Nome Fantasia"
                                disabled
                            ></b-form-input>

                            <b-form-invalid-feedback id="inputTradeNameFeedback">{{ veeErrors.first('inputTradeName') }}</b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                </div>
                <div class="mx-0 py-2 row d-flex justify-content-left">
                    <div class="col-md-3">
                        <b-form-group id="inputGroupZipCode" label="CEP" label-for="inputGroupZipCode">
                            <b-form-input
                                id="inputZipCode"
                                name="inputZipCode"
                                v-model="commercialPartner.zip_code"
                                style="min-height:56px;"
                                v-validate="{ zipCode: true }"
                                :state="validateState('inputZipCode')"
                                aria-describedby="inputZipCodeFeedback"
                                data-vv-as="CEP"
                                v-mask="'##.###-###'"
                                @blur="searchZipCode"
                                :disabled="loading"
                            ></b-form-input>

                            <b-form-invalid-feedback id="inputZipCodeFeedback">{{ veeErrors.first('inputZipCode') }}</b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                    <div class="col-md-6">
                        <b-form-group id="inputGroupAddress" label="Endereço" label-for="inputGroupAddress">
                            <b-form-input
                                id="inputAddress"
                                name="inputAddress"
                                v-model="commercialPartner.address"
                                style="min-height:56px;"
                                v-validate="{}"
                                :state="validateState('inputAddress')"
                                aria-describedby="inputAddressFeedback"
                                data-vv-as="Endereço"
                                :disabled="loading"
                            ></b-form-input>

                            <b-form-invalid-feedback id="inputAddressFeedback">{{ veeErrors.first('inputAddress') }}</b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                    <div class="col-md-3">
                        <b-form-group id="inputGroupNumber" label="Número" label-for="inputGroupNumber">
                            <b-form-input
                                id="inputNumber"
                                name="inputNumber"
                                v-model="commercialPartner.number"
                                style="min-height:56px;"
                                v-validate="{}"
                                :state="validateState('inputNumber')"
                                aria-describedby="inputNumberFeedback"
                                data-vv-as="Número"
                                :disabled="loading"
                            ></b-form-input>

                            <b-form-invalid-feedback id="inputNumberFeedback">{{ veeErrors.first('inputNumber') }}</b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                </div>
                <div class="mx-0 py-2 row d-flex justify-content-left">
                    <div class="col-md-5">
                        <b-form-group id="inputGroupNeighborhood" label="Bairro" label-for="inputGroupNeighborhood">
                            <b-form-input
                                id="inputNeighborhood"
                                name="inputNeighborhood"
                                v-model="commercialPartner.neighborhood"
                                style="min-height:56px;"
                                v-validate="{}"
                                :state="validateState('inputNeighborhood')"
                                aria-describedby="inputNeighborhoodFeedback"
                                data-vv-as="Bairro"
                                :disabled="loading"
                            ></b-form-input>

                            <b-form-invalid-feedback id="inputNeighborhoodFeedback">{{ veeErrors.first('inputNeighborhood') }}</b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                    <div class="col-md-5">
                        <b-form-group id="inputGroupCity" label="Cidade" label-for="inputGroupCity">
                            <b-form-input
                                id="inputCity"
                                name="inputCity"
                                v-model="commercialPartner.city"
                                style="min-height:56px;"
                                v-validate="{}"
                                :state="validateState('inputCity')"
                                aria-describedby="inputCityFeedback"
                                data-vv-as="Cidade"
                                :disabled="loading"
                            ></b-form-input>

                            <b-form-invalid-feedback id="inputCityFeedback">{{ veeErrors.first('inputCity') }}</b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                    <div class="col-md-2">
                        <b-form-group id="inputState" label="UF" label-for="inputGroupState">
                            <b-form-select
                                id="inputState"
                                name="inputState"
                                v-model="commercialPartner.state_id"
                                style="min-height:56px;"
                                class="form-control"
                                v-validate="{}"
                                :state="validateState('inputState')"
                                aria-describedby="inputStateFeedback"
                                data-vv-as="UF"
                                :disabled="loading"
                            >
                                <option value="null" key=""></option>
                                <option v-for="state in states" :value="state.id" :key="state.id">{{ state.acronym }}</option>
                            </b-form-select>

                            <b-form-invalid-feedback id="inputStateFeedback">{{ veeErrors.first('inputState') }}</b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                </div>

                <div class="my-4" style="border-bottom:1px solid #535252"></div>

                <div class="mx-0 py-2 row d-flex justify-content-left">
                    <div class="col">
                        <b-form-group id="inputGroupNameContact" label="Nome do contato" label-for="inputGroupNameContact">
                            <b-form-input
                                id="inputNameContact"
                                name="inputNameContact"
                                v-model="commercialPartner.name_contact"
                                style="min-height:56px;"
                                v-validate="{}"
                                :state="validateState('inputContact')"
                                aria-describedby="inputNameContactFeedback"
                                data-vv-as="Nome"
                                :disabled="loading"
                            ></b-form-input>

                            <b-form-invalid-feedback id="inputNameContactFeedback">{{ veeErrors.first('inputNameContact') }}</b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                    <div class="col">
                        <b-form-group id="inputGroupEmailContact" label="E-mail do contato" label-for="inputGroupEmailContact">
                            <b-form-input
                                id="inputEmailContact"
                                name="inputEmailContact"
                                type="email"
                                v-model="commercialPartner.email_contact"
                                style="min-height:56px;"
                                v-validate="{}"
                                :state="validateState('inputEmailContact')"
                                aria-describedby="inputEmailContactFeedback"
                                data-vv-as="Email"
                                :disabled="loading"
                            ></b-form-input>

                            <b-form-invalid-feedback id="inputEmailContactFeedback">{{ veeErrors.first('inputEmailContact') }}</b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                    <div class="col">
                        <b-form-group id="inputGroupPhoneContact" label="Telefone" label-for="inputGroupPhoneContact">
                            <b-form-input
                                id="inputPhoneContact"
                                name="inputPhoneContact"
                                v-model="commercialPartner.phone_contact"
                                style="min-height:56px;"
                                v-validate="{}"
                                :state="validateState('inputPhoneContact')"
                                aria-describedby="inputPhoneContactFeedback"
                                data-vv-as="Telefone"
                                :disabled="loading"
                                v-mask="['(##) ####-####', '(##) #####-####']"
                            ></b-form-input>

                            <b-form-invalid-feedback id="inputPhoneContactFeedback">{{ veeErrors.first('inputPhoneContact') }}</b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                </div>
                <!-- <div class="mx-0 py-2 row d-flex justify-content-left">
                    <div class="col-md-12">
                        <b-form-group id="inputGroupContact" label="Contato" label-for="inputGroupZipCode">
                            <b-form-input
                                id="inputContact"
                                name="inputContact"
                                v-model="commercialPartner.contact"
                                style="min-height:56px;"
                                v-validate="{}"
                                :state="validateState('inputContact')"
                                aria-describedby="inputContactFeedback"
                                data-vv-as="CEP"
                                :disabled="loading"
                            ></b-form-input>

                            <b-form-invalid-feedback id="inputContactFeedback">{{ veeErrors.first('inputContact') }}</b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                </div> -->
                <!-- <div class="mx-0 py-2 row d-flex justify-content-left">
                    <div class="col-12 text-right">
                        <button class="btn" style="background-color: #DDD; color: #FFF;" :style="formIsValid ? 'background-color: #28A745;' : ''" type="submit" :disabled="loadingSubmit">
                            <template v-if="loadingSubmit">
                                <b-spinner small class="mr-2" />
                            </template>
                            <template v-else>
                                Salvar
                            </template>
                        </button>
                    </div>
                </div> -->
        </div>

        <template #footer>
            <div class="mx-0 row d-flex align-items-center justify-content-end">
                <CancelButton
                @click="$router.push({ name: 'commercialPartner' })"
                >
                    Cancelar
                </CancelButton>
                <CustomButton
                :type="'submit'"
                :loading="loading || loadingSubmit"
                > 
                    Salvar
                </CustomButton>
            </div>
        </template>

        </DefaultPageComponent>
        </b-form>
        
    </div>
    </div>
</template>

<script>
// import DefaultHeader from '@/components/DefaultHeader.vue';
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import CustomButton from '@/components/CustomButton.vue';
import CancelButton from '@/components/CancelButton.vue';
export default {
    name: 'CommercialPartnerEditView',
    components: {
        // DefaultHeader,
        DefaultPageComponent,
        CustomButton,
        CancelButton,
    },
    async mounted() {
        await this.getStates();
        await this.getCommercialPartner();
        setTimeout(() => {
            this.loading = false;
        }, 1000);
    },
    created() {
        this.$validator.localize('en', {
            custom: {
                inputCNPJ: {
                    required: 'Campo obrigatório.',
                    cnpj: 'CNPJ inválido.'
                },
                inputCorporateName: {
                    required: 'Campo obrigatório.',
                },
                inputTradeName: {
                    required: 'Campo obrigatório.',
                },
                inputZipCode: {
                    zipCode: 'CEP inválido.'
                }
            },
        });
    },
    data() {
        return {
            loading: true,
            formIsValid: false,
            commercialPartner: {
                n_document: '',
                corporate_name: '',
                trade_name: '',
                zip_code: '',
                address: '',
                number: '',
                neighborhood: '',
                city: '',
                state_id: '',
                contact: '',
            },
            loadingSubmit: false,
            states: [],
        }
    },
    methods: {
        async getStates() {
            this.$axios.get('states').then((response) => {
                this.states = response.data.data;
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar os estados.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            });
        },
        async getCommercialPartner() {
            this.$axios.get(`commercial-partner/${this.$router.currentRoute.params.id}`).then((response) => {
                this.commercialPartner = response.data.data;
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar o parceiro comercial.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                });
                return;
            });
        },
        validateState(ref) {
            if (
                this.veeFields[ref] &&
                (this.veeFields[ref].dirty || this.veeFields[ref].validated)
            ) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        async validateForm() {
            let response = await this.$validator.validateAll();
            
            if (response)
                this.formIsValid = true;
            else
                this.formIsValid = false;
        },
        async searchZipCode() {
            this.loadingSubmit = true;
            await this.$axios.post(`https://brasilapi.com.br/api/cep/v2/${this.commercialPartner.zip_code}`).then((response) => {
                if (response.status == 200) {
                    this.commercialPartner.address = response.data.street;
                    this.commercialPartner.neighborhood = response.data.neighborhood;
                    this.commercialPartner.city = response.data.city;
                    this.commercialPartner.state_id = response.data.state;
                } else {
                    this.commercialPartner.address = "";
                    this.commercialPartner.neighborhood = "";
                    this.commercialPartner.city = "";
                    this.commercialPartner.state_id = "";
                }
            });
            this.loadingSubmit = false;
        },
        onSubmit() {
            this.$validator.validateAll().then(result => {
                if (!result) {
                    return;
                }

                this.loadingSubmit = true;

                let objCommercialPartner = this.commercialPartner;
                objCommercialPartner.n_document = objCommercialPartner.n_document.replace(/\D/g, '');
                objCommercialPartner.zip_code = objCommercialPartner.zip_code.replace(/\D/g, '');

                this.$axios.put(`commercial-partner/${this.$router.currentRoute.params.id}`, objCommercialPartner).then(() => {
                    this.$swal({
                        title: 'Sucesso',
                        text: 'Parceiro comercial atualizado com sucesso.',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#28A745',
                        allowOutsideClick: false,
                        focusConfirm: false,
                        customClass: {
                            confirmButton: 'custom-confirm-button-class',
                        }
                    });
                    this.loadingSubmit = false;
                    this.commercialPartner = {};
                    this.$router.push({ name: 'commercialPartner' });
                }).catch(() => {
                    this.$swal({
                        title: 'Erro',
                        text: 'Não foi possível atualizar o parceiro comercial.',
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#C44C30',
                        allowOutsideClick: false,
                        customClass: {
                            confirmButton: 'custom-confirm-button-class',
                        }
                    });
                    this.loadingSubmit = false;
                });
            });
        },
    },
    watch: {
        'commercialPartner.n_document': 'validateForm',
        'commercialPartner.corporate_name': 'validateForm',
        'commercialPartner.trade_name': 'validateForm',
        // 'user.password': 'validateForm',
        // 'user.password_confirmation': 'validateForm',
    }
}
</script>
