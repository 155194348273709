<template>
    <b-col class="d-flex p-3" style="width:100%; height:100%; flex-direction:column; background-color:#1C1B1B !important; border-radius:10px;">
        <div style="position:absolute; top:13px; right:13px;">
            <b-button v-if="showVisibility"
                style="width: 35px; height: 30px; background-color: #242323; color:#EB694A; display: flex; justify-content: center; align-items: center; font-size: 0.875rem; border-color: #535252;"
                @click="$emit('show')"
            >
                <b-icon icon="eye-fill"></b-icon>
            </b-button>
        </div>
        <b-row align-h="center"><span style="max-width:60%; color:white; text-align:center; font-size:20px; font-weight:600;"> {{ onlyTitle || title || '---' }} </span></b-row>
        <b-row align-h="center"><span style="color:white; text-align:center; font-size:14px; font-weight:500;"> {{ subtitle }} </span></b-row>
        <b-row v-if="!onlyTitle" align-h="center" style="transform:translateY(30px);"><span style="color:white; font-weight:600;"> {{ goal || '---' }} </span></b-row>
        <ECharts v-if="!onlyTitle" ref="chart" :option="option" style="transform:translateY(20px);"/>
        <b-row v-if="periodReference && !onlyTitle" align-h="center" style="transform:translateY(-10px);"><span style="color:#EB694A; text-align:center">{{ periodReference || '---' }}</span></b-row>
    </b-col>
</template>

<script>
import { createComponent } from 'echarts-for-vue';
import * as echarts from 'echarts';

export default {
    name: 'CockpitComponent',
    components: {
        ECharts: createComponent({ echarts }),      // use as a component
    },
    props:{
        type: {
            type: String,
            default: 'integer'
        },
        onlyTitle: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: null
        },
        subtitle: {
            type: String,
            default: null
        },
        goal: {
            type: String,
            default: null
        },
        value: {
            type: Number,
            default: 0
        },
        target: {
            type: Number,
            default: 0
        },
        periodReference: {
            type: String,
            default: null
        },
        showVisibility: {
            type: Boolean,
            default: false
        },
    },
    mounted(){
        this.getOptions(this.value, this.target, this.type);
    },
    data(){
        return {
            option:{},
        }
    },
    // data: () => ({
    //     option: {
    //         series: [
    //             {
    //                 type: 'gauge',
    //                 axisLine: {
    //                     lineStyle: {
    //                         width: 20,
    //                         color: [
    //                             [0.4, '#f00'],
    //                             [0.5, '#ff0'],
    //                             [1, '#0f0']
    //                         ]
    //                     }
    //                 },
    //                 pointer: {
    //                     itemStyle: {
    //                         color: 'white'
    //                     }
    //                 },
    //                 axisTick: {
    //                     distance: -30,
    //                     length: 0,
    //                     lineStyle: {
    //                         color: 'transparent',
    //                         width: 2
    //                     }
    //                 },
    //                 splitLine: {
    //                     distance: -30,
    //                     length: 0,
    //                     lineStyle: {
    //                         color: 'transparent',
    //                         width: 4
    //                     }
    //                 },
    //                 axisLabel: {
    //                     color: 'transparent',
    //                     distance: 0,
    //                     fontSize: 20
    //                 },
    //                 detail: {
    //                     fontWeight: 500,
    //                     fontSize: 16,
    //                     valueAnimation: true,
    //                     formatter: 'R$ {value} bi',
    //                     color: 'white'
    //                 },
    //                 data: [
    //                     {
    //                         value: value
    //                     }
    //                 ]
    //             }
    //         ]
    //     },
    // }),
    methods: {
        getOptions(value, target, type){
            this.option = {
                series: [
                    {
                        max: target*2 || 1,
                        type: 'gauge',
                        axisLine: {
                            lineStyle: {
                                width: 20,
                                color: [
                                    [0.4, '#F54026'],
                                    [0.5, '#F2E82C'],
                                    [1, '#08DB00']
                                ]
                            }
                        },
                        pointer: {
                            itemStyle: {
                                color: 'white'
                            }
                        },
                        axisTick: {
                            distance: -30,
                            length: 0,
                            lineStyle: {
                                color: 'transparent',
                                width: 2
                            }
                        },
                        splitLine: {
                            distance: -30,
                            length: 0,
                            lineStyle: {
                                color: 'transparent',
                                width: 4
                            }
                        },
                        axisLabel: {
                            color: 'transparent',
                            distance: 0,
                            fontSize: 20
                        },
                        detail: {
                            fontWeight: 600,
                            fontSize: 16,
                            textBorderColor: '#000',
                            textBorderWidth: 3,
                            valueAnimation: true,
                            // formatter: this.type=='integer' ? '{value}' : this.type=='percentage' ? '{value}%' : 'R$ {value}',
                            formatter: function (value) {
                                if(type=='integer') return value;
                                else if(type=='percentage') return value+'%';
                                else {
                                    if (value >= 1e12) {
                                        return 'R$ '+(value / 1e12).toFixed(1) + ' tri';
                                    } else if (value >= 1e9) {
                                        return 'R$ '+(value / 1e9).toFixed(1) + ' bi';
                                    } else if (value >= 1e6) {
                                        return 'R$ '+(value / 1e6).toFixed(1) + ' mi';
                                    } else {
                                        return 'R$ '+Number(value).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                                    }
                                }
                            },
                            color: 'white'
                        },
                        data: [
                            {
                                value: value<0 ? 0 : value,
                                target: target,
                            }
                        ]
                    }
                ]
            }
        },
        formatLargeNumber(number) {
            if (number >= 1e12) {
                return (number / 1e12).toFixed(1) + ' tri';
            } else if (number >= 1e9) {
                return (number / 1e9).toFixed(1) + ' bi';
            } else if (number >= 1e6) {
                return (number / 1e6).toFixed(1) + ' mi';
            } else {
                return Number(number).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            }
        },
        doSomething() {
            // this.$refs.chart.setOption({
            //     series: [
            //         {
            //             data: [
            //                 {
            //                     value: +(Math.random() * 100).toFixed(2)
            //                 }
            //             ]
            //         }
            //     ]
            // });
            // this.$refs.chart.inst.getWidth();
        },
    },
}
</script>

<style>

</style>