<template>
    <div style="height: 100%;">
        <DefaultPageComponent v-if="!showDetails"
        :loading="loading"
        :title="'Cockpit'"
        :haveBackButton="true"
        @onBack="$router.push({name: 'dashboard'})"
        >

        <template #filters>
            <div class="row d-flex justify-content-start align-items-center mt-1">
                <div class="col">
                    <div class="form-group">
                        <label for="">Célula:</label>
                        <select class="form-control form-control-sm" id="inputCelula" style="box-shadow: none; height: 56px;" v-model="cell" @change="advisor=null;">
                            <option :value="null">Todos</option>
                            <option v-for="item in cells" :key="item.id" :value="item.id">{{ item.cell }} </option>
                        </select>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label for="">Assessor:</label>
                        <select class="form-control form-control-sm" id="inputAssessor" style="box-shadow: none; height: 56px;" v-model="advisor">
                            <option :value="null">Todos</option>
                            <option v-for="item in advisorOptions" :key="item.advisorCommercialPartner[0].code" :value="item.advisorCommercialPartner[0].code">{{ item.first_name + " " + item.last_name }} </option>
                        </select>
                    </div>
                </div>
                <div class="col">
                    <InputDatePicker
                    @input="(value)=>{ period=value; isValidPeriod(); }"
                    label="Período"
                    placeholder="Selecione"
                    :value="period"
                    :disabledDateFn="disabledDates"
                    :maxDate="maxDate"
                    />
                    <!-- <div class="form-group">
                        <label for="inputPeriod" style="z-index:10;">Período</label>
                        <b-form-datepicker
                        id="inputPeriod" 
                        type="date" 
                        class="form-control form-control-sm" 
                        style="box-shadow: none; height: 56px; color-scheme: dark !important;"
                        v-model="period"
                        placeholder="Selecione"
                        :date-disabled-fn="disabledDates"
                        :max="maxDate"
                        ></b-form-datepicker>
                    </div> -->
                </div>
                <b-col></b-col>
                <b-col cols="2">
                    <CustomButton
                    :width="'100%'"
                    :loading="loading"
                    :disabled="!period || !(
                        periodData?.positive &&
                        periodData?.xp_us &&
                        periodData?.customer_transfer
                    )"
                    @click="filterTable"
                    > Filtrar
                    </CustomButton>
                </b-col>
            </div>
        </template>

        <!-- CONTENT -->
        <CockpitTable v-if="renderComponent" @show="details"
        :data="data"
        />

        <template #footer>
            <div></div>
        </template>

        </DefaultPageComponent>

        <DefaultPageComponent v-else
        :loading="loading"
        :title="detailsTitle"
        :haveBackButton="true"
        @onBack="toggleDetails"
        >

        <template #filters>
            <div class="row d-flex justify-content-start align-items-center mt-1">
                <!-- <div class="col">
                    <div class="form-group">
                        <label for="inputType">Tipo</label>
                        <select class="form-control form-control-sm" id="inputType" style="box-shadow: none; min-height:56px;">
                            <option value="">Selecione</option>
                        </select>
                    </div>
                </div> -->
                <div class="col">
                    <div class="form-group">
                        <label for="">Célula:</label>
                        <select class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; height: 56px;" v-model="cell" @change="advisor=null;">
                            <option :value="null">Todos</option>
                            <option v-for="item in cells" :key="item.id" :value="item.id">{{ item.cell }} </option>
                        </select>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label for="">Assessor:</label>
                        <select class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; height: 56px;" v-model="advisor">
                            <option :value="null">Todos</option>
                            <option v-for="item in advisorOptions" :key="item.advisorCommercialPartner[0].code" :value="item.advisorCommercialPartner[0].code">{{ item.first_name + " " + item.last_name }} </option>
                        </select>
                    </div>
                </div>
                <div class="col">
                    <InputDatePicker
                    @input="(value)=>{ period=value; isValidPeriod(); }"
                    label="Período"
                    placeholder="Selecione"
                    :value="period"
                    :disabledDateFn="disabledDates"
                    :maxDate="maxDate"
                    />
                </div>
                <b-col></b-col>
                <b-col cols="2">
                    <CustomButton
                    :width="'100%'"
                    :loading="loading"
                    :disabled="!period || !(
                        periodData?.positive &&
                        periodData?.xp_us &&
                        periodData?.customer_transfer
                    )"
                    @click="filterDetails"
                    > Filtrar
                    </CustomButton>
                </b-col>
            </div>
        </template>

        <!-- CONTENT -->
        <CockpitDetails v-if="renderComponent" :title="detailsTitle" :data="detailsData" @back="toggleDetails"/>

        <template #footer>
            <div></div>
        </template>

        </DefaultPageComponent>

    </div>
</template>

<script>
import CockpitTable from '@/views/CockpitTable.vue'
import CockpitDetails from '@/views/CockpitDetails.vue'
import DefaultPageComponent from '@/components/DefaultPageComponent.vue'
import CustomButton from '@/components/CustomButton.vue'
import InputDatePicker from '@/components/InputDatePicker.vue'

export default {
    name: "CockpitView",
    components:{
        DefaultPageComponent,
        CockpitTable,
        CockpitDetails,
        CustomButton,
        InputDatePicker,
    },
    data(){
        return {
            renderComponent: true,
            loading: false,
            status: false,
            showDetails: false,
            detailsTitle: '',
            detailsData: null,
            cells: [],
            advisors: [],
            cell: null,
            advisor: null,
            maxDate: null,
            period: null,
            periodData: null,
            completeDates: [],
            incompleteDates: [],
            dates: [],
            datesData: [],
            data: {
                net:{
                    net_target: 0,
                    net_current: 0,
                },
                activation:{
                    activation_target: 0,
                    activation_current: 0,
                },
                roa:{
                    // roa_reference: new Date().toISOString().split('T')[0],
                    roa_reference: null,
                    roa_target: 0,
                    roa_current: 0,
                },
            }
        }
    },
    created(){
        this.maxDate = new Date().toISOString().split('T')[0];
        this.saveLog();
        this.getCells();
        this.getAdvisors();
        this.getDates();
        this.getDateColors();
    },
    computed:{
        advisorOptions(){
            if(this.cell)
                return this.advisors.filter(advisor => {
                    return advisor.cellHistories.length > 0 && advisor.cellHistories[0].cell.id === this.cell
                });
            else return this.advisors;
        },
    },
    methods: {
        async toggleDetails(){
            await this.filterTable();
            this.showDetails=false;
        },
        async details(endpoint){
            if(!this.isValidPeriod()){
                this.showPeriodDialog();
                return;
            }
            if(endpoint=='cockpit/net') this.detailsTitle='Custódia';
            if(endpoint=='cockpit/activation') this.detailsTitle='Ativação';
            if(endpoint=='cockpit/nps') { this.detailsTitle='NPS'; this.showDetails=true; return; }
            await this.filterDetails();
            this.showDetails=true;
        },
        async forceRender() {
            this.renderComponent = false;
            await this.$nextTick();
            this.renderComponent = true;
        },
        getDateReverse(){
            if(!this.period) return null;
            var day = this.period.substring(8,10);
            var month = this.period.substring(5,7);
            var year = this.period.substring(0,4);
            var date = year+'-'+month+'-'+day;
            return date;
        },
        async getDates(){
            const response = await this.$axios.get('import-dates');
            this.datesData = response.data;
            this.dates = response.data.map(e=>e.date);
            this.filterDates();
        },
        async getCells(){
            const response = await this.$axios.get('cells');
            this.cells = response.data.data;
        },
        async getAdvisors(){
            const response = await this.$axios.get('advisors');
            this.advisors = response.data.data.filter(e=>e.advisorCommercialPartner.length>0 && e.advisorCommercialPartner[0].code);
        },
        disabledDates(ymd, date){ymd, date;
            return false;
            // const dateFormat = date.toLocaleDateString().split('/').reverse().join('-');
            // if(this.dates.length==0) return true;
            // if(this.dates.includes(dateFormat)) return false;
            // else return true;
        },
        async filterTable(){
            try{
                this.loading=true;
                if(!this.period){
                    this.$swal({
                        title: 'Selecione um período',
                        text: 'Por favor, selecione um período.',
                        icon: 'warning',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#28A745',
                        allowOutsideClick: false,
                    });
                    return;
                }
                if(!this.isValidPeriod()) return;
                var endpoint = 'cockpit?date=';
                if(this.period) endpoint = endpoint + this.getDateReverse();
                if(this.advisor) endpoint = endpoint + '&advisor=' + this.advisor;
                if(this.cell) endpoint = endpoint + '&cell=' + this.cell;
                const response = await this.$axios.get(endpoint);
                this.data = response.data;
                this.forceRender();
            }
            catch(error){ console.log(error); }
            finally{ this.loading=false; }
        },
        async filterDetails(){
            try{
                this.loading=true;
                if(!this.period){
                    this.$swal({
                        title: 'Selecione um período',
                        text: 'Por favor, selecione um período.',
                        icon: 'warning',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#28A745',
                        allowOutsideClick: false,
                    });
                    return;
                }
                if(!this.isValidPeriod()) return;
                var endpoint = '';
                
                if(this.detailsTitle=='Custódia') endpoint = 'cockpit/net?date='
                if(this.detailsTitle=='Ativação') endpoint = 'cockpit/activation?date='

                if(this.period) endpoint = endpoint + this.getDateReverse();
                if(this.advisor) endpoint = endpoint + '&advisor=' + this.advisor;
                if(this.cell) endpoint = endpoint + '&cell=' + this.cell;
                const response = await this.$axios.get(endpoint);
                this.detailsData = response.data;
                this.forceRender();
            }
            catch(error){ console.log(error); }
            finally{ this.loading=false; }
        },
        filterDates(){
            this.completeDates = []
            this.incompleteDates = []

            this.datesData.forEach(e=>{
                if(
                    e.positive &&
                    e.xp_us &&
                    e.customer_transfer
                ) this.completeDates.push(e.date)
                else this.incompleteDates.push(e.date)
            })
        },
        isValidPeriod(){
            if(!this.period) return false;
            const finded = this.datesData.find(e=>e.date==this.period)
            this.periodData = finded;
            this.showPeriodDialog();
            if(
                finded?.positive &&
                finded?.xp_us &&
                finded?.customer_transfer
            ) {
                return true;
            }
            else{
                return false;
            } 
        },
        showPeriodDialog(){
            if(!this.periodData){
                this.$swal({
                    title: 'Não há dados disponíveis',
                    text: "Não há dados importados para o dia selecionado",
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28A745',
                    allowOutsideClick: false,
                });
                return;
            }
            if(
                this.periodData.positive &&
                this.periodData.xp_us &&
                this.periodData.customer_transfer
            ) return;
            
            var list = [];

            if(!this.periodData.positive)
                list.push('Positivador');
            if(!this.periodData.xp_us)
                list.push('XP US');
            if(!this.periodData.customer_transfer)
                list.push('Transferência de clientes');
            
            var message = '';
            
            if(list.length==2){
                message = 'Os arquivos ' + list.map(e=>`"`+e+`"`).join(' e ') + ' não foram importados na data selecionada.'
            }
            else if(list.length==1){
                message = 'O arquivo ' + `"`+list[0]+`"` + ' não foi importado na data selecionada.'
            }

            this.$swal({
                title: 'Importação incompleta',
                text: message,
                icon: 'warning',
                confirmButtonText: 'OK',
                confirmButtonColor: '#28A745',
                allowOutsideClick: false,
            });
        },
        getDateColors(){
            const intervalId = setInterval(()=>{
                if(this.$route.name!='cockpit'){
                    clearInterval(intervalId);
                    return;
                }
                const elements = document.querySelectorAll('div[data-date]');
                elements.forEach(e => {
                    const dateValue = e.getAttribute('data-date');
                    const spanElement = e.querySelector('span');
                    if (this.completeDates.includes(dateValue)) {
                        spanElement.style.removeProperty('color');
                        spanElement.style.setProperty('color', '#28A745', 'important');
                    } 
                    else if(this.incompleteDates.includes(dateValue)) {
                        spanElement.style.removeProperty('color');
                        spanElement.style.setProperty('color', '#CB3411', 'important');
                    }
                    else {
                        spanElement.style.removeProperty('color');
                        spanElement.style.setProperty('color', '#BBBBBB', 'important');
                    }
                });
            }, 0)
        },
        search(){

        },
        saveLog () {
            let objLog = {
                description: 'Cockpit'
            }

            this.$axios.post('logs', objLog).then((response) => {
                if(response.status != 201) {
                    console.log(response);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    },
}
</script>

<style scoped>
* /deep/ .bg-light{
    background-color:#262625 !important;
}
* /deep/ .text-dark,
* /deep/ .text-muted{
    color:white !important;
}
* /deep/ .text-dark:hover{
    color:#262625 !important;
}

* /deep/ #inputPeriod__dialog_{
    background-color:black !important;
}

* /deep/ #inputPeriod__value_{
    position: absolute !important;
    top: 0px !important;
    left: 0px !important;
    padding-top: 15px !important;
    padding-bottom: 0px !important;
    height: 100% !important;
}

</style>

<style>
.green-date {
    color: #28A745 !important;
}
.orange-date {
    color: #CB3411 !important;
}
.white-date {
    color: #FFFFFF !important;
}
</style>