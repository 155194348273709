<template>
    <div style="height: 100%;">
        <DefaultPageComponent
        :loading="loading"
        :title="'Log'"
        :haveBackButton="true"
        @onBack="$router.push({name: 'dashboard'})"
        :haveContentMargin="true"
        >

        <template #filters>
            <div class="row d-flex justify-content-start align-items-center">
                <div class="col">
                    <div class="form-group">
                        <label for="inputUser">Usuário</label>
                        <select v-model="filterUser" class="form-control form-control-sm" id="inputUser" style="box-shadow: none; min-height:56px;">
                            <option :value="null">Todos</option>
                            <option v-for="item in users" :key="item.id" :value="item.id"> {{ item.first_name+' '+item.last_name }} </option>
                        </select>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label for="inputDescription">Descrição</label>
                        <select v-model="filterDescription" class="form-control form-control-sm" id="inputDescription" style="box-shadow: none; min-height:56px;">
                            <option :value="null">Todas</option>
                            <option v-for="item in descriptions" :key="item.description" :value="item.description"> {{ item.description }} </option>
                        </select>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <InputDatePicker
                        @input="(value)=>{ filterDate=value; }"
                        :inputId="'inputDate'"
                        label="Período:"
                        placeholder="Todos"
                        resetButton="Cancelar"
                        :value="filterDate"
                        :maxDate="today"
                        />
                    </div>
                </div>
                <div class="col"></div>
                <div class="col d-flex justify-content-end">
                    <GlassButton
                    :loading="loading"
                    @click="getLogs(1, true)"
                    > 
                        <img src="@/assets/search.png" />
                        Pesquisar
                    </GlassButton>
                </div>
            </div>
        </template>

        <!-- CONTENT -->
        <b-table v-if="renderComponent" id="tableDetalhes" class="custom-table" borderless sticky-header :fields="columns" :items="items" show-empty empty-text="Sem registros para mostrar no momento...">
        </b-table>

        <template #footer>
            <div></div>
        </template>

        </DefaultPageComponent>
    </div>
</template>

<script>
// import DefaultHeader from '@/components/DefaultHeader.vue';
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import GlassButton from '@/components/GlassButton.vue';
import InputDatePicker from '@/components/InputDatePicker.vue';
export default {
    name: 'LogView',
    components: {
        GlassButton,
        DefaultPageComponent,
        InputDatePicker,
        // DefaultHeader,
    },
    mounted() {
        this.getLogs(1);
        this.getSelectOptions();
        this.scrollKey = setInterval(()=>{ 
            this.onScroll();
        }, 1000);
    },
    beforeDestroy(){
        clearInterval(this.scrollKey);
    },
    data() {
        return {
            scrollKey: null,
            loading: false,
            isScrollLoading: false,
            page: 1,
            lastPage: 1,
            columns: [
                {
                    'user_name': 
                    {
                        label: 'Usuário',
                        class: 'text-left'
                    }
                },
                {
                    'description': 
                    {
                        label: 'Descrição',
                        class: 'text-left'
                    }
                },
                {
                    'created_at': 
                    {
                        label: 'Data',
                        class: 'text-left',
                        formatter: (value) => {
                            return this.formatDateTimeBR(value);
                        }
                    }
                },
                // {
                //     'ACTIONS': 
                //     {
                //         label: '',
                //         class: 'text-right',
                //     }
                // },
            ],
            items: [],
            users: [],
            descriptions: [],
            filterUser: null,
            filterDescription: null,
            filterDate: null,
            renderComponent: true,
            today: new Date().toISOString().split('T')[0],
        }
    },
    methods: {
        async forceRender(){
            this.renderComponent = false;
            await this.$nextTick();
            this.renderComponent = true;
        },
        async onScroll(){
            const element = document.querySelector('#tableDetalhes').parentElement;
            if (element.scrollTop + 1500 >= element.scrollHeight 
                && element.scrollHeight>1000 
                && !this.loading && !this.isScrollLoading
                && this.page < this.lastPage
            ) {
                this.isScrollLoading=true;
                await this.getLogs(this.page)
            }
        },
        async getSelectOptions(){
            const responseUsers = await this.$axios.get('users');
            const responseDescriptions = await this.$axios.get('logs/descriptions');
            this.users = responseUsers.data.data
            this.descriptions = responseDescriptions.data
        },
        getLogs(page, reset=false) {
            if(page==1) this.loading=true;
            else this.isScrollLoading=true;
            
            var queries = '?page='+page;
            if(this.filterUser)
                queries += '&user_id='+this.filterUser;
            if(this.filterDate)
                queries += '&date='+this.filterDate;
            if(this.filterDescription)
                queries += '&description='+this.filterDescription;

            this.$axios.get('logs'+queries).then((response) => {
                if(reset) this.items = response.data.data;
                else this.items.push(...response.data.data);
                this.page = response.data.meta.current_page;
                this.lastPage = response.data.meta.last_page;
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar logs.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                });
                return;
            })
            .finally(()=>{
                if(page==1) this.loading=false;
                else this.isScrollLoading=false;
            })
        },
        formatDateTimeBR(value){
            if(!value) return '---';
            var day = value.substring(8,10);
            var month = value.substring(5,7);
            var year = value.substring(0,4);
            var hour = value.substring(11,13);
            var minute = value.substring(14,16);
            var dateBR = day+'/'+month+'/'+year+' '+hour+':'+minute;
            return dateBR;
        },
    }
}
</script>

<style>
.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff !important;
    border-color: #28A745 !important;
    background-color: #28A745 !important;
    box-shadow: none !important;
}

.custom-control-input:not(checked) ~ .custom-control-label::before {
    border-color: #CCC !important;
    box-shadow: none !important;
}

tr:nth-child(odd) {
    background-color: #DDD !important;
}
</style>
