<template>
    <b-col class="d-flex p-3 align-items-center justify-content-center" style="width:100%; height:100%; flex-direction:column; background-color:#1C1B1B !important; border-radius:10px;">
        <div class="chart-container mb-4" style="position: relative;">
            <canvas :id="dataKey" ></canvas>
        </div>
    </b-col>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
    name: 'GraphicBarComponent',
    props:{
        dataKey: {
            type: String,
            default: null
        },
        dataKeyTarget: {
            type: String,
            default: null
        },
        dataKeyCurrent: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: null
        },
        target: {
            type: String,
            default: null
        },
        data: {
            type: Object,
            default: ()=>{ return {}; }
        },
        type: {
            type: String,
            default: null,
        },
        showVisibility: {
            type: Boolean,
            default: false
        },
    },
    mounted(){
        this.renderChart();
    },
    data(){
        return {
            chartInstance: null,
            option:{},
        }
    },
    methods: {
        renderChart() {
            if (this.chartInstance) {
                this.chartInstance.destroy();
            }

            let keys = Object.keys(this.data[this.dataKeyCurrent]).map(e=>this.translate(e))

            let values = Object.values(this.data[this.dataKeyCurrent])
                        
            let valuesFormatted = Object.values(this.data[this.dataKeyCurrent]).map(e=>this.formatLargeNumber(e))

            let target = this.data[this.dataKeyTarget]

            let type = this.type;

            // let items = this.items.filter(item => item.CLASSIFICATION !== 'QUEBRA DE LINHA');
            // items.sort((a, b) => a.POSITION - b.POSITION);
            // const labels = items.map(item => item.CLASSIFICATION + ' (' + Number(item.PERCENTAGE).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })  + '%' + ')');
            // const values = items.map(item => item.PERCENTAGE);
            const ctx = document.getElementById(this.dataKey).getContext('2d');
            this.chartInstance = new Chart(ctx, {
                // type: 'bar',
                data: {
                    labels: keys,
                    datasets: [{
                        type: 'bar',
                        label: '',
                        data: values,
                        backgroundColor: 'rgba(235, 105, 74, 1)',
                        borderColor: 'rgba(235, 105, 74, 1)',
                        borderWidth: 1,
                        barThickness: 20,
                        categoryPercentage: 1000,
                    },
                    {
                        type: 'line',
                        label: type=='value' ? 'Objetivo: R$ '+this.formatLargeNumber(target)
                             : 'Objetivo: '+target,
                        data: values.map(()=>target),
                        backgroundColor: '#00FF94',
                        borderColor: '#00FF9470',
                    },
                    {
                        type: 'line',
                        label: '',
                        data: values.map(()=>0),
                        backgroundColor: '#555',
                        borderColor: '#555',
                    }
                    ]
                },
                options: {
                    indexAxis: 'y',
                    maintainAspectRatio: false,
                    responsive: true,
                    scales: {
                        x: {
                            min: Math.min(...values) || 0,
                            max: Number(target) * 2,
                            beginAtZero: true,
                            ticks: {
                                maxTicksLimit: 4,
                                color: '#F3F0EF',
                                // callback: function(value) {
                                //     return value + '%';
                                // }
                                font: {
                                    size: 14,
                                    weight: 500,
                                },
                            },

                        },
                        y: {
                            ticks: {
                                color: '#F3F0EF',
                                font: {
                                    size: 16,
                                    weight: 500,
                                },
                                callback: function(value) {
                                    let obj = {
                                        label: keys[value],
                                        value: valuesFormatted[value]
                                    }
                                    if(type=='value') return obj.label+' (R$ '+obj.value+')';
                                    else return obj.label+' ('+obj.value+')';
                                }
                            }
                        }
                    },
                    plugins: {
                        legend: {
                            display: true,
                            onClick: ()=>{},
                            labels: {
                                color: '#00FF94',
                                font:{
                                    size: 16,
                                },
                                filter: function(item) {
                                    return item.text.includes('Objetivo');
                                }
                            }
                        },
                        tooltip: {
                            callbacks: {
                                label: function(context) {
                                    var label = context.dataset.label || '';
                                    if (label) {
                                        label += ': ';
                                    }
                                    
                                    if(type=='value') label += 'R$ '+context.parsed.x.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                                    else label += context.parsed.x;

                                    return label;
                                }
                            }
                        },
                    }
                }
            });
        },
        translate(value){
            switch(value){
                case 'aquarium': return 'Aquário XP';
                case 'global_market': return 'Mercado Global';
                case 'activation': return 'Ativação';
                case 'base': return 'Base';
                case 'between_300_and_500': return '>= 300k < 500k';
                case 'greater_than_or_equal_500': return '>= 500k';
                case 'less_than_300': return '< 300k';
                case 'total_amount': return 'Total';
            }
        },
        formatLargeNumber(number) {
            if (number >= 1e12) {
                return (number / 1e12).toFixed(1) + ' tri';
            } else if (number >= 1e9) {
                return (number / 1e9).toFixed(1) + ' bi';
            } else if (number >= 1e6) {
                return (number / 1e6).toFixed(1) + ' mi';
            } else {
                return Number(number).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            }
        },
    },
}
</script>

<style scoped>
.chart-container {
    width: calc(100% - 30px) !important;
    min-height: 350px !important;
}
</style>