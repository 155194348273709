<template>
    <div id="default-layout">
        <!-- NavbarComponent -->
        <navbar-component />
        <!-- NavbarComponent -->

        <!-- SidebarComponent -->
        <sidebar-component />
        <!-- SidebarComponent -->
    
        <div id="main">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import NavbarComponent from '@/components/NavbarComponent.vue'
import SidebarComponent from '@/components/SidebarComponent.vue'

export default {
    name: 'DefaultLayout',
    components: {
        NavbarComponent,
        SidebarComponent,
    },
}
</script>

<style>
    #default-layout{
        min-height:100vh;
        min-width:100vw;
    }
    /* Main */
    #main {
        height: calc(100vh - 62px) !important; 
        background-color: #1C1B1B !important;
        padding: 30px;
        color: #FFF;
        overflow-y: auto;
    }
</style>
